import { List, ListItem } from 'framework7-react';
import Roles from './Roles';
import React, {
  Fragment,
  useEffect,
  useState,
  useContext,
  useMemo,
} from 'react';
import { StoreContext } from '../../store';
import * as roleService from '../../services/role.service';
const TerminalRole = props => {
  const { userStore, commonStore } = useContext(StoreContext);
  const [terminalRoles, setTerminalRoles] = useState([]);
  const [firstTimeInit, setFirstTimeInit] = useState(true);
  const { data } = props;
  const [roles, setRoles] = useState([]);

  useEffect(async () => {
    await getRoles();
    setFirstTimeInit(false);
    getCurrentRoles();
  }, []);

  useMemo(() => {
    if (firstTimeInit) return;
    userStore.updateUserTerminalsRoles(
      data.idTerminal,
      terminalRoles.join(', '),
    );
  }, [terminalRoles]);

  const getRoles = async () => {
    let roleList = commonStore.getRoleList;
    if (!roleList || !roleList.length) {
      roleList = await roleService.getRoleList();
      commonStore.setRoleList(roleList);
    }
    setRoles(roleList);
  };
  const getCurrentRoles = () => {
    const { userTerminalRoles } = userStore.getUser || {};
    const terminalRole = (userTerminalRoles || []).find(
      item => item.idTerminal == data.idTerminal,
    );

    if (!terminalRole || !terminalRole.roles) return;
    setTerminalRoles(terminalRole.roles.split(',').map(item => item.trim()));
  };
  const onChangeRoles = e => {
    const value = e.target.value;
    if (e.target.checked) {
      terminalRoles.push(value);
    } else {
      terminalRoles.splice(terminalRoles.indexOf(value), 1);
    }
    setTerminalRoles([...terminalRoles]);
  };
  const onTerminalRoleChange = e => {
    if (terminalRoles.length >= 0 && terminalRoles.length < roles.length) {
      const roleIds = roles.map(item => {
        return `${item.roleId}`;
      });
      setTerminalRoles(roleIds);
    } else if (terminalRoles.length === roles.length) {
      setTerminalRoles([]);
    }
  };

  return (
    <Fragment>
      <List>
        <ListItem
          checkbox
          title={`${data.tmlCd} -${data.name} `}
          name="demo-checkbox"
          checked={terminalRoles.length === roles.length}
          indeterminate={
            terminalRoles.length > 0 && terminalRoles.length < roles.length
          }
          onChange={e => onTerminalRoleChange(e)}
        >
          <ul slot="root" style={{ paddingLeft: '40px' }}>
            <Roles
              values={terminalRoles}
              data={roles}
              onChange={onChangeRoles}
            />
          </ul>
        </ListItem>
      </List>
    </Fragment>
  );
};

export default TerminalRole;
