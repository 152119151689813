import React from 'react';
import { QrReader } from "react-qr-reader";


export default function ReactQrReader({ isStart, onScanned }) {
  return (
    <>
      {isStart && <QrReader
        key='environment'
        videoContainerStyle={{
          position: 'unset',
          paddingTop: 'unset',
          width: '100%',
          height: '100%',
        }}
        onResult={(result, error) => {
          if (!!result) {
            onScanned(result?.text);
          }

          // if (!!error) {
          //   console.info(error);
          // }
        }}
        scanDelay={500}
        constraints={{
          facingMode: 'environment',
        }}
        style={{ width: '100%', height: '100%' }}
      />}
    </>
  );
}