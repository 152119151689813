import axios from './axios';

export const getAllUserProfile = async tmlCd => {
  const { data } = await axios.get(`users/all-profile/${tmlCd}`);
  return data;
};

export const updateUserProfile = async (params = {}) => {
  const { data } = await axios.put('users/user-profile', params);
  return data;
};

export const saveUserTerminalVessel = async (params = {}) => {
  await axios.put('users/terminal-vessel-codes', params);
};

export const fetchUserInfo = async idUser => {
  const { data } = await axios.get(`/users/${idUser}/profile`);
  return data;
};
