import { BlockTitle, List, ListItem, AccordionContent } from 'framework7-react';
import React, { useEffect, Fragment, useContext, useState } from 'react';
import { StoreContext } from '../../store';
import TerminalRole from './TerminalRole';
import { observer } from 'mobx-react';
import { getTerminals } from '../../services/terminal-service.js';

const terminalList = props => {
  const { profileStore, commonStore, userStore } = useContext(StoreContext);
  const [terminalList, setTerminalList] = useState([]);

  useEffect(async () => {
    let terminalList = commonStore.getTerminalList;
    if (!terminalList || !terminalList.length) {
      terminalList = await getTerminals();
      commonStore.setTerminalList(terminalList);
    }

    const { idTerminal, sysAdm } = profileStore;
    if (!sysAdm || sysAdm !== 'Y') {
      const tml = terminalList.find(item => item.idTerminal == idTerminal);
      setTerminalList([tml]);
    } else {
      setTerminalList(terminalList);
    }
  }, []);

  return (
    <Fragment>
      <List accordionList>
        <ListItem accordionItem title="Terminals & Roles">
          <AccordionContent>
            {terminalList.map((terminal, index) => {
              return <TerminalRole key={index} data={terminal} />;
            })}
          </AccordionContent>
        </ListItem>
      </List>
    </Fragment>
  );
};

export default observer(terminalList);
