import React, { useEffect, useRef, useState } from 'react';
import jsQR from "jsqr";

export default function JsQr({ isStart, onScanned }) {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const isScanning = useRef(false);
  const [stream, setStream] = useState(null);

  useEffect(() => {
    if (isStart) {
      startScanByCam();
    } else {
      stopScanByCam();
    }
  }, [isStart]);

  async function startScanByCam() {
    isScanning.current = true;
    const canvasElement = canvasRef.current;
    const canvas = canvasElement.getContext('2d');
    const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
    setStream(stream);
    videoRef.current.srcObject = stream;
    videoRef.current.setAttribute("playsinline", true);
    videoRef.current.play();

    const scanFrame = () => {
      if (videoRef.current && videoRef.current.readyState === videoRef.current.HAVE_ENOUGH_DATA) {
        // canvasElement.hidden = false;
        canvasElement.height = videoRef.current.videoHeight;
        canvasElement.width = videoRef.current.videoWidth;
        canvas.drawImage(videoRef.current, 0, 0, canvasElement.width, canvasElement.height);
        const imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height);
        const code = jsQR(imageData.data, imageData.width, imageData.height);
        if (code) {
          onScanned(code.data);
        }
      }

      if (isScanning.current) {
        requestAnimationFrame(scanFrame);
      }
    };

    scanFrame();
  }

  function stopScanByCam() {
    isScanning.current = false;
    videoRef.current.pause();
    if (stream) {
      stream.getTracks().forEach((track) => {
        track.stop();
      });
    }
  }

  return (
    <>
      <div style={{ position: 'relative' }}>
        <video ref={videoRef} style={{ width: '100%' }} />
      </div>
      <canvas ref={canvasRef} style={{ display: 'none' }} />
    </>
  );
}