export const GENDER = [
  { id: 'M', value: 'Male' },
  { id: 'F', value: 'Female' },
];

export const ROLES = [
  { title: 'Shipping Line', value: 'S', role: 'shippingline' },
  { title: 'Customer', value: 'C', role: 'customer' },
  { title: 'Truck Company', value: 'T', role: 'truckcompany' },
  { title: 'Driver', value: 'D', role: 'driver' },
  { title: 'Terminal', value: 'P', role: 'terminal' },
  { title: 'User External', value: 'E', role: 'external' },
];

export const MENU_LIST = [
  {
    name: 'Vessel Voyage',
    path: '/vessel-schedule',
    icon: 'directions_boat',
    roles: [],
  },
  {
    name: 'Container Details',
    path: '/cargo',
    icon: 'apps',
    roles: [],
  },
  {
    name: 'Delivery Cargo',
    path: '/delivery-cargo',
    icon: 'local_shipping',
    roles: [],
  },
  {
    name: 'Jetty Supervisor',
    path: '/jetty-supervisor',
    icon: 'local_shipping',
    roles: ['external'],
  },
  {
    name: 'Vessel Seal Management',
    path: '/vessel-seal',
    icon: 'lock_icon',
    roles: ['external'],
  },
  {
    name: 'Vessel Sounding',
    path: '/vessel-sounding',
    icon: 'straighten_icon',
    roles: ['external'],
  },
  {
    name: 'User List',
    path: '/users',
    icon: 'people',
    roles: ['admin'],
  },
];

export const GROUP_NAME_COMMON_CODE = {
  VESSEL_SEAL_TYPE: 'VESSEL_SEAL_TYPE',
  VESSEL_LOCATION: 'VESSEL_LOCATION',
  VESSEL_EQUIPMENT: 'VESSEL_EQUIPMENT',
  VESSEL_SEAL_CONDITION: 'VESSEL_SEAL_CONDITION',
};

export const ITEM_TYPE_CODE = {
  CODE: '0051CD',
  NUMBER: '00051NM',
  RANGE: '0051RG',
  TEXT: '0051TX',
  YES_NO: '0051YN',
  CODE_OPTION: '0051CO',
  VESSEL_VOYAGE: '0051VV',
  PERIOD: '0051PE',
  MULTI_TEXT: '0051MT',
  SINGLE_CODE: '0051SC',
  NA: '0051NA',
  PERIOD_DATE: '0051PD',
  SINGLE_DATE: '0051SD',
};

export const GENERAL_CODE = {
  DISPLAY: '0351DP',
  CALCULATION: '0351CA',
  INPUT: '0351IP',
  UPDATE_OPERATION: '0351UO',
};

export const CP_GROUP_CODES = {
  USER_ROLE: '0002',
};

export const COLUMN_PREFIX = 'COL_';

export const VESSEL_LOCALTION = {
  LOADING_PORT: '0362L',
  JETTY: '0362J',
  ANCHORAGE: '0362A',
  AFTER_DISCHARHING: '0362AD',
};

export const QR_SCAN_LIB = {
  BARCODE_DETECTOR: {
    id: 'barcodedetector',
    name: 'Barcode Detector',
  },
  QR_SCANNER: {
    id: 'qrscanner',
    name: 'QR Scanner',
  },
  ZXING: {
    id: 'zxing',
    name: 'ZXing',
  },
  JSQR: {
    id: 'jsqr',
    name: 'JsQR',
  },
  REACT_QR: {
    id: 'reactqr',
    name: 'React QR Reader',
  },
  HTML5_QR_CODE: {
    id: 'html5qrcode',
    name: 'Html5QrCode',
  },
};

export const QR_SCAN_LIB_DEFAULT = 'barcodedetector';