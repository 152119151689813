import {
  Block,
  Button,
  Link,
  List,
  ListInput,
  Page,
  Toolbar,
} from 'framework7-react';
import React, { useEffect, useState } from 'react';
import { login, getProfile } from '../services/auth.service';
import { store } from '../store/index';

const LoginPage = props => {
  const [formState, setFormState] = useState({
    username: '',
    password: '',
  });

  useEffect(async () => {
    //Check app config
    const isDarkThem = localStorage.getItem('darkTheme');
    store.appStore.setDarkTheme(isDarkThem == 'true' ? true : false);
    const user = await getProfile();
    if (!user) return;

    if (user) {
      props.$f7router.navigate('/main', {
        clearPreviousHistory: true,
      }); // clear login page in history
    }
  }, []);

  const onInputForm = event => {
    const input = event.target;
    formState[input.name] = input.value;
    setFormState(formState);
  };

  const btnLoginClick = async () => {
    let result = await login(formState.username, formState.password);

    if (result.message) {
      props.$f7router.app.dialog.alert(result.message, 'Warning', () => {});
      return;
    }

    props.$f7router.navigate('/login', { clearPreviousHistory: true }); // clear login page in history
  };

  return (
    <Page noNavbar noSwipeback loginScreen>
      <Block
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img
          className="logo-system"
          src="../static/img/logo-opus.svg"
          alt="logo-opus"
          style={{ width: '260px' }}
        />
      </Block>
      <List id="formLogin">
        <ListInput
          label={<label htmlFor="username">Username or email</label>}
          type="text"
          name="username"
          placeholder="Your username or email"
          onInput={onInputForm}
          required
          validate
          clearButton
          inputId="username"
        />
        <ListInput
          label={<label htmlFor="password">Password</label>}
          type="password"
          name="password"
          inputId="password"
          placeholder="Your password"
          onInput={onInputForm}
          required
          validate
        />
      </List>
      <Block
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <button className="button button-fill" onClick={btnLoginClick}>
          Login
        </button>
      </Block>
      <Block>
        <Link
          style={{ display: 'block', textAlign: 'center' }}
          href="/register"
        >
          SIGN UP
        </Link>
      </Block>

      <Toolbar
        bottom
        noHairline
        noShadow
        noBorder
        bgColor="unset"
        inner={false}
      >
        <Link color="black" href="/verify">
          Forgot the Password?
        </Link>
      </Toolbar>
    </Page>
  );
};

export default LoginPage;
