import axios from './axios';

export const getVesselList = async (role, tmlCd, idCompany) => {
  const result = await axios.get(
    `vessel-schedules?role=${role}&tmlCd=${tmlCd}&idCompany=${idCompany}`,
  );
  return result.data;
};

export const getVesselDetails = async (tmlCd, vslVoyId) => {
  const result = await axios.get(
    `vessel-schedules/${tmlCd}/${vslVoyId}`,
  );
  return result.data;
};
