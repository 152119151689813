import {
  Badge,
  Button,
  Icon,
  Link,
  List,
  ListItem,
  ListItemCell,
  ListItemRow,
  Navbar,
  NavLeft,
  NavRight,
  NavTitle,
  Page,
  Progressbar,
} from 'framework7-react';
import React, { useEffect, useState } from 'react';
import * as vesselVoyageService from '../../services/vessel-voyage.service';
import moment from 'moment';

const VesselScheduleDetail = props => {
  const { vslVoyId, tmlCd } = props;
  const [details, setDetailsLst] = useState({});

  useEffect(async () => {
    props.$f7router.app.preloader.show();
    const details = await vesselVoyageService.getVesselDetails(tmlCd, vslVoyId);
    props.$f7router.app.preloader.hide();

    if (!details) {
      return;
    }

    setDetailsLst(details);
  }, []);

  return (
    <Page style={{ '--f7-page-subnavbar-offset': '0px' }}>
      <Navbar>
        <NavLeft backLink>
          {/* <Link icon="icon-back" external href="/vessel-schedule" /> */}
        </NavLeft>
        <NavTitle>Vessel Schedule</NavTitle>
        <NavRight>
          <Link color="black" iconMaterial="directions_boat"></Link>
        </NavRight>
      </Navbar>

      <Button fill large>
        {details.vslNm}
      </Button>

      <List mediaList style={{ marginTop: 0 }}>
        <ListItem title="Vessel" after={details.vslNm}>
          <div className="item-text" style={{ textAlign: 'right' }}>
            {details.vslOprCd}
          </div>
        </ListItem>
        <ListItem
          title="Voyage"
          after={`${details.oprInVoyNo || ''} ${
            !details.oprInVoyNo || !details.oprOutVoyNo ? '' : '-'
          } ${details.oprOutVoyNo || ''}`}
        >
          <div className="item-text" style={{ textAlign: 'right' }}>
            {details.vslVoyId}
          </div>
        </ListItem>
        <ListItem
          title="Lane"
          after={`${details.inLaneCd || ''} ${
            !details.inLaneCd || !details.outLaneCd ? '' : '-'
          } ${details.outLaneCd || ''}`}
        >
          {/* <div className="item-text" style={{ textAlign: 'right' }}>
            THLCH {'>'} KRPUS {'>'} USLGB
          </div> */}
        </ListItem>
        <ListItem title="Status">
          <Badge
            slot="after"
            color="green"
            style={{ paddingLeft: '15px', paddingRight: '15px' }}
          >
            {details.cdDesc}
          </Badge>
        </ListItem>
        <ListItem title="Actual Arrival">
          <div slot="after">
            {moment(details.ataDt).isValid() &&
              moment(details.ataDt).format('MMMM DD, YYYY, h:ss A')}
          </div>
          <Icon slot="after" material="today"></Icon>
        </ListItem>
        <ListItem title="Working Status">
          <div slot="after">
            {getWorkStatus(details.dchgStDt, details.lodgStDt)}
          </div>
          <Icon slot="after" material="today"></Icon>
        </ListItem>
        <ListItem title="Estimated Departure">
          <div slot="after">
            {moment(details.etaDt).isValid() &&
              moment(details.etaDt).format('MMMM DD, YYYY, h:ss A')}
          </div>
          <Icon slot="after" material="today"></Icon>
        </ListItem>
        <ListItem title="Working Progress">
          <div slot="after" style={{ width: '190px' }}>
            <Progressbar
              progress={roundPercentComplete(details.percentageComplete)}
              id="demo-inline-progressbar"
            ></Progressbar>
          </div>
          <div className="item-text" style={{ textAlign: 'right' }}>
            {`${roundPercentComplete(details.percentageComplete)} % Completed`}
          </div>
        </ListItem>

        <ListItemRow>
          <ListItemCell style={{ flex: 3 }}></ListItemCell>
          <ListItemCell
            style={{ flex: 2, textAlign: 'center', overflow: 'hidden' }}
          >
            Complete
          </ListItemCell>
          <ListItemCell
            style={{ flex: 2, textAlign: 'center', overflow: 'hidden' }}
          >
            Pending
          </ListItemCell>
          <ListItemCell
            style={{ flex: 2, textAlign: 'center', overflow: 'hidden' }}
          >
            Total
          </ListItemCell>
        </ListItemRow>

        <ListItemRow>
          <ListItemCell style={{ flex: 3, overflow: 'hidden' }}>
            <ListItem>
              {/* <Icon slot="media" material="import_export"></Icon> */}
              Discharging
            </ListItem>
          </ListItemCell>
          <ListItemCell style={{ flex: 2, textAlign: 'right' }}>
            <ListItem>{`${Number(details.dischargeMovesComplete) ||
              '0'}`}</ListItem>
          </ListItemCell>
          <ListItemCell style={{ flex: 2, textAlign: 'right' }}>
            <ListItem>{`${Number(details.dischargeMovesPending) ||
              '0'}`}</ListItem>
          </ListItemCell>
          <ListItemCell style={{ flex: 2, textAlign: 'right' }}>
            <ListItem>
              {`${Number(
                details.dischargeMovesComplete + details.dischargeMovesPending,
              ) || '0'}`}
            </ListItem>
          </ListItemCell>
        </ListItemRow>

        <ListItemRow>
          <ListItemCell style={{ flex: 3, overflow: 'hidden' }}>
            <ListItem>Loading</ListItem>
          </ListItemCell>
          <ListItemCell style={{ flex: 2, textAlign: 'right' }}>
            <ListItem>{`${Number(details.loadMovesComplete) || '0'}`}</ListItem>
          </ListItemCell>
          <ListItemCell style={{ flex: 2, textAlign: 'right' }}>
            <ListItem>{`${Number(details.loadMovesPending) || '0'}`}</ListItem>
          </ListItemCell>
          <ListItemCell style={{ flex: 2, textAlign: 'right' }}>
            <ListItem>
              {`${Number(
                details.loadMovesComplete + details.loadMovesPending,
              ) || '0'}`}
            </ListItem>
          </ListItemCell>
        </ListItemRow>
      </List>
    </Page>
  );
};

const getWorkStatus = (dateStr1, dateStr2) => {
  try {
    const moment1 = moment(dateStr1);
    const moment2 = moment(dateStr2);
    const minMoment = moment.min(moment1, moment2);
    if (minMoment.isValid()) {
      return minMoment.format('MMMM DD, YYYY, h:ss A');
    }
    return '';
  } catch (error) {
    return '';
  }
};

const roundPercentComplete = numbStr => {
  try {
    return Math.round(numbStr * 100) || 0;
  } catch (error) {
    return 0;
  }
};
export default VesselScheduleDetail;
