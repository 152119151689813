import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useMemo,
} from 'react';
import {
  Page,
  Navbar,
  Block,
  Button,
  Sheet,
  Toolbar,
  Link,
  PageContent,
  BlockTitle,
  List,
  ListItem,
  ListInput,
  f7,
  Input,
  Chip,
  Row,
  Popup,
} from 'framework7-react';
import * as terminalRoleService from '../../services/user-terminal-role.service';
import * as userService from '../../services/user.service';
import { StoreContext } from '../../store';
import InputVesselCode from './InputVesselCode';
import * as vesselCommonService from '../../services/vessel-common.service';
import MultiSelect from '../multiselect/MultiSelect';

const TerminalVesselForm = props => {
  const { userStore, profileStore } = useContext(StoreContext);
  const [terminalList, setTerminalList] = useState([]);
  const [vslCdLst, setVslCdLst] = useState([]);
  const [vslCdInput, setVslCdInput] = useState('');
  const [tmlCd, setTmlCd] = useState('');
  const [vesselList, setVesselList] = useState([]);
  const [userTerminalVessels, setUserTerminalVessels] = useState([]);

  useEffect(async () => {
    await initialize();
  }, [tmlCd]);

  const initialize = async () => {
    const result = await terminalRoleService.getTerminalRoles();
    const terminalLst = result.map(item => {
      const { tmlCd, name } = item.terminal || {};
      return { tmlCd, name };
    });
    setTerminalList([{ name: '', tmlCd: '' }].concat(terminalLst));

    initTerminalVesselList(userStore.getUser.userTerminalVessels);
  };

  useMemo(() => {
    const { tmlCd, vslCdList } = props.data || {};
    setVslCdLst(vslCdList || []);
    setTmlCd(tmlCd || '');
  }, [props.data]);

  useMemo(async () => {
    if (!tmlCd) return;

    const vslList = await vesselCommonService.getVessselPlannedList(tmlCd);
    setVesselList(vslList);

    const userTmlVslCds = userTerminalVessels.find(
      item => item.tmlCd === tmlCd,
    );
    setVslCdLst(userTmlVslCds?.vslCdList || []);
  }, [tmlCd]);

  const initTerminalVesselList = (terminalVessels = []) => {
    const { terminal, sysAdm } = profileStore;

    let tmpLst = terminalVessels.map(item => {
      const { terminal, vslCds, tmlCd } = item;
      const terminalName = `${terminal.tmlCd}-${terminal.name}`;
      const vslCdList = (vslCds || '').split(',').map(vslCd => vslCd.trim());
      return { terminalName, vslCdList, tmlCd };
    });
    if (!sysAdm || sysAdm !== 'Y') {
      tmpLst = tmpLst.filter(item => item.tmlCd === terminal);
    }

    setUserTerminalVessels(tmpLst || []);
  };

  const onSave = () => {
    if (!tmlCd) return;

    f7.dialog.confirm('Do you want to save?', 'Confirm', () => {
      onSaveProcess();
    });

    //
  };

  const showAlert = (title, content) => {
    f7.dialog.alert(content, title, () => {});
  };

  const onSaveProcess = async () => {
    const { idUser } = userStore.getUser;
    const params = { tmlCd, vslCds: vslCdLst.join(', '), idUser };

    const result = await userService.saveUserTerminalVessel(params);
    if (result) {
      showAlert('Info', 'Save successfully!');
      props.onAfterSave();
      onClose();
    } else {
      showAlert('Warning', 'Failed! Please try again.');
    }
  };

  const onSelectedTerminal = newVal => {
    setTmlCd(newVal);
  };

  const onClose = () => {
    props.onSheetClosed();
    setTmlCd('');
    setVslCdLst([]);
    setVslCdInput('');
  };
  return (
    <Fragment>
      <Popup
        className="demo-popup"
        swipeToClose
        opened={props.isOpen}
        onPopupClosed={() => {
          onClose();
        }}
      >
        {/* <Sheet
        className="demo-sheet"
        swipeToClose={true}
        closeByBackdropClick={true}
        closeByOutsideClick={true}
        opened={props.isOpen}
        style={{ height: '70%', '--f7-sheet-bg-color': '#fff' }}
        onSheetClosed={() => {
          onClose();
        }}
      > */}
        <Toolbar>
          <div className="left" style={{ marginLeft: '10px' }}>
            <b>Setting Terminal - Vessel</b>
          </div>
          <div className="right">
            <Link popupClose>Close</Link>
          </div>
        </Toolbar>
        {/*  Scrollable sheet content */}
        <PageContent>
          {profileStore.sysAdm === 'Y' && (
            <Block>
              <BlockTitle className="strong">Terminal</BlockTitle>
              <List>
                <ListInput
                  type="select"
                  validate
                  value={tmlCd}
                  onChange={e => {
                    e.preventDefault();
                    onSelectedTerminal(e.target.value);
                  }}
                >
                  {terminalList &&
                    terminalList.map((item, index) => (
                      <option key={index} value={item.tmlCd}>
                        {item.tmlCd ? item.tmlCd + ' - ' : ''}
                        {item.name}
                      </option>
                    ))}
                </ListInput>
                <input id="txtTmlCd" defaultValue={tmlCd} hidden />
              </List>
            </Block>
          )}
          <Block>
            <BlockTitle className="strong">Vessel Code</BlockTitle>
            <MultiSelect
              collection={vesselList}
              onChange={vals => {
                setVslCdLst(vals);
              }}
              values={vslCdLst}
              mappingPropsValue="vslNm"
              mappingPropsKey="vslCd"
            ></MultiSelect>
          </Block>
          <Button
            style={{
              width: '80px',
              margin: 'auto',
            }}
            raised
            fill
            disabled={!tmlCd}
            onClick={onSave}
          >
            Save
          </Button>
        </PageContent>
        {/* </Sheet> */}
      </Popup>
    </Fragment>
  );
};

export default TerminalVesselForm;
