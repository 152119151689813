import {
  BlockTitle,
  Button,
  Col,
  Link,
  List,
  ListItem,
  Navbar,
  NavLeft,
  NavRight,
  NavTitle,
  Page,
  Row,
  Segmented,
  Subnavbar,
  Tab,
  Tabs,
} from 'framework7-react';
import * as vesselVoyageService from '../../services/vessel-voyage.service';
import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import { StoreContext } from '../../store';

const VesselSchedule = props => {
  const { profileStore } = useContext(StoreContext);
  const [departureLst, setDepartureLst] = useState([]);
  const [plannedLst, setPlannedLst] = useState([]);
  const [arrivalLst, setArrivalLst] = useState([]);

  useEffect(async () => {
    props.$f7router.app.preloader.show();
    const { role, company, terminal } = profileStore;
    let data = await vesselVoyageService.getVesselList(role, terminal, company);
    const { departureLst, plannedLst, arrivalLst } = data;
    setDepartureLst(departureLst);
    setArrivalLst(arrivalLst);
    setPlannedLst(plannedLst);
    props.$f7router.app.preloader.hide();
  }, []);

  return (
    <Page>
      <Navbar>
        <NavLeft backLink />
        <NavTitle>Vessel Schedule</NavTitle>
        {/* <NavRight>
          <Link color="black" iconMaterial="directions_boat"></Link>
        </NavRight> */}

        <Subnavbar>
          <Segmented raised>
            <Button tabLink="#tab1" tabLinkActive>
              Plan
            </Button>
            <Button tabLink="#tab2">Arrival</Button>
            <Button tabLink="#tab3">Departured</Button>
          </Segmented>
        </Subnavbar>
      </Navbar>

      <Tabs>
        <Tab id="tab1" tabActive>
          {TimerBLock()}
          {VesselListComp(plannedLst)}
        </Tab>

        <Tab id="tab2">
          {TimerBLock()}
          {VesselListComp(arrivalLst)}
        </Tab>

        <Tab id="tab3">
          {TimerBLock()}
          {VesselListComp(departureLst)}
        </Tab>
      </Tabs>
    </Page>
  );
};

const VesselListComp = props => {
  return (
    <List mediaList>
      {props &&
        props.map((item, index) => {
          const date = moment(item.etaDt);
          return (
            <ListItem
              key={index}
              title={item.vslNm}
              subtitle={item.vslOprCd}
              link={`/vessel-schedule/details/${item.tmlCd}/${item.vslVoyId}`}
            >
              <div slot="media" style={{ minWidth: '56px' }}>
                <div style={{ textAlign: 'center' }}>
                  {date.isValid() && date.format('HH:ss')}
                </div>

                <div style={{ textAlign: 'center' }}>
                  {date.isValid() && date.format('DD-MMM')}
                </div>
              </div>
            </ListItem>
          );
        })}
    </List>
  );
};

const TimerBLock = () => {
  return (
    <BlockTitle style={{ marginTop: 'unset', marginBottom: 'unset' }}>
      <Row>
        <Col>
          <p>{moment().format('MMM DD, YYYY')}</p>
        </Col>
        <Col style={{ textAlign: 'right' }}>
          <p>{moment().format('dddd')}</p>
        </Col>
      </Row>
    </BlockTitle>
  );
};

export default VesselSchedule;
