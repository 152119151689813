import React from 'react';
import { Page, Navbar, NavRight, Link, BlockTitle } from 'framework7-react';
import SelectTerminalRole from '../../components/select-terminal-role';
const TerminalPage = props => {
  const onSelectedTerminal = () => {
    props.$f7router.navigate('/main', { clearPreviousHistory: true });
  };

  return (
    <Page>
      <Navbar>
        <NavRight>
          <img
            className="logo-system"
            src="../static/img/logo-opus.svg"
            style={{ maxHeight: '30px' }}
            alt="logo-opus"
          />
        </NavRight>
      </Navbar>
      <BlockTitle>Select terminal</BlockTitle>
      <SelectTerminalRole onAfterSelected={onSelectedTerminal} />
    </Page>
  );
};

export default TerminalPage;
