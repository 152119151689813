import React, { useState } from 'react';
import {
  Block,
  List,
  ListInput,
  Page,
  Toolbar,
  Link,
  Button,
  BlockHeader,
} from 'framework7-react/framework7-react.esm';
import * as resetPasswordService from '../../services/reset-password.service';
import { isValidFormByFormId } from '../../utils/common';

const Verify = props => {
  const [verifyForm, setVerifyForm] = useState({ email: '' });

  const onInputEmail = event => {
    const valInput = event.target.value;
    verifyForm.email = valInput;
    setVerifyForm(verifyForm);
  };

  const btnResetPasswordClick = async () => {
    if (!isValidFormByFormId("formVerify")) {
      return;
    }

    await resetPasswordService.verify(verifyForm.email);
    props.$f7router.navigate('/confirm', { clearPreviousHistory: true });
  };

  const redirectLogin = () => {
    props.$f7router.navigate('/', { clearPreviousHistory: true });
  };

  return (
    <Page noNavbar noSwipeback loginScreen>
      <Block
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img
          className="logo-system"
          src="../static/img/logo-opus.svg"
          style={{ width: '260px' }}
          alt="logo-opus"
        ></img>
      </Block>

      <BlockHeader style={{ fontSize: '20px', color: 'rgba(0,0,0,0.6)' }}>
        Forgot the password?
      </BlockHeader>

      <List id="formVerify">
        <ListInput
          label={<label htmlFor="email">E-mail</label>}
          type="email"
          name="email"
          placeholder="E-mail"
          onInput={onInputEmail}
          inputId="email"
          required
          validate
          clearButton
        />
      </List>

      <Block style={{ display: 'table' }}>
        <button className="button button-fill" onClick={btnResetPasswordClick}>
          Reset Password
        </button>
        {/* <Button
          style={{ width: '200px' }}
          fill
          round
          onClick={btnResetPasswordClick}
        >
          Reset Password
        </Button> */}
      </Block>

      <Toolbar
        bottom
        noHairline
        noShadow
        noBorder
        bgColor="unset"
        inner={false}
      >
        <Link href="/">Login</Link>
      </Toolbar>
    </Page>
  );
};

export default Verify;
