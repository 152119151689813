import { Link, Navbar, NavLeft, NavTitle, Page } from 'framework7-react';
import React, { useContext, useEffect, useState } from 'react';
import VesselMainPage from '../../components/vessel-main-page';
import { getMenuByTerminalRole } from '../../services/main.service';
import { StoreContext } from '../../store';

const VesselSealManagement = ({ f7router, menuId: propMenuId }) => {
  const { commonStore, profileStore } = useContext(StoreContext);
  const [menuId, setMenuId] = useState(propMenuId);

  useEffect(() => {
    const fetchData = async () => {
      if (!menuId) {
        const path = f7router.url;
        let menu = commonStore.getMenu;
        if (!menu || !menu.length) {
          menu = await getMenuByTerminalRole(profileStore.idTerminal);
          commonStore.setMenu(menu);
        }
        const currentMenu = menu.find(mnu => mnu.path === path);
        if (!currentMenu) {
          return;
        }
        setMenuId(currentMenu.menuId);
      }
    };
    fetchData();
  }, []);
  const onSelectedVoyage = () => {
    f7router.navigate('vessel-seal-main');
  };
  return (
    <Page>
      <Navbar>
        <NavLeft>
          <Link icon="icon-back" back />
        </NavLeft>
        <NavTitle>Vessel Seal Management</NavTitle>
      </Navbar>
      <VesselMainPage onVoyageItemClick={onSelectedVoyage} menuId={menuId} />
    </Page>
  );
};
export default VesselSealManagement;
