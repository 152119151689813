import axios from './axios';

export const getTerminal = async () => {
  const lstTerminal = await axios.get('terminals/');
  return lstTerminal.data;
};

export const getCompanyByTmlCd = async (tmlCd, role) => {
  const lstCompanyRole = await axios.get(
    `terminals/${tmlCd}/companies?role=${role}`,
  );
  return lstCompanyRole.data;
};

export const settingRole = async userInfo => {
  await axios.put(`users/roles`, userInfo);
};

export const getRoles = async () => {
  const result = await axios.get(`roles/`);
  return result.data;
};

export const deleteRole = async roleInfo => {
  await axios.delete(`users/roles`, { data: roleInfo });
};
