import {
  Block,
  BlockTitle,
  List,
  ListItem,
  Page,
  Navbar,
  NavLeft,
  NavRight,
  Link,
  Icon,
  Button,
} from 'framework7-react';
import React, { useState, useContext, useEffect, useMemo } from 'react';
import { StoreContext } from '../store';
import Cookies from 'js-cookie';
import { getMenuByRole, getMenuByTerminalRole } from '../services/main.service';
import { clearStore } from '../services/store.service';

const MainPage = props => {
  const { profileStore, commonStore } = useContext(StoreContext);
  const [menuList, setMenuList] = useState([]);

  useEffect(async () => {
    if (!profileStore.idUser) {
      props.$f7router.navigate('/', { clearPreviousHistory: true });
      return;
    }

    // if (!profileStore.role) {
    //   props.$f7router.navigate('/select-role', { clearPreviousHistory: true });
    //   return;
    // }
  }, []);

  useMemo(async () => {
    const menus = await getMenuByTerminalRole(profileStore.idTerminal);
    setMenuList(menus);
    commonStore.setMenu(menus);
  }, [profileStore.idTerminal]);

  async function logOutBtnOnClick() {
    clearStore();
    // localStorage.removeItem('terminal');
    Cookies.remove('access_token');
    props.$f7router.navigate('/', { clearPreviousHistory: true });
  }

  function handleTestQRClick() {
    props.$f7router.navigate('/test-qr');
  }

  return (
    <Page>
      <Navbar>
        <NavLeft>
          <Link>
            <img
              className="logo logo-system"
              src="../static/img/logo-opus.svg"
              style={{ maxHeight: '30px' }}
            />
          </Link>
        </NavLeft>
        <NavRight>
          <Link
            iconMaterial="people_alt"
            iconOnly={true}
            iconColor="black"
            href="/profile"
          ></Link>
        </NavRight>
      </Navbar>
      <Block>
        <strong>Hi {profileStore.name || ''}!</strong>
        <br />
        Welcome to OTM.
      </Block>
      <BlockTitle medium>Main Menu</BlockTitle>
      <List>
        {menuList.map((item, index) => {
          return (
            <ListItem
              key={index}
              title={item.name}
              link={item.path}
              routeProps={{ menuId: item.menuId }}
            >
              <Icon slot="media" material={item.icon}></Icon>
            </ListItem>
          );
        })}
      </List>

      <BlockTitle medium>Setting</BlockTitle>
      <List>
        <ListItem title="User Profile" link="/profile">
          <Icon slot="media" material="person_fill"></Icon>
        </ListItem>
        <ListItem title="Settings" link="/settings">
          <Icon slot="media" material="settings"></Icon>
        </ListItem>
        <ListItem title="Logout" onClick={logOutBtnOnClick}>
          <Icon slot="media" material="power_settings_new"></Icon>
        </ListItem>
        <ListItem title="QR Scanning Testing" onClick={handleTestQRClick}>
          <Icon slot="media" material="photo_camera"></Icon>
        </ListItem>
      </List>
    </Page>
  );
};

export default MainPage;
