import {
  Block,
  BlockHeader,
  Button,
  Link,
  List,
  ListInput,
  ListItem,
  Navbar,
  NavLeft,
  NavRight,
  Page,
} from 'framework7-react';
import React, { useContext, useEffect, useState } from 'react';
import * as roleService from '../../services/role.service';
import { StoreContext } from '../../store';
import Cookies from 'js-cookie';

const SettingRole = props => {
  const { profileStore } = useContext(StoreContext);
  const [frmState, setFrmState] = useState({
    role: 'P',
    idCompany: '',
    tmlCd: '',
  });
  const [lstTerminals, setLstTerminals] = useState([]);
  const [lstCompanies, setLstCompanies] = useState([]);
  const [lstRoles, setLstRole] = useState([]);

  useEffect(async () => {
    const roles = roleService.getRoleList();
    const cmbTerminals = await roleService.getTerminals();
    setLstRole(roles);
    setLstTerminals(cmbTerminals);
  }, []);

  const setCompaniesCombo = async (role, tmlCd) => {
    let cboCompanies = [];
    if (tmlCd) {
      cboCompanies = await roleService.getCompanyByRole(role, tmlCd);
    }
    setLstCompanies([...cboCompanies]);
    setFrmState({ ...frmState, idCompany: '' });
  };

  const setRole = async role => {
    frmState.role = role;
    setFrmState(frmState);
    // Get combo Company
    let tmlCd = frmState.tmlCd;
    setCompaniesCombo(role, tmlCd);
  };

  const createRole = async () => {
    const form = document.getElementById('frmSettingRole');
    const isValid = form.checkValidity();

    if (!isValid) return;
    const result = await roleService.isRoleExisted(frmState.role);
    if (result) {
      props.$f7router.app.dialog.alert('Role is existed', () => {});
      return;
    }

    props.$f7router.app.dialog.confirm(
      'Do you want to setting role for this user ?',
      () => {
        settingRole(frmState);
      },
    );
  };

  const settingRole = async frmState => {
    let success = await roleService.settingRole(frmState);

    if (!success) {
      props.$f7router.app.dialog.alert(
        'Setting role failed, Please try again!',
        'Error',
        () => {},
      );
      return;
    }

    if (props.toMainPage) {
      props.$f7router.navigate('/main', {
        clearPreviousHistory: true,
      });
    } else {
      const roleLst = await roleService.getRoleList();
      props.setRoleList(roleLst);
      props.$f7router.back();
    }
  };

  async function onBackClick() {
    // Check to back login page
    if (props.toMainPage) {
      Cookies.remove('access_token');
      props.$f7router.back('/', { force: true });
      return;
    }

    props.$f7router.back();
  }

  return (
    <Page>
      <Navbar>
        <NavLeft>
          <Link icon="icon-back" onClick={onBackClick} />
        </NavLeft>
        <NavRight>
          <Link>
            <img className="logo-system" style={{ maxHeight: '30px' }} />
          </Link>
        </NavRight>
      </Navbar>

      <Block>
        <strong>Hi {profileStore.name}!</strong>
        <br />
        Welcome to OTM.
      </Block>
      <List id="frmSettingRole" form noHairlines>
        <BlockHeader>Your Role</BlockHeader>
        {lstRoles &&
          lstRoles.map((item, index) => (
            <ListItem
              key={index}
              radio
              radioIcon="start"
              title={item.title}
              value={item.value}
              name="rboRole"
              required
              validate
              defaultChecked
              onClick={async e => {
                let role = e.currentTarget.children['rboRole'].value;
                setRole(role);
              }}
            ></ListItem>
          ))}

        <ListInput
          type="select"
          label="Terminal"
          required
          validate
          onChange={async e => {
            const tmlCd = e.target.value;
            frmState.tmlCd = tmlCd;
            setFrmState(frmState);

            const role = frmState.role;
            setCompaniesCombo(role, tmlCd);
          }}
        >
          <option value=""></option>
          {lstTerminals &&
            lstTerminals.map((item, index) => (
              <option key={index} value={item.tmlCd}>
                {item.name}
              </option>
            ))}
        </ListInput>
        <ListInput
          type="select"
          label="Company"
          required
          validate
          value={frmState.idCompany}
          onChange={e => {
            setFrmState({ ...frmState, idCompany: e.target.value });
          }}
        >
          <option value=""></option>
          {lstCompanies &&
            lstCompanies.map((item, index) => (
              <option key={index} value={item.idCompany}>
                {item.name}
              </option>
            ))}
        </ListInput>
      </List>

      <Block
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Button
          className="button button-fill"
          style={{
            width: '100%',
          }}
          onClick={createRole}
        >
          Save
        </Button>
      </Block>
    </Page>
  );
};

export default SettingRole;
