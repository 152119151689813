import { ListItem } from 'framework7-react';
import React, { Fragment, useEffect, useState, useContext } from 'react';

const Roles = props => {
  useEffect(async () => {}, []);

  const onRoleChange = e => {
    if (props.onChange) {
      props.onChange(e);
    }
  };

  return (
    <Fragment>
      {(props.data || []).map((item, index) => (
        <ListItem
          key={index}
          checkbox
          value={item.roleId}
          title={item.name}
          checked={(props.values || []).indexOf(`${item.roleId}`) >= 0}
          onChange={e => {
            onRoleChange(e);
          }}
        ></ListItem>
      ))}
    </Fragment>
  );
};

export default Roles;
