import QrScannerLib from 'qr-scanner';
import React, { useEffect, useRef } from 'react';

export default function QrScanner({ isStart, onScanned }) {
  const scanner = useRef(null);
  const videoRef = useRef(null);

  useEffect(() => {
    if (isStart) {
      startScanByCam();
    } else {
      stopScanByCam();
    }
  }, [isStart]);

  useEffect(() => {
    scanner.current = new QrScannerLib(
      videoRef.current,
      result => {
        if (result.data) {
          onScanned(result.data);
        }
      },
      {
        preferredCamera: 'environment',
        maxScansPerSecond: 1000,
        highlightCodeOutline: true,
        highlightScanRegion: true,
        calculateScanRegion: function (video) {
          const scanRegionSize = 200;
          return {
            x: Math.round((video.videoWidth - scanRegionSize) / 2),
            y: Math.round((video.videoHeight - scanRegionSize) / 2),
            width: scanRegionSize,
            height: scanRegionSize,
            downScaledWidth: scanRegionSize,
            downScaledHeight: scanRegionSize,
          };
        },
      },
    );
  }, []);

  function startScanByCam() {
    if (scanner.current) {
      scanner.current.start();
    }
  }

  function stopScanByCam() {
    if (scanner.current) {
      scanner.current.stop();
    }
  }

  return (
    <video
      ref={videoRef}
      style={{ width: '100%', height: '100%' }}
      playsInline
      autoPlay
    ></video>
  );
}