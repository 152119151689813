import * as menuTerminalRoleService from '../api/menu-terminal-role';

export const getMenuTerminalRole = async (idTerminal, role) => {
  return await menuTerminalRoleService.getMenuTerminalRole(idTerminal, role);
};

export const getMenuResource = async () => {
  return await menuTerminalRoleService.getMenuResource();
};

export const saveMenuTerminalRole = async (params = {}) => {
  try {
    await menuTerminalRoleService.saveMenuTerminalRole(params);
    return true;
  } catch (ex) {
    console.error(ex.response);
    return false;
  }
};

export const addRole = async (params = {}) => {
  try {
    await menuTerminalRoleService.addRole(params);
    return true;
  } catch (ex) {
    console.error(ex.response);
    return false;
  }
};

export const updateRole = async (params = {}) => {
  try {
    await menuTerminalRoleService.updateRole(params);
    return true;
  } catch (ex) {
    console.error(ex.response);
    return false;
  }
};

export const deleteRole = async (tmlCd, roleCd) => {
  try {
    const result = await menuTerminalRoleService.deleteRole(tmlCd, roleCd);
    return result;
  } catch (ex) {
    console.error(ex.response);
    throw new Error('error');
  }
};

export const getRoleListByMenuId = async (tmlCd, menuId) => {
  return await menuTerminalRoleService.getRoleListByMenuId(tmlCd, menuId);
};
