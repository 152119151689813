import axios from './axios';

export const loginApi = async (username, password) => {
  return await axios.post('auth/login', {
    username,
    password,
  });
};

export const profile = async () => {
  const result = await axios.get('app/profile');
  return result.data;
};
