import Cookies from 'js-cookie';
import { loginApi, profile } from '../api/auth.api';
import { store } from '../store/index';

export const login = async (username, password) => {
  try {
    const result = await loginApi(username, password);

    Cookies.set('access_token', result.data.access_token);

    return result.data;
  } catch (ex) {
    return { message: 'Username or Password is wrong' };
  }
};

export const getProfile = async () => {
  try {
    const userInfo = await profile();
    store.profileStore.setValues(userInfo);
    return userInfo;
  } catch (error) {
    console.error(error);
    return null;
  }
};
