import { Block, CardContent, Col, Icon, Row } from 'framework7-react';
import React from 'react';

class VesselVoyageItem extends React.Component {
  render() {
    const { vslOprCd, vslNm, vslVoyId, hoursEta, monthEta } = this.props.voyage;
    return (
      <React.Fragment>
        <CardContent style={{ position: 'relative' }}>
          <Row>
            <Col width={20} className="text-center">
              <strong>{hoursEta}</strong>
            </Col>
            <Col width={80}>
              <Block textColor="gray">{vslNm}</Block>
            </Col>
          </Row>
          <Row>
            <Col width={20} className="text-center">
              <strong>{monthEta}</strong>
            </Col>
            <Col width={30}>
              <Block textColor="gray">{vslOprCd}</Block>
            </Col>
            <Col width={50}>
              <Block textColor="gray">{vslVoyId}</Block>
            </Col>
          </Row>
          <div
            onClick={() => {
              this.props.onClick(this.props.voyage);
            }}
            style={{
              position: 'absolute',
              top: '50%',
              right: '5px',
              transform: 'translate(0px, -50%)',
              zIndex: '100',
            }}
          >
            <Icon size="22" color="blue" material="chevron_right"></Icon>
          </div>
        </CardContent>
        <div style={{ height: '1px', background: '#cccccc' }}></div>
      </React.Fragment>
    );
  }
}

export default VesselVoyageItem;
