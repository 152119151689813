import * as registerApi from '../api/register.api.js';
import { profile } from '../api/auth.api';
import * as profileApi from '../api/profile.api';
import * as roleApi from '../api/role.api';
import { ROLES } from '../const';

export const getRoleName = role => {
  if (!role || role === 'user') {
    return '';
  }

  if (role.includes('shippingline')) {
    return 'Shipping Line';
  }

  if (role.includes('customer')) {
    return 'Customer';
  }

  if (role.includes('truckcompany')) {
    return 'Truck Company';
  }

  if (role.includes('driver')) {
    return 'Driver';
  }

  if (role.includes('terminal')) {
    return 'Terminal';
  }
};

export const getCountryCode = async () => {
  try {
    const lstCountryCode = await registerApi.getCountryCode();

    if (lstCountryCode)
      lstCountryCode.map(e => {
        e.phoneCountry = '(' + e.phoneCode + ')' + ' - ' + e.name;
        return e;
      });

    return lstCountryCode;
  } catch (error) {
    console.error(error.response);
    return [];
  }
};

export const getProfile = async () => {
  try {
    const userInfo = await profile();

    userInfo.role = userInfo.role.replace('user,', '');

    return {
      username: userInfo.username,
      role: userInfo.role,
      name: userInfo.person.name,
      email: userInfo.person.email,
      birthDate: userInfo.person.birthDate,
      gender: userInfo.person.gender,
      idCountry: userInfo.person.idCountry,
      mobileCountryCode: userInfo.person.mobileCountryCode,
      mobilePhoneNumber: userInfo.person.mobilePhoneNumber,
      userTerminalRoles: userInfo.userTerminalRoles,
    };
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const checkOldPassword = async password => {
  try {
    const isValid = await profileApi.checkPassword(password);
    return isValid;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const saveProfile = async dataSave => {
  try {
    await profileApi.saveProfile(dataSave);
    return true;
  } catch (error) {
    console.error(error.response);
    return false;
  }
};

export const isRoleExisted = async (role, company, roles) => {
  try {
    return roles.find(
      r => Number(r.idCompany) === Number(company) && r.role === role,
    );
  } catch (error) {
    console.log(error.message);
    return false;
  }
};

export const getRoleList = async () => {
  try {
    const roleLst = await roleApi.getRoles();
    roleLst.forEach(r => {
      const role = ROLES.find(e => e.role === r.role);

      if (role) {
        r.roleNm = role.title;
      }
    });
    return roleLst;
  } catch (error) {
    console.error(error.response);
    return [];
  }
};
