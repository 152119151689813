import CargoDetail from '../pages/cargo-detail/cargo-detail';
import CargoList from '../pages/cargo-detail/cargo-list';
import LoginPage from '../pages/login.js';
import MainPage from '../pages/main.js';

import ConfirmEmail from '../pages/register/confirm-email';
import Register from '../pages/register/register.js';
import Confirm from '../pages/reset-password/confirm.js';
import ResetPassword from '../pages/reset-password/reset-password';
import Verify from '../pages/reset-password/verify.js';
import SettingRole from '../pages/role/setting-role';
import VesselSchedule from '../pages/vessel-voyage/vessel-schedule';
import VesselScheduleDetails from '../pages/vessel-voyage/vessel-schedule-details';
import { getProfile } from '../services/auth.service';
import { setIdTerminal, setTerminal } from '../services/store.service';
import { getTerminals } from '../services/terminal-service.js';

import Profile from '../pages/profile.js';
import ManageRole from '../pages/role/manage-role.js';
import SelectRole from '../pages/role/select-role.js';
import TerminalPage from '../pages/terminal/index.js';

import JettySupervisor from '../pages/jetty-supervisor';
import RoleSetting from '../pages/menu/RoleSetting';
import Menu from '../pages/menu/index';
// import ScanQRCode from '../pages/scan-qr/index.js';
import Setting from '../pages/setting-page/index';
import User from '../pages/user/index.js';
import UserForm from '../pages/user/user-form';

import RoleTerminalSetting from '../pages/role-terminal/index';

import VesselSealManagement from '../pages/vessel-seal-management';
import VesselSounding from '../pages/vessel-sounding';
import VesselSoundingDraft from '../pages/vessel-sounding/VesselSoundingDraft.js';
import WelcomePage from '../pages/register/welcome.js';
import VesselAccessManagement from '../pages/vessel-access-management';
import VesselAccessForm from '../pages/vessel-access-management/vessel-access-form';
import { store } from '../store/index';
import { TestQR } from '../pages/scan-qr/test-view';

async function onCommonHandle(routeTo, routeFrom, resolve, reject) {
  //Check app config
  const isDarkThem = localStorage.getItem('darkTheme');
  store.appStore.setDarkTheme(isDarkThem == 'true' ? true : false);

  //Check authen
  const userInfo = await getProfile();

  if (!userInfo) {
    reject();
    this.navigate('/');
  } else {
    resolve();
  }
}

async function onRequiredVesselVoyage(routeTo, routeFrom, resolve, reject) {
  const { vslVoyId } = store.vesselMainpageStore.getSelectedVoyage;
  if (!vslVoyId) {
    const { path } = routeTo;
    const mainPath = path.substr(0, path.indexOf('/', 1));
    reject();
    this.navigate(mainPath);
    return;
  }
  resolve();
}

async function onRequiredUserSelectedInfo(routeTo, routeFrom, resolve, reject) {
  const { idUser } = store.userStore.getUser;
  if (!idUser) {
    const { path } = routeTo;
    const mainPath = path.substr(0, path.indexOf('/', 1));
    reject();
    this.navigate(mainPath);
    return;
  }
  resolve();
}

async function onRequiredCommonData(routeTo, routeFrom, resolve, reject) {
  //Terminal code
  // let tmlCd = store.profileStore.terminal || localStorage.getItem('terminal');

  const idTerminal =
    store.profileStore.idTerminal || localStorage.getItem('idTerminal');
  const tmlCd = store.profileStore.terminal;
  //Role
  let role = store.profileStore.role || localStorage.getItem('role');

  const terminal = await getTerminalAndRole(idTerminal, role);

  if (terminal) {
    // setTerminal(tmlCd);
    setIdTerminal(idTerminal);
    setTerminal(terminal.tmlCd);
    resolve();
    return;
  }

  // setTerminal('');
  setIdTerminal('');
  reject();
  this.navigate('/select-terminal-role');
}

async function getTerminalAndRole(idTerminal, role) {
  if (!idTerminal) return false;
  // if (!tmlCd || !role) return false;

  const tmlLst = await getTerminals();
  const tmlObj = tmlLst.find(item => item.idTerminal == idTerminal);
  // if (!tmlObj) return false;
  return tmlObj;

  // const roleObj = (tmlObj.roles || '').indexOf(role);
  // return roleObj >= 0;
}

const handleLogin = async (routeTo, resolve, reject) => {
  //Set default role is User External
  resolve('/main');
  //TODO: need to review this logic later
  // const user = await getProfile();
  // if (user.role.includes('external')) {
  //   setRole(user.role);

  //   resolve('/main');
  //   return;
  // }
  // //End

  // const roles = await getRoleList();
  // if (roles.length === 0) {
  //   resolve('/setting-role', { props: { toMainPage: true } });
  //   return;
  // }

  // if (roles.length === 1) {
  //   const roles = await getRoleList();
  //   await setRoleInfo(roles[0]);
  //   resolve('/main');
  // } else {
  //   resolve('/select-role', { props: { toMainPage: true } });
  // }
};

const routes = [
  {
    path: '/',
    component: LoginPage,
  },
  {
    path: '/main',
    component: MainPage,
    beforeEnter: [onCommonHandle, onRequiredCommonData],
  },
  {
    path: '/select-terminal-role',
    beforeEnter: [onCommonHandle],
    component: TerminalPage,
  },
  {
    path: '/jetty-supervisor',
    component: JettySupervisor,
    beforeEnter: [onCommonHandle, onRequiredCommonData],
    routes: [
      {
        path: '/jetty-supervisor-main',
        asyncComponent: () =>
          import('../pages/jetty-supervisor/JettySupervisorMain'),
        beforeEnter: [
          onCommonHandle,
          onRequiredVesselVoyage,
          onRequiredCommonData,
        ],
        routes: [
          {
            path: '/jetty-supervisor-vessel-schedule',
            asyncComponent: () =>
              import('../pages/jetty-supervisor/JettySupervisorVesselSchedule'),
            beforeEnter: [
              onCommonHandle,
              onRequiredVesselVoyage,
              onRequiredCommonData,
            ],
          },
          {
            path: '/jetty-supervisor-photo',
            asyncComponent: () =>
              import('../pages/jetty-supervisor/JettySupervisorPhoto'),
            beforeEnter: [
              onCommonHandle,
              onRequiredVesselVoyage,
              onRequiredCommonData,
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/vessel-seal',
    component: VesselSealManagement,
    beforeEnter: [onCommonHandle, onRequiredCommonData],
    routes: [
      {
        path: '/vessel-seal-main',
        asyncComponent: () =>
          import('../pages/vessel-seal-management/vessel-seal-main'),
        beforeEnter: [
          onCommonHandle,
          onRequiredVesselVoyage,
          onRequiredCommonData,
        ],
      },
      {
        path: '/vessel-seal-form',
        asyncComponent: () =>
          import('../pages/vessel-seal-management/vessel-seal-form'),
        beforeEnter: [
          onCommonHandle,
          onRequiredCommonData,
          onRequiredVesselVoyage,
        ],
        routes: [
          {
            path: '/vessel-seal-photo',
            asyncComponent: () =>
              import('../pages/vessel-seal-management/vessel-seal-photo'),
            beforeEnter: [
              onCommonHandle,
              onRequiredVesselVoyage,
              onRequiredCommonData,
            ],
          },
        ],
      },
      {
        path: '/vessel-seal-form',
        asyncComponent: () =>
          import('../pages/vessel-seal-management/vessel-seal-form'),
      },
    ],
  },
  {
    path: '/vessel-sounding',
    component: VesselSounding,
    beforeEnter: [onCommonHandle, onRequiredCommonData],
    routes: [
      {
        path: '/vessel-sounding-main',
        beforeEnter: [
          onCommonHandle,
          onRequiredVesselVoyage,
          onRequiredCommonData,
        ],
        asyncComponent: () =>
          import('../pages/vessel-sounding/VesselSoundingMain'),
        routes: [
          {
            path: '/vessel-sounding-detail',
            beforeEnter: [
              onCommonHandle,
              onRequiredVesselVoyage,
              onRequiredCommonData,
            ],
            asyncComponent: () =>
              import('../pages/vessel-sounding/VesselSoundingDetail'),
          },
        ],
      },
    ],
  },
  {
    path: '/settings',
    component: Setting,
    beforeEnter: [onCommonHandle],
  },
  // {
  //   path: '/scan-qr',
  //   component: ScanQRCode,
  //   beforeEnter: [onCommonHandle],
  // },
  {
    path: '/users',
    component: User,
    beforeEnter: [onCommonHandle, onRequiredCommonData],
    routes: [
      {
        path: '/user-form',
        component: UserForm,
        beforeEnter: [
          onCommonHandle,
          onRequiredCommonData,
          onRequiredUserSelectedInfo,
        ],
      },
    ],
  },
  {
    path: '/menu-setting',
    component: Menu,
    beforeEnter: [onCommonHandle, onRequiredCommonData],
    routes: [
      {
        path: '/role-setting',
        component: RoleSetting,
        beforeEnter: [onCommonHandle, onRequiredCommonData],
      },
    ],
  },
  {
    path: '/role-terminal-setting',
    component: RoleTerminalSetting,
  },
  {
    path: '/vessel-sounding-draft',
    beforeEnter: onCommonHandle,
    component: VesselSoundingDraft,
  },
  {
    path: '/manage-role',
    beforeEnter: onCommonHandle,
    component: ManageRole,
  },
  {
    path: '/setting-role',
    beforeEnter: onCommonHandle,
    component: SettingRole,
  },
  {
    path: '/select-role',
    beforeEnter: onCommonHandle,
    component: SelectRole,
  },
  {
    path: '/login',
    redirect: handleLogin,
  },
  {
    path: '/profile',
    beforeEnter: onCommonHandle,
    component: Profile,
  },
  {
    path: '/register',
    component: Register,
  },
  {
    path: '/welcome',
    component: WelcomePage,
  },
  {
    path: '/verify',
    component: Verify,
  },
  {
    path: '/confirm',
    component: Confirm,
  },
  {
    path: '/reset-password',
    component: ResetPassword,
  },
  {
    path: '/confirm-email',
    component: ConfirmEmail,
  },
  {
    path: '/vessel-schedule',
    beforeEnter: onCommonHandle,
    component: VesselSchedule,
  },
  {
    path: '/vessel-schedule/details/:tmlCd/:vslVoyId',
    beforeEnter: onCommonHandle,
    component: VesselScheduleDetails,
  },
  {
    path: '/vessel-access-management',
    beforeEnter: [onCommonHandle, onRequiredCommonData],
    component: VesselAccessManagement,
    routes: [
      {
        path: '/vessel-access-form',
        beforeEnter: [onCommonHandle, onRequiredCommonData],
        component: VesselAccessForm,
      },
    ],
  },
  {
    path: '/cargo',
    beforeEnter: onCommonHandle,
    component: CargoList,
  },
  {
    path: '/cargo/details/:tmlCd/:tmlUtId',
    beforeEnter: onCommonHandle,
    component: CargoDetail,
  },
  {
    path: '/test-qr',
    component: TestQR,
  },
  {
    path: '(.*)',
    redirect: () => {
      window.location = '/';
    },
  },
];

export default routes;
