import React, { useEffect, useRef, useState } from 'react';
import {
  readBarcodesFromImageData,
  setZXingModuleOverrides,
} from 'otm-barcode-reader';
import Focus from './svg/focus';

const FOCUS_SIZE = 170;

export default function BarcodeDetector({ isStart, onScanned }) {
  const isScanning = useRef(false);
  const [stream, setStream] = useState(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    if (isStart) {
      startScanByCam();
    } else {
      stopScanByCam();
    }
  }, [isStart]);

  function initialize() {
    setZXingModuleOverrides({
      locateFile: (path, prefix) => {
        if (path.endsWith('.wasm')) {
          return `${window.location.origin}/static/lib/zxing_reader.wasm`;
        }
        return prefix + path;
      },
    });
  }

  async function startScanByCam() {
    if (isScanning.current) {
      return;
    }
    isScanning.current = true;
    const canvasElement = canvasRef.current;
    const canvas = canvasElement.getContext('2d');
    const stream = await navigator.mediaDevices.getUserMedia({
      video: { facingMode: 'environment' },
    });
    setStream(stream);
    videoRef.current.srcObject = stream;
    videoRef.current.setAttribute('playsinline', true);
    videoRef.current.play();

    const scanFrame = async () => {
      if (
        videoRef.current &&
        videoRef.current.readyState === videoRef.current.HAVE_ENOUGH_DATA
      ) {
        const videoBox = videoRef.current.getBoundingClientRect();
        canvasElement.height = videoBox.height;
        canvasElement.width = videoBox.width;
        canvas.drawImage(
          videoRef.current,
          0,
          0,
          canvasElement.width,
          canvasElement.height,
        );

        const x = (canvasElement.width - FOCUS_SIZE) / 2;
        const y = (canvasElement.height - FOCUS_SIZE) / 2;

        const imageData = canvas.getImageData(x, y, FOCUS_SIZE, FOCUS_SIZE);

        const code = await readBarcodesFromImageData(imageData, {
          tryHarder: true,
          formats: ['QRCode'],
          maxSymbols: 1,
        });
        if (code && code.length) {
          onScanned(code[0].text);
        }
      }

      if (isScanning.current) {
        requestAnimationFrame(scanFrame);
      }
    };

    scanFrame();
  }

  function stopScanByCam() {
    isScanning.current = false;
    videoRef.current.pause();
    if (stream) {
      stream.getTracks().forEach(track => {
        track.stop();
      });
    }
  }

  return (
    <>
      <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }} className='w-full h-full'>
        <video ref={videoRef} className='w-full h-auto' style={{ objectFit: 'contain' }} />
        <Focus size={FOCUS_SIZE} className="barcode-focus" />
      </div>
      <canvas ref={canvasRef} style={{ display: 'none' }} />
    </>
  );
}