import * as registerApi from '../api/register.api.js';
import * as emailApi from '../api/email.api'

const createParamsRegister = frmRegister => {
  const params = {
    name: frmRegister.name,
    gender: frmRegister.gender,
    email: frmRegister.email,
    idCountry: frmRegister.idCountry ? frmRegister.idCountry : null,
    birthDate: frmRegister.birthday,
    mobilePhoneNumber: frmRegister.mobilePhone,
    mobileCountryCode: frmRegister.mobileCountryCode,
    user: {
      username: frmRegister.username,
      password: frmRegister.password,
    },
  };

  return params;
};

export const register = async frmRegister => {
  try {
    const userInfo = createParamsRegister(frmRegister)
    await registerApi.register(userInfo);
    return true;
  } catch (error) {
    console.error(error.response);
    return false;
  }
};

export const validateEmail = async (token, email) => {
  try {
    await emailApi.validateEmail(token, email);
    return true;
  } catch (error) {
    console.error(error.response)
    return false;
  } 
};

export const getCountryCode = async () => {
  try {
    const lstCountryCode = await registerApi.getCountryCode();

    if (lstCountryCode)
      lstCountryCode.map(e => {
        e.phoneCountry = '(' + e.phoneCode + ')' + ' - ' + e.name;
        return e;
      });

    return lstCountryCode;
  } catch (error) {
    console.error(error.response);
    return [];
  }
};

export const checkUserExits = async username => {
  try {
    return await registerApi.getUserByUsername(username);
  } catch (error) {
    console.error(error.response);
    return true;
  }
};

export const checkEmailExits = async email => {
  try {
    return await registerApi.getPersonByEmail(email);
  } catch (error) {
    console.error(error.response);
    return true;
  }
};
