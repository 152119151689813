import React, { useEffect, useRef } from 'react';
import { Html5Qrcode, Html5QrcodeSupportedFormats } from "html5-qrcode";

export default function Html5QrCode({ isStart, onScanned }) {
  const html5QrcodeScanner = useRef(null);

  useEffect(() => {
    if (isStart) {
      startScanByCam();
    } else {
      stopScanByCam();
    }
  }, [isStart]);

  useEffect(() => {
    html5QrcodeScanner.current = new Html5Qrcode("videoReader", { formatsToSupport: [Html5QrcodeSupportedFormats.QR_CODE] });
  }, []);

  function startScanByCam() {
    html5QrcodeScanner.current?.start(
      { facingMode: "environment" },
      {
        fps: 10,    // Optional, frame per seconds for qr code scanning
        qrbox: { width: 150, height: 150 }  // Optional, if you want bounded box UI
      },
      onScanned
    )
  }

  function stopScanByCam() {
    html5QrcodeScanner.current?.stop();
  }

  return (
    <div
      id="videoReader"
      style={{ width: '100%', height: '100%' }}
      className='html5Video'
    />
  );
}