import { makeAutoObservable } from 'mobx';

export class AppStore {
  darkTheme = false;
  constructor() {
    makeAutoObservable(this);
  }

  setDarkTheme(value) {
    this.darkTheme = value;
  }

  clearStore() {
    this.darkTheme = false;
  }
}
