import React, { Component, useContext } from 'react';
import {
  Page,
  Navbar,
  List,
  ListItem,
  BlockTitle,
  Toggle,
  Block,
  Button,
} from 'framework7-react';
import SelectTerminalRole from '../../components/select-terminal-role';
import { StoreContext } from '../../store';
import { observer } from 'mobx-react';
const SettingPage = observer(props => {
  const { appStore } = useContext(StoreContext);
  return (
    <Page id="setting-page">
      <Navbar title={'Settings'} backLink="Back" /> {/** Settings */}
      <SelectTerminalRole />
      <BlockTitle>Interface</BlockTitle> {/** Interface */}
      <Block>
        <List noHairlines>
          <ListItem title={'Dark theme'}>
            <Toggle
              slot="after"
              onChange={() => {
                const isDarkThem = !appStore.darkTheme;
                localStorage.setItem('darkTheme', isDarkThem);
                appStore.setDarkTheme(isDarkThem);
              }}
              checked={appStore.darkTheme}
            />
          </ListItem>
        </List>
      </Block>
    </Page>
  );
});

export default SettingPage;
