import React, {
  Fragment,
  useEffect,
  useState,
  useContext,
  useMemo,
} from 'react';
import {
  Link,
  Navbar,
  NavLeft,
  NavTitle,
  Page,
  List,
  ListInput,
  BlockTitle,
  Icon,
  ListItem,
  Card,
  Block,
  Row,
  Col,
  Button,
  NavRight,
} from 'framework7-react';
import { StoreContext } from '../../store';
import * as terminalService from '../../services/terminal-service';
import * as menuService from '../../services/menu.service';
import * as roleService from '../../services/role.service';

const Menu = props => {
  const { commonStore, profileStore } = useContext(StoreContext);
  const [terminalList, setTerminalList] = useState([]);
  const [tmlCd, setTmlCd] = useState('');
  const [idTerminal, setIdTerminal] = useState('');
  const [roleId, setRoleId] = useState('');
  const [roleList, setRoleList] = useState([]);
  const [menuResourceList, setMenuResourceList] = useState([]);
  const [menuTerminalRoleLst, setMenuTerminalRoleLst] = useState([]);
  useEffect(async () => {
    initialize();
  }, []);

  const initialize = async () => {
    //Get terminal list
    let terminalList = commonStore.getTerminalList;
    if (!terminalList || !terminalList.length) {
      terminalList = await terminalService.getTerminals();
      commonStore.setTerminalList(terminalList);
    }
    setTerminalList(terminalList || []);

    //Get role list
    let roles = commonStore.getRoleList;
    if (!roles || !roles.length) {
      roles = await roleService.getRoleList();
      commonStore.setRoleList(roles);
    }
    setRoleList(roles || []);

    //Set defautl terminal and role
    setTmlCd(profileStore.terminal);
    setIdTerminal(profileStore.idTerminal);
    setRoleId(roles ? roles[0]?.roleId : '');
    //Get menu list resources
    const menuLst = await menuService.getMenuResource();
    setMenuResourceList(menuLst);
  };

  useMemo(async () => {
    if (!idTerminal || !roleId) return;
    const result = await menuService.getMenuTerminalRole(idTerminal, roleId);
    setMenuTerminalRoleLst(result || []);
  }, [roleId, idTerminal]);

  const onChangeTerminal = e => {
    e.preventDefault();
    // const tmlCdSelected = e.target.value;
    // setTmlCd(tmlCdSelected);
    setIdTerminal(e.target.value);
    setMenuTerminalRoleLst([]);
  };

  const onChangeRole = async e => {
    e.preventDefault();
    const roleSelected = e.target.value;
    setRoleId(roleSelected);
    setMenuTerminalRoleLst([]);
  };

  const isCheckedMenu = menuItem => {
    const menuSelected = (menuTerminalRoleLst || []).find(
      e => e.menuId == menuItem.menuId,
    );

    return menuSelected ? true : false;
  };

  const onChangeMenu = e => {
    const value = e.target.value;
    let currentData = menuTerminalRoleLst;
    if (e.target.checked) {
      currentData.push({ menuId: parseInt(value) });
    } else {
      currentData = currentData.filter(item => item.menuId != value);
    }
    setMenuTerminalRoleLst([...currentData]);
  };

  const showAlert = (title, content) => {
    props.$f7router.app.dialog.alert(content, title, () => {});
  };

  const onSaveClick = () => {
    props.$f7router.app.dialog.confirm(
      'Do you want to save menu config',
      'Confirm',
      () => {
        onSaveProcess();
      },
    );
  };

  const onSaveProcess = async () => {
    const menuIdList = menuTerminalRoleLst.map(item => {
      return item.menuId;
    });
    const params = { idTerminal, roleId, menuIdList };
    const result = await menuService.saveMenuTerminalRole(params);
    if (result) {
      showAlert('Info', 'Save successfully!');
    } else {
      showAlert('Warning', 'Failed! Please try again.');
    }
  };

  const onBtnSettingClick = () => {
    props.f7router.navigate('role-setting');
  };

  return (
    <Page>
      <Navbar>
        <NavLeft>
          <Link icon="icon-back" back />
        </NavLeft>
        <NavTitle>Menu setting</NavTitle>
        <NavRight>
          <Link
            color="black"
            iconSize={30}
            iconMaterial="add"
            onClick={onBtnSettingClick}
          ></Link>
        </NavRight>
      </Navbar>
      <Card style={{ margin: 0, minHeight: '100%' }}>
        <Block style={{ paddingTop: 10, paddingBottom: 10 }}>
          <List mediaList noHairlines>
            <BlockTitle className="strong">Terminal</BlockTitle>
            <ListInput
              type="select"
              validate
              value={idTerminal}
              onChange={e => {
                onChangeTerminal(e);
              }}
            >
              {terminalList &&
                terminalList.map((item, index) => (
                  <option key={index} value={item.idTerminal}>
                    {item.tmlCd ? item.tmlCd + ' - ' : ''}
                    {item.name}
                  </option>
                ))}
            </ListInput>
          </List>
          <List>
            <BlockTitle className="strong">Role</BlockTitle>
            <ListInput
              type="select"
              validate
              value={roleId}
              onChange={e => {
                onChangeRole(e);
              }}
            >
              {roleList &&
                roleList.map((item, index) => (
                  <option key={index} value={item.roleId}>
                    {item.name}
                  </option>
                ))}
            </ListInput>
          </List>
          {idTerminal && roleId && (
            <Fragment>
              <Row>
                <List>
                  <BlockTitle className="strong">Menu</BlockTitle>
                  {(menuResourceList || []).map((item, index) => (
                    <ListItem
                      key={index}
                      checkbox
                      value={item.menuId}
                      title={item.name}
                      checked={isCheckedMenu(item)}
                      onChange={e => {
                        onChangeMenu(e);
                      }}
                    >
                      <Icon slot="media" material={item.icon}></Icon>
                    </ListItem>
                  ))}
                </List>
              </Row>
              <Row>
                <Col>
                  <Button
                    id="saveBtn"
                    className="button button-fill button-round"
                    style={{
                      width: '160px',
                      margin: 'auto',
                    }}
                    onClick={onSaveClick}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Fragment>
          )}
        </Block>
      </Card>
    </Page>
  );
};

export default Menu;
{
  /* <ListItem key={index} title={item.name} link={item.path}>
              <Icon slot="media" material={item.icon}></Icon>
            </ListItem> */
}
