import axios from './axios';
import queryString from 'query-string';
export const getComCodes = async (idTerminal, grpNm) => {
  const { data } = await axios.get(`common/com-codes/${idTerminal}/${grpNm}`);
  return data;
};

export const getConfigurations = async idTerminal => {
  const { data } = await axios.get(`common/photo-configurations/${idTerminal}`);
  return data;
};

export const getComCodesCp = async (tmlCd, grpId) => {
  const { data } = await axios.get(`com-codes-cp/${tmlCd}/${grpId}`);
  return data;
};

export const deQrCode = async file => {
  const formData = new FormData();
  formData.append('image', file);
  const result = await axios.post(`common/decode`, formData);
  return result.data;
};

export const getTerminalHost = async idTerminal => {
  const { data } = await axios.get(`common/terminal-host/${idTerminal}`);
  return data;
};
