import { makeAutoObservable, toJS } from 'mobx';

export class JettySupervisorStore {
  constructor() {
    makeAutoObservable(this);
  }

  vsllLogDetailGrouping = [];

  berthGroups = [];

  berths = [];

  alongsideCodes = [];

  berthBaselines = [];

  vesselVoyageImages = [];

  currentGroupIndex = '';

  isSameTmlConfig = false;

  vesselSchedule = {};

  berthPositionPopup = {
    isOpen: false,
    berthPosition: {},
  };

  get getVsllLogDetailGrouping() {
    return toJS(this.vsllLogDetailGrouping);
  }

  setVsllLogDetailGrouping(vsllLogDetailGrouping) {
    this.vsllLogDetailGrouping = vsllLogDetailGrouping;
  }

  /* Current Group Index */
  get getCurrentGroupIndex() {
    return toJS(this.currentGroupIndex);
  }

  setCurrentGroupIndex(currentGroupIndex) {
    this.currentGroupIndex = currentGroupIndex;
  }

  parseProxyToJson(object) {
    return toJS(object);
  }

  /* Vessel Schedule */
  get getVesselSchedule() {
    return toJS(this.vesselSchedule);
  }

  setVesselSchedule(vesselSchedule) {
    this.vesselSchedule = {
      ...this.vesselSchedule,
      ...vesselSchedule,
    };
  }

  /* Berth Position Popup */
  get getBerthPositionPopup() {
    return toJS(this.berthPositionPopup);
  }

  setBerthPositionPopup(berthPositionPopup) {
    this.berthPositionPopup = {
      ...this.berthPositionPopup,
      ...berthPositionPopup,
    };
  }

  setBerthPosition(berthPosition) {
    this.berthPositionPopup.berthPosition = {
      ...this.berthPositionPopup.berthPosition,
      ...berthPosition,
    };
  }

  /* Berths */
  get getBerths() {
    return toJS(this.berths);
  }

  setBerths(berths) {
    this.berths = berths;
  }

  /* Berth Groups */
  get getBerthGroups() {
    return toJS(this.berthGroups);
  }

  setBerthGroups(berthGroups) {
    this.berthGroups = berthGroups;
  }

  /* Alongside COde */
  get getAlongsideCodes() {
    return toJS(this.alongsideCodes);
  }

  setAlongsideCodes(alongsideCodes) {
    this.alongsideCodes = alongsideCodes;
  }

  /* Berth Baseline */
  get getBerthBaselines() {
    return toJS(this.berthBaselines);
  }

  setBerthBaselines(berthBaselines) {
    this.berthBaselines = berthBaselines;
  }

  /* Jetty Supervisor Image */
  get getVesselVoyageImages() {
    return toJS(this.vesselVoyageImages);
  }

  setVesselVoyageImages(vesselVoyageImages) {
    this.vesselVoyageImages = vesselVoyageImages;
  }

  /*isSameTmlConfig */
  get getIsTheSameConfig() {
    return toJS(this.isSameTmlConfig);
  }

  setIsTheSameConfig(isSameTmlConfig) {
    this.isSameTmlConfig = isSameTmlConfig;
  }
}
