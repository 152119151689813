import {
  Block,
  Button,
  Navbar,
  Page,
  Toolbar,
  NavTitle,
} from 'framework7-react';
import React, { useEffect } from 'react';
import * as registerService from '../../services/register.service';

const ConfirmEmail = props => {
  const { token, email } = props.$f7route.query;

  useEffect(async () => {
    if (!token || !email) {
      props.$f7router.app.dialog.alert('Verify Email failed!');
      return;
    }
    const success = await registerService.validateEmail(token, email);

    if (success) {
      props.$f7router.app.dialog.alert('Verify Email sucessed!');
      return;
    }

    props.$f7router.app.dialog.alert('Verify Email failed!');
  }, []);

  return (
    <Page>
      <Navbar>
        <NavTitle>New member</NavTitle>
      </Navbar>

      <Block>
        <img
          className="logo-system"
          src="../static/img/logo-opus.svg"
          style={{ width: '260px' }}
          alt="logo-opus"
        ></img>
      </Block>

      <Toolbar
        bottom
        noHairline
        noShadow
        noBorder
        bgColor="unset"
        style={{
          border: 'unset',
        }}
      >
        <Button
          fill
          style={{
            width: '100%',
          }}
          external
          href="/"
        >
          Sign In
        </Button>
      </Toolbar>
    </Page>
  );
};

export default ConfirmEmail;
