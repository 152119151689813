import * as roleTerminalApi from '../api/role-terminal.api';
import { ROLES } from '../const';
import { profile } from '../api/auth.api';

export const getRoleTerminals = async roleId => {
  try {
    return await roleTerminalApi.getRoleTerminals(roleId);
  } catch (error) {
    console.error(error.response);
    return [];
  }
};
export const saveRoleTerminals = async params => {
  try {
    await roleTerminalApi.saveRoleTerminals(params);
    return true;
  } catch (error) {
    console.error(error.response);
    return false;
  }
};
