import React from 'react';

export default function Focus({ className, size = 150 }) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 490.254 490.254">
      <g>
        <polygon points="0,169.503 15.297,169.503 15.297,15.428 169.376,15.428 169.376,0.131 0,0.131 	" />
        <polygon points="15.297,320.751 0,320.751 0,490.124 169.376,490.124 169.376,474.826 15.297,474.826 	" />
        <polygon points="320.878,0.131 320.878,15.428 474.957,15.428 474.957,169.503 490.254,169.503 490.254,0.131 	" />
        <polygon points="474.957,474.826 320.878,474.826 320.878,490.124 490.254,490.124 490.254,320.751 474.957,320.751 	" />
      </g>
    </svg>
  );
}