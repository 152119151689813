import { makeAutoObservable, toJS } from 'mobx';

export class VesselSealManagementStore {
  constructor() {
    makeAutoObservable(this);
  }

  inputFields = {
    vslNm: '',
    selectedVslVoyCds: '',
    vslVoySkdStsCdLst: [],
    selectedBerthingPurposeCodes: [],
    berthingPurposeCodes: [],
  };
  groupByCompartment = true;
  seals = [];
  currentSeal = {};
  compartmentCodes = [];
  vesselEquipmentCodes = [];
  vesselLocationCodes = [];
  vesselSealTypeCodes = [];
  vesselSealConditionCodes = [];
  listGroupSeals = [];
  vesselSealImages = [];
  selectedVesselLocationCode = '';
  isBackFromPhotoPage = false;
  ignoreCheckPosSealList = [];

  popupReplaceSeal = {
    isOpen: false,
    currentSeal: {},
    newSeal: {},
  };

  setIsBackFromPhotoPage(isBackFromPhotoPage) {
    this.isBackFromPhotoPage = isBackFromPhotoPage;
  }

  get getInputFields() {
    return this.inputFields;
  }

  setInputFields(inputFields) {
    this.inputFields = {
      ...this.inputFields,
      ...inputFields,
    };
  }

  get getCurrentSeal() {
    return this.currentSeal;
  }
  setCurrentSeal = seal => {
    this.currentSeal = { ...this.currentSeal, ...seal };
  };

  get getCompartmentCodes() {
    return this.compartmentCodes;
  }
  setCompartmentCodes = data => {
    this.compartmentCodes = data;
  };

  get getVesselEquipmentCodes() {
    return this.vesselEquipmentCodes;
  }
  setVesselEquipmentCodes = data => {
    this.vesselEquipmentCodes = data;
  };

  get getVesselLocationCodes() {
    return this.vesselLocationCodes;
  }
  setVesselLocationCodes = data => {
    this.vesselLocationCodes = data;
  };

  get getVesselSealTypeCodes() {
    return this.vesselSealTypeCodes;
  }
  setVesselSealTypeCodes = data => {
    this.vesselSealTypeCodes = data;
  };

  get getVesselSealConditionCodes() {
    return this.vesselSealConditionCodes;
  }
  setVesselSealConditionCodes = data => {
    this.vesselSealConditionCodes = data;
  };

  get getSelectedVesselLocationCode() {
    return this.selectedVesselLocationCode;
  }
  setSelectedVesselLocationCode = value => {
    this.selectedVesselLocationCode = value;
  };

  get getSeals() {
    return this.seals;
  }
  setSeals = dataLst => {
    this.seals = dataLst;
  };

  get isGroupByCompartment() {
    return this.groupByCompartment;
  }
  setGroupByCompartment = value => {
    this.groupByCompartment = value;
  };

  get getListGroupSeals() {
    return this.listGroupSeals;
  }
  setListGroupSeals = dataList => {
    this.listGroupSeals = dataList;
  };

  get getPopupReplaceSeal() {
    return toJS(this.popupReplaceSeal);
  }
  setPopupReplaceSeal = popupReplaceSeal => {
    this.popupReplaceSeal = {
      ...this.popupReplaceSeal,
      ...popupReplaceSeal,
    };
  };

  setPopupReplaceSealCurrentSeal(data) {
    this.popupReplaceSeal.currentSeal = {
      ...this.popupReplaceSeal.currentSeal,
      ...data,
    };
  }
  setPopupReplaceSealNewSeal(data) {
    this.popupReplaceSeal.newSeal = {
      ...this.popupReplaceSeal.newSeal,
      ...data,
    };
  }

  get getVesselSealImages() {
    return this.vesselSealImages;
  }
  setVesselSealImages = dataLst => {
    this.vesselSealImages = dataLst;
  };

  get getIgnoreCheckPosSealList() {
    return this.ignoreCheckPosSealList;
  }
  setIgnoreCheckPosSealList = dataList => {
    this.ignoreCheckPosSealList = dataList || [];
  };

  clearStore() {
    this.groupByCompartment = true;
    this.seals = [];
    this.currentSeal = {};
    this.compartmentCodes = [];
    this.vesselEquipmentCodes = [];
    this.vesselLocationCodes = [];
    this.vesselSealTypeCodes = [];
    this.vesselSealConditionCodes = [];
    this.vesselSealImages = [];
    this.selectedVesselLocationCode = '';
    this.listGroupSeals = [];
    this.ignoreCheckPosSealList = [];
    this.popupReplaceSeal = {
      isOpen: false,
      currentSeal: {},
      newSeal: {},
    };
  }
}
