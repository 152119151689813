import axios from 'axios';
import Cookies from 'js-cookie';

const instance = axios.create({
  baseURL: '/api',
});

instance.interceptors.request.use(function(config) {
  console.log('Cookies', Cookies.get('access_token'));
  if (!Cookies.get('access_token')) {
    return config;
  }

  config.headers['Authorization'] = 'Bearer ' + Cookies.get('access_token');
  return config;
});

export default instance;
