import axios from './axios';

export const getTerminal = async () => {
  const lstTerminal = await axios.get('terminals/');
  return lstTerminal.data;
};

export const getTerminalsRoles = async () => {
  const { data } = await axios.get('terminals/roles');
  return data;
};
