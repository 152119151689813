import { makeAutoObservable, toJS } from 'mobx';

export class VesselMainpageStore {
  constructor() {
    makeAutoObservable(this);
  }

  inputFields = {
    vslNm: '',
    selectedVslVoyCds: '',
    vslVoySkdStsCdLst: [],
    selectedBerthingPurposeCodes: [],
    berthingPurposeCodes: [],
  };

  vslVoyageLst = [];

  selectedVoyage = {};

  get getInputFields() {
    return toJS(this.inputFields);
  }

  setInputFields(inputFields) {
    this.inputFields = {
      ...this.inputFields,
      ...inputFields,
    };
  }

  get getVslVoyageLst() {
    return toJS(this.vslVoyageLst);
  }

  setVslVoyageLst(vslVoyageLst) {
    this.vslVoyageLst = vslVoyageLst;
  }

  get getSelectedVoyage() {
    return toJS(this.selectedVoyage);
  }

  setSelectedVoyage(selectedVoyage) {
    this.selectedVoyage = selectedVoyage;
  }
}
