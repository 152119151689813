import { f7 } from 'framework7-react';
import React, { useEffect, useRef, useState } from 'react';

import {
  readBarcodesFromImageData,
  setZXingModuleOverrides,
} from 'otm-barcode-reader';

export default function QrCodeImage({ file, onFinish }) {
  const [imgBase64, setImgBase64] = useState('');
  const imgRef = useRef(null);

  useEffect(() => {
    setZXingModuleOverrides({
      locateFile: (path, prefix) => {
        if (path.endsWith('.wasm')) {
          return `${window.location.origin}/static/lib/zxing_reader.wasm`;
        }
        return prefix + path;
      },
    });
  }, []);

  useEffect(() => {
    setImgBase64('');
    if (file) {
      handleFile(file);
    }
  }, [file]);

  const showLoading = () => {
    f7.dialog.progress('Decoding...');
  };

  const hideLoading = () => {
    f7.dialog.close();
  };

  const handleFile = (file) => {
    if (!file) {
      return;
    }
    try {
      const reader = new FileReader();
      reader.onloadend = async () => {
        setImgBase64(reader.result);
        // showLoading();
        const code = await handleScanImage(`${reader.result}`);
        if (!code) {
          f7.dialog.alert('Not found.', 'QR code');
        }
        onFinish(code);
      };
      reader.readAsDataURL(file);
      event.target.value = null;
    } catch (err) {
      console.error(err);
    } finally {
      // hideLoading();
    }
  };

  const handleScanImage = async (base64String) => {
    return new Promise(resolve => {
      const image = document.createElement('img');
      image.onload = async () => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.width = image.width;
        canvas.height = image.height;
        context.drawImage(image, 0, 0);
        const imageData = context.getImageData(0, 0, image.width, image.height);

        const code = await readBarcodesFromImageData(imageData, {
          tryHarder: true,
          formats: ['QRCode'],
          maxSymbols: 1,
        });
        if (code && code.length) {
          resolve(code[0].text);
        }
      };
      image.src = base64String;
    });
  };

  return (
    <img
      ref={imgRef}
      src={imgBase64}
      style={{ width: '100%', height: '100%', objectFit: 'contain' }}
    />
  );
}