import {
  Block,
  Link,
  NavRight,
  Navbar,
  Page,
  Popup,
  f7,
} from 'framework7-react';
import React, { useRef, useState } from 'react';
import { QR_SCAN_LIB, QR_SCAN_LIB_DEFAULT } from '../../const';
import BarcodeDetector from './component/barcode-detector';
import Html5QrCode from './component/html5-qr-code';
import JsQr from './component/jsqr';
import QrScanner from './component/qr-scanner';
import ReactQrReader from './component/react-qr-reader';
import Zxing from './component/zxing';
import QrCodeImage from './component/qr-code-image';
import './style.scss';

export default function CommonQrCode({ isOpen, onPopupClosed, libId: libIdInput, onScanned }) {
  const [scanMode, setScanMode] = useState('CAM');
  const isOpenDialog = useRef(false);
  const [isStartScanByCam, setIsStartScanByCam] = useState(false);
  const [imageFile, setImageFile] = useState(null);

  const libId = libIdInput || localStorage.getItem('qrLibId') || QR_SCAN_LIB_DEFAULT;
  let QrLib = null;
  let qrName = ""
  function chooseQrLib(libId) {
    if (libId === QR_SCAN_LIB.BARCODE_DETECTOR.id) {
      QrLib = BarcodeDetector;
      qrName = QR_SCAN_LIB.BARCODE_DETECTOR.name;
    } else if (libId === QR_SCAN_LIB.ZXING.id) {
      QrLib = Zxing;
      qrName = QR_SCAN_LIB.ZXING.name;
    } else if (libId === QR_SCAN_LIB.JSQR.id) {
      QrLib = JsQr;
      qrName = QR_SCAN_LIB.JSQR.name;
    } else if (libId === QR_SCAN_LIB.REACT_QR.id) {
      QrLib = ReactQrReader;
      qrName = QR_SCAN_LIB.REACT_QR.name;
    } else if (libId === QR_SCAN_LIB.QR_SCANNER.id) {
      QrLib = QrScanner;
      qrName = QR_SCAN_LIB.QR_SCANNER.name;
    } else if (libId === QR_SCAN_LIB.HTML5_QR_CODE.id) {
      QrLib = Html5QrCode;
      qrName = QR_SCAN_LIB.HTML5_QR_CODE.name;
    } else {
      QrLib = BarcodeDetector; // default;
      qrName = QR_SCAN_LIB.BARCODE_DETECTOR.name;
    }
  }
  chooseQrLib(libId);

  const handlePopupOpen = async () => {
    if (scanMode !== 'CAM') {
      return;
    }
    setIsStartScanByCam(true);
  };

  const handlePopupClosed = () => {
    setScanMode('CAM');
    if (onPopupClosed && typeof onPopupClosed === 'function') {
      onPopupClosed();
    }
    // stop cam
    setIsStartScanByCam(false);
  };

  function handleScanFinished(code) {
    if (onScanned && typeof onScanned === 'function') {
      onScanned(code);
      handlePopupClosed();
    } else if (!isOpenDialog.current) {
      f7.dialog.alert(
        code,
        'QR Code: ',
        () => (isOpenDialog.current = false),
      );
      isOpenDialog.current = true;
    }
  }

  function handleChangeScanMode(mode) {
    setScanMode(mode);
    if (mode === 'FILE') {
      setIsStartScanByCam(false);
    } else {
      setIsStartScanByCam(true);
      setImageFile(null);
    }
  }

  function handleFileChange(event) {
    const file = event.target.files[0];
    setImageFile(file);
  }

  return (
    <Popup
      className="demo-popup"
      swipeToClose
      opened={isOpen}
      onPopupOpen={handlePopupOpen}
      onPopupClosed={handlePopupClosed}
    >
      <Page>
        <Navbar title={qrName || ""}>
          <NavRight>
            <Link popupClose>Close</Link>
          </NavRight>
        </Navbar>
        <Block className="qr-scan-block flex">
          <div className="qr-scan-camera w-full grow">
            <div hidden={scanMode !== 'CAM'} className='w-full h-full flex justify-center items-center overflow-hidden'>
              <QrLib isStart={isStartScanByCam} onScanned={handleScanFinished} />
            </div>

            {scanMode === 'FILE' && (
              <QrCodeImage onFinish={handleScanFinished} file={imageFile} />
            )}

          </div>
          <div className="qr-scan-btn-area">
            <div className='relative'>
              <i
                className="material-icons"
                style={{ color: 'white' }}
              >
                collections
              </i>
              <input
                onClick={() => {
                  handleChangeScanMode('FILE');
                  setImageFile(null);
                }}
                className='input-choose-image'
                accept="image/*"
                onChange={handleFileChange}
                type="file"
              ></input>
            </div>
            <div>
              <i
                className="material-icons"
                style={{ color: 'white' }}
                onClick={() => handleChangeScanMode('CAM')}
              >
                party_mode
              </i>
            </div>
          </div>
        </Block>
      </Page>
    </Popup>
  );
}