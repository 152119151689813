import axios from './axios';

export const validateEmail = async (token, email) => {
  await axios.put(`accounts/vaildate/email?token=${token}`, {
    email: email,
  });
};

export const isValidEmail = async (token, email) => {
  const res = await axios.get(
    `accounts/verify/email?token=${token}&email=${email}`,
  );
  return res.data;
};
