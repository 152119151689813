import * as vesselVoyageApi from '../api/vessel-voyage.api';
export const getVesselList = async (role, tmlCd, idCompany) => {
  try {
    const data = await vesselVoyageApi.getVesselList(role, tmlCd, idCompany);
    const departureLst = data
      .filter(
        item =>
          item.vslVoySkdStsCd === '0103X' || item.vslVoySkdStsCd === '0103D',
      )
      .sort(
        (a, b) => new Date(a.etaDt).getTime() - new Date(b.etaDt).getTime(),
      );

    const plannedLst = data
      .filter(item => item.vslVoySkdStsCd === '0103P')
      .sort(
        (a, b) => new Date(a.etaDt).getTime() - new Date(b.etaDt).getTime(),
      );

    const arrivalLst = data
      .filter(
        item =>
          item.vslVoySkdStsCd !== '0103X' &&
          item.vslVoySkdStsCd !== '0103D' &&
          item.vslVoySkdStsCd !== '0103P',
      )
      .sort(
        (a, b) => new Date(a.etaDt).getTime() - new Date(b.etaDt).getTime(),
      );

    return {
      departureLst: departureLst,
      plannedLst: plannedLst,
      arrivalLst: arrivalLst,
    };

  } catch (error) {
    console.error(error);
    return {
      departureLst: [],
      plannedLst: [],
      arrivalLst: [],
    };
  }
};

export const getVesselDetails = async (tmlCd, vslVoyId) => {
  try {
    const data = await vesselVoyageApi.getVesselDetails(tmlCd, vslVoyId);
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
