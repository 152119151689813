import React, {
  Fragment,
  useRef,
  useState,
  useEffect,
  useContext,
} from 'react';
import {
  List,
  ListItem,
  AccordionContent,
  BlockTitle,
  Row,
  Badge,
  Button,
  Col,
} from 'framework7-react';
import TerminalVesselForm from './TerminalVesselForm';
import { StoreContext } from '../../store';
import * as userService from '../../services/user.service';
import * as menuService from '../../services/menu.service';

const TerminalVessel = props => {
  const { userStore, profileStore } = useContext(StoreContext);
  const [openSheet, setOpenSheet] = useState(false);
  const formRef = useRef();
  const [userTerminalVessels, setUserTerminalVessels] = useState([]);
  const [menuResourceList, setMenuResourceList] = useState([]);
  const [dataModify, setDataModify] = useState({
    tmlCd: '',
    vslCdList: [],
  });

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    await getMenuResourceList();
    initTerminalVesselList(userStore.getUser.userTerminalVessels);
  };
  const getMenuResourceList = async () => {
    //Get menu list resources
    const menuLst = await menuService.getMenuResource();
    setMenuResourceList(menuLst);
  };
  const initTerminalVesselList = (terminalVessels = []) => {
    const { terminal, sysAdm } = profileStore;
    let result = groupByTmlCd(terminalVessels);
    // if (!sysAdm || sysAdm !== 'Y') {
    //   result = result.filter(item => item.tmlCd === terminal);
    // }
    setUserTerminalVessels(result || []);
  };
  const groupByTmlCd = terminalVessel => {
    const groupedData = {};
    terminalVessel.forEach(item => {
      const tmlCd = item.terminal.tmlCd;
      if (!groupedData[tmlCd]) {
        groupedData[tmlCd] = {
          name: item.terminal.name,
          tmlCd,
          items: [],
        };
      }
      groupedData[tmlCd].items.push({
        vslCds: (item.vslCds || '').split(',').map(vslCd => vslCd.trim()),
        menuId: item.menuId,
      });
    });
    return Object.values(groupedData);
  };

  const renderMenuName = menuId => {
    const menu = menuResourceList.find(item => item.menuId == menuId);
    return <span>{menu.name}</span>;
  };
  const renderVslCdLst = vslCdList => {
    return (
      <Fragment>
        {vslCdList.map(
          (vslCd, index) =>
            vslCd && (
              <Badge style={{ margin: '3px' }} key={index}>
                {vslCd}
              </Badge>
            ),
        )}
      </Fragment>
    );
  };

  const renderEditIcon = (tmlCd, vslCdList) => {
    return (
      <i
        className="icon material-icons"
        style={{
          color: 'gray',
          fontSize: '20px',
          position: 'absolute',
          top: '10px',
          right: '15px',
        }}
        onClick={() => {
          onEditTerminalVessel(tmlCd, vslCdList);
        }}
      >
        edit
      </i>
    );
  };

  const onEditTerminalVessel = (tmlCd, vslCdList) => {
    setDataModify({ tmlCd, vslCdList });
    onOpenForm();
  };

  const onOpenForm = () => {
    if (profileStore.sysAdm !== 'Y') {
      setDataModify({ tmlCd: profileStore.terminal, vslCdList: [] });
    }
    setOpenSheet(true);
  };

  const onAfterChangeData = async () => {
    const { idUser } = userStore.getUser;
    const newInfo = await userService.fetchUserInfo(idUser);
    initTerminalVesselList(newInfo.userTerminalVessels);
    userStore.updateUserInList(newInfo);
    userStore.setUser(newInfo);
  };

  const CSS = `
  .terminal-vessel-item .item-title{
    font-weight: unset !important;
  }
  `;

  return (
    <Fragment>
      <style>{CSS}</style>
      <List accordionList>
        <ListItem accordionItem title="Terminal-Vessel Working">
          <AccordionContent>
            <List mediaList>
              {userTerminalVessels.map((terminalVessel, index) => (
                <ListItem
                  key={index}
                  className="terminal-vessel-item"
                  title={`${terminalVessel.tmlCd}-${terminalVessel.name}`}
                >
                  {terminalVessel.items.map((item, index2) => (
                    <div style={{ padding: '5px 5px 5px 15px' }} key={index2}>
                      {renderMenuName(item.menuId)}
                      <br />
                      {renderVslCdLst(item.vslCds)}
                    </div>
                  ))}

                  {/* {renderEditIcon(item.tmlCd, item.vslCdList)} */}
                </ListItem>
              ))}
            </List>
            {/* {(profileStore.sysAdm === 'Y' ||
              userTerminalVessels.length < 1) && (
              <Row>
                <Col>
                  <Button
                    style={{
                      width: '160px',
                      margin: 'auto',
                    }}
                    raised
                    fill
                    onClick={onOpenForm}
                  >
                    Add
                  </Button>
                </Col>
              </Row>
            )} */}
          </AccordionContent>
        </ListItem>
      </List>
      <TerminalVesselForm
        isOpen={openSheet}
        data={dataModify}
        onAfterSave={onAfterChangeData}
        onSheetClosed={() => {
          setOpenSheet(false);
        }}
      />
    </Fragment>
  );
};

export default TerminalVessel;
