import React, { useEffect, useState } from 'react';
import {
  Block,
  Button,
  List,
  ListInput,
  Page,
  Toolbar,
  Link,
  BlockHeader,
} from 'framework7-react/framework7-react.esm';
import * as resetPasswordService from '../../services/reset-password.service';

const resetPassword = props => {
  const { token, email } = props.$f7route.query;

  const [frmState, setFrmState] = useState({
    password: '',
    retypePassword: '',
  });

  const [isValidEmail, setValidEmail] = useState(true);

  useEffect(async () => {
    if (!token || !email) {
      setValidEmail(false);
      return;
    }
    const isValid = await resetPasswordService.isValidEmail(token, email);
    setValidEmail(isValid);
  }, []);

  const onInputForm = e => {
    const input = e.target;
    frmState[input.name] = input.value;
    setFrmState(frmState);
  };

  const btnResetPasswordClick = async () => {
    if (frmState.password !== frmState.retypePassword) {
      props.$f7router.app.dialog.alert(
        'Password not match, Please check again!',
        'Warning',
      );
      return;
    }

    const success = await resetPasswordService.resetPassword(
      token,
      email,
      frmState.password,
    );

    if (success) {
      props.$f7router.app.dialog.alert('Save success', 'Info', () => {
        const loginBtn = document.getElementById('loginBtn');
        loginBtn.click();
      });
      return;
    }

    props.$f7router.app.dialog.alert('Reset password failed!', 'Error');
  };

  const formResetPassword = (
    <Block>
      <List form id="frmState">
        <ListInput
          label="New Password"
          type="password"
          name="password"
          placeholder="Your password"
          onInput={onInputForm}
          required
          validate
        />
        <ListInput
          label="Retype New Password"
          type="password"
          name="retypePassword"
          placeholder="Your Password again"
          onInput={onInputForm}
          required
          validate
        />
      </List>

      <Block style={{ display: 'table' }}>
        <Button
          style={{ width: '200px' }}
          fill
          round
          onClick={btnResetPasswordClick}
        >
          Reset Password
        </Button>
      </Block>
    </Block>
  );

  return (
    <Page noNavbar noSwipeback loginScreen>
      <Block
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img className="logo-system" style={{ width: '260px' }}></img>
      </Block>
      <BlockHeader style={{ fontSize: '20px', color: 'rgba(0,0,0,0.6)' }}>
        Forgot the password?
      </BlockHeader>
      {!isValidEmail ? (
        <Block
          style={{
            textAlign: 'center',
          }}
        >
          Verify email failed. Please try again!
        </Block>
      ) : (
        formResetPassword
      )}
      <Toolbar
        bottom
        noHairline
        noShadow
        noBorder
        bgColor="unset"
        inner={false}
      >
        <Link href="/" external id="loginBtn">
          Login
        </Link>
      </Toolbar>
    </Page>
  );
};

export default resetPassword;
