import { configure } from 'mobx';
import { createContext } from 'react';
import { JettySupervisorStore } from './jetty-supervisor.store';
import { UserStore } from './user.store';
import { VesselMainpageStore } from './vessel-mainpage.store';
import { VesselSealManagementStore } from './vessel-seal-management.store';
import { VesselSoundingStore } from './vessel-sounding.store';
import { AppStore } from './app.store';
import { ProfileStore } from './profile.store';
import { CommonStore } from './common.store';
import { VesselAccessManagementStore } from './vessel-access-management.store';

configure({
  enforceActions: 'always',
  computedRequiresReaction: true,
  reactionRequiresObservable: true,
  observableRequiresReaction: true,
  disableErrorBoundaries: true,
});

class Store {
  constructor() {
    this.appStore = new AppStore();
    this.userStore = new UserStore();
    this.vesselMainpageStore = new VesselMainpageStore();
    this.vesselSoundingStore = new VesselSoundingStore();
    this.vesselSealManagementStore = new VesselSealManagementStore();
    this.jettySupervisorStore = new JettySupervisorStore();
    this.profileStore = new ProfileStore();
    this.commonStore = new CommonStore();
    this.vesselAccessManagementStore = new VesselAccessManagementStore();
  }
}

export const store = new Store();

export const StoreContext = createContext(store);
