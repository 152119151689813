import {
  List,
  ListItem,
  Page,
  Navbar,
  NavLeft,
  NavRight,
  Link,
  Icon,
  BlockTitle,
} from 'framework7-react';
import React, { useState, useContext, useEffect } from 'react';
import * as roleService from '../../services/role.service';
import { StoreContext } from '../../store';
import * as storeService from '../../services/store.service';
import Cookies from 'js-cookie';

const SelectRole = props => {
  const { profileStore } = useContext(StoreContext);
  const [roleList, setRoleList] = useState([]);

  useEffect(async () => {
    await initialize();
  }, []);

  const initialize = async () => {
    const roleLst = await roleService.getRoleList(profileStore.role);
    setRoleList(roleLst);
  };

  const onSelectRole = async roleInfo => {
    if (roleInfo.active) {
      return;
    }

    await storeService.setRoleInfo(roleInfo);
    props.$f7router.navigate('/main', { clearPreviousHistory: true }); // clear login page in history
  };

  async function onBackClick() {
    // Check to back login page
    if (props.toMainPage) {
      Cookies.remove('access_token');
      props.$f7router.back('/', { force: true });
      return;
    }

    props.$f7router.back();
  }

  return (
    <Page>
      <Navbar>
        <NavLeft>
          <Link icon="icon-back" onClick={onBackClick} />
        </NavLeft>
        <NavRight>
          <Link>
            <img className="logo-system" style={{ maxHeight: '30px' }} />
          </Link>
        </NavRight>
      </Navbar>
      <BlockTitle>Select Role</BlockTitle>
      <List mediaList>
        {roleList.map((r, index) => {
          return (
            <ListItem
              key={index}
              header={r.tmlNm || ''}
              title={r.roleNm}
              text={r.companyNm || ''}
              onClick={() => onSelectRole(r)}
            >
              {r.active && (
                <Icon
                  material="check"
                  style={{ display: 'block' }}
                  slot="after"
                  color="blue"
                />
              )}
            </ListItem>
          );
        })}
      </List>
    </Page>
  );
};

export default SelectRole;
