import { makeAutoObservable, toJS } from 'mobx';

export class VesselAccessManagementStore {
  constructor() {
    makeAutoObservable(this);
  }

  formValues = {
    userIdLst: [],
    vvdList: [],
    menuIdList: [],
    tmlCd: '',
    idTerminal: '',
  };

  fetchData = 0;

  setFormValue(data) {
    this.formValues = { ...data };
  }

  setFetchData() {
    this.fetchData++;
  }

  get getFormValues() {
    return this.formValues;
  }

  clear() {
    this.formValues = {
      userIdLst: [],
      vvdList: [],
      menuIdList: [],
      tmlCd: '',
      idTerminal: '',
    };
  }
}
