import { BrowserQRCodeReader } from "@zxing/library";
import React, { useEffect, useRef } from 'react';

export default function Zxing({ isStart, onScanned }) {
  const codeReader = useRef(new BrowserQRCodeReader());
  const videoRef = useRef(null);

  useEffect(() => {
    if (isStart) {
      startScanByCam();
    } else {
      stopScanByCam();
    }
  }, [isStart]);

  function decodeContinuously(selectedDeviceId) {
    codeReader.current.decodeFromInputVideoDeviceContinuously(
      selectedDeviceId,
      "video",
      (result) => {
        if (result) {
          onScanned(result.text);
        }
      }
    );
  }

  async function startScanByCam() {
    const videoInputDevices = await codeReader.current.getVideoInputDevices();
    const defaultDevice = videoInputDevices.find((device) =>
      device.label.toLowerCase().includes("rear") ||
      device.label.toLowerCase().includes("back")
    );

    if (defaultDevice) {
      decodeContinuously(defaultDevice.deviceId);
    } else {
      decodeContinuously(videoInputDevices[0].deviceId);
    }
  }

  function stopScanByCam() {
    codeReader.current.reset();
    if (videoRef.current) {
      videoRef.current.pause();
    }
  }

  return (
    <video id="video" ref={videoRef} className='w-full' />
  );
}