import * as userApi from '../api/user.api';
import { store } from '../store/index';

export const fetchAllUserProfile = async (tmlCd = '') => {
  try {
    const userList = await userApi.getAllUserProfile(tmlCd);
    store.userStore.setUserList(userList);
    return userList;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const updateUserProfile = async (parmas = {}) => {
  try {
    return await userApi.updateUserProfile(parmas);
  } catch (error) {
    console.error(error.response);
    return false;
  }
};

export const saveUserTerminalVessel = async (parmas = {}) => {
  try {
    await userApi.saveUserTerminalVessel(parmas);
    return true;
  } catch (error) {
    console.error(error.response);
    return false;
  }
};

export const fetchUserInfo = async idUser => {
  try {
    return await userApi.fetchUserInfo(idUser);
  } catch (error) {
    console.error(error);
    return null;
  }
};
