import {
  BlockTitle,
  Card,
  f7,
  Fab,
  Link,
  List,
  ListInput,
  Navbar,
  NavLeft,
  NavTitle,
  Page,
} from 'framework7-react';
import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';
import * as VesselSoundingService from '../../services/vessel-sounding.service';
import { StoreContext } from '../../store';
import {
  alertErrorMess,
  hideLoading,
  openNotification,
  showLoading,
} from '../../utils/common';

const VesselSoundingDraft = () => {
  const { vesselMainpageStore, vesselSoundingStore, profileStore } = useContext(
    StoreContext,
  );

  useEffect(async () => {
    resetForm();
    await initData();
  }, []);

  const resetForm = () => {
    const vesselDraft = {
      fwrdDepDrftHgt: '',
      aftDepDrftHgt: '',
      fwrdArrDrftHgt: '',
      aftArrDrftHgt: '',
      depTrim: '',
      arrTrim: '',
    };

    vesselSoundingStore.setVesselDraft(vesselDraft);
  };

  const initData = async () => {
    showLoading();
    const { vslVoyId } = vesselMainpageStore.getSelectedVoyage;
    const vesselDraft = await VesselSoundingService.getVesselDraft({
      idTerminal: profileStore.idTerminal,
      vslVoyId,
    });

    if (vesselDraft) {
      vesselSoundingStore.setVesselDraft(vesselDraft);
    }

    hideLoading();
  };

  const onChangeDepInfo = dataChanged => {
    const vesselDraft = vesselSoundingStore.getVesselDraft;
    const vesselDraftChanged = {
      ...vesselDraft,
      ...dataChanged,
    };

    const { fwrdDepDrftHgt, aftDepDrftHgt } = vesselDraftChanged;

    if (!fwrdDepDrftHgt || !aftDepDrftHgt) {
      dataChanged.depTrim = '';
    } else {
      dataChanged.depTrim = Number(
        Number(fwrdDepDrftHgt - aftDepDrftHgt).toFixed(3),
      );
    }

    vesselSoundingStore.setVesselDraftField(dataChanged);
  };

  const onChangeArrInfo = dataChanged => {
    const vesselDraft = vesselSoundingStore.getVesselDraft;
    const vesselDraftChanged = {
      ...vesselDraft,
      ...dataChanged,
    };

    const { fwrdArrDrftHgt, aftArrDrftHgt } = vesselDraftChanged;

    if (!fwrdArrDrftHgt || !aftArrDrftHgt) {
      dataChanged.arrTrim = '';
    } else {
      dataChanged.arrTrim = Number(
        Number(fwrdArrDrftHgt - aftArrDrftHgt).toFixed(3),
      );
    }

    vesselSoundingStore.setVesselDraftField(dataChanged);
  };

  const isIntegerOrFloat = numVal => {
    if (typeof numVal == 'number') {
      return true;
    }

    if (
      numVal.indexOf('.') === 0 ||
      numVal.indexOf('.') === numVal.length - 1
    ) {
      return false;
    }

    if (isNaN(numVal)) {
      return false;
    }

    return true;
  };

  const isValidateFields = () => {
    const {
      fwrdDepDrftHgt,
      aftDepDrftHgt,
      fwrdArrDrftHgt,
      aftArrDrftHgt,
    } = vesselSoundingStore.getVesselDraft;

    if (fwrdDepDrftHgt && !isIntegerOrFloat(fwrdDepDrftHgt)) {
      return false;
    }

    if (aftDepDrftHgt && !isIntegerOrFloat(aftDepDrftHgt)) {
      return false;
    }

    if (fwrdArrDrftHgt && !isIntegerOrFloat(fwrdArrDrftHgt)) {
      return false;
    }

    if (aftArrDrftHgt && !isIntegerOrFloat(aftArrDrftHgt)) {
      return false;
    }

    return true;
  };

  const onSaveVesselDraft = () => {
    if (!isValidateFields()) {
      return;
    }

    f7.dialog.confirm('Do you want to save Voyage Draft?', '', async () => {
      let response;
      const vesselDraft = vesselSoundingStore.getVesselDraft;

      try {
        showLoading();
        response = await VesselSoundingService.updateVesselDraft(
          profileStore.idTerminal,
          vesselDraft,
        );
        hideLoading();
      } catch (error) {
        console.trace(error);
        alertErrorMess();
      }

      if (response && response.success) {
        openNotification(
          'Voyage Draft',
          'Voyage Draft update has been completed.',
        );
        f7.view.main.router.back();
      } else {
        alertErrorMess('Failed to update Voyage Draft. Please try again.');
      }
    });
  };

  const titleCss = {
    marginTop: '20px',
    marginBottom: '0px',
  };

  const {
    fwrdDepDrftHgt,
    aftDepDrftHgt,
    fwrdArrDrftHgt,
    aftArrDrftHgt,
    depTrim,
    arrTrim,
  } = vesselSoundingStore.getVesselDraft;

  return (
    <Page>
      <Navbar>
        <NavLeft>
          <Link icon="icon-back" back />
        </NavLeft>
        <NavTitle style={{ lineHeight: '26px' }}>Voyaye Draft</NavTitle>
      </Navbar>
      <Card style={{ margin: 0, height: 'calc(100% - 20px)' }}>
        <BlockTitle style={titleCss}>Departure</BlockTitle>
        <List noHairlinesMd>
          <ListInput
            type="text"
            outline
            floatingLabel
            label="Forward"
            pattern="[0-9]+([\.][0-9]+)?"
            validate
            errorMessage="Please enter number only."
            onChange={e => {
              onChangeDepInfo({
                fwrdDepDrftHgt: e.currentTarget.value,
              });
            }}
            value={fwrdDepDrftHgt || ''}
          />
          <ListInput
            type="text"
            outline
            floatingLabel
            label="After"
            pattern="[0-9]+([\.][0-9]+)?"
            validate
            errorMessage="Please enter number only."
            onChange={e => {
              onChangeDepInfo({
                aftDepDrftHgt: e.currentTarget.value,
              });
            }}
            value={aftDepDrftHgt || ''}
          />
          <ListInput
            type="text"
            outline
            floatingLabel
            label="Trim"
            disabled
            value={depTrim || ''}
          />
        </List>
        <BlockTitle style={titleCss}>Arrival</BlockTitle>
        <List noHairlinesMd>
          <ListInput
            type="text"
            outline
            floatingLabel
            label="Forward"
            pattern="[0-9]+([\.][0-9]+)?"
            validate
            errorMessage="Please enter number only."
            onChange={e => {
              onChangeArrInfo({
                fwrdArrDrftHgt: e.currentTarget.value,
              });
            }}
            value={fwrdArrDrftHgt || ''}
          />
          <ListInput
            type="text"
            outline
            floatingLabel
            label="After"
            pattern="[0-9]+([\.][0-9]+)?"
            validate
            errorMessage="Please enter number only."
            onChange={e => {
              onChangeArrInfo({
                aftArrDrftHgt: e.currentTarget.value,
              });
            }}
            value={aftArrDrftHgt || ''}
          />
          <ListInput
            type="text"
            outline
            floatingLabel
            label="Trim"
            disabled
            value={arrTrim || ''}
          />
        </List>
      </Card>
      <Fab
        position="center-bottom"
        slot="fixed"
        text="Save"
        color="blue"
        onClick={onSaveVesselDraft}
      ></Fab>
    </Page>
  );
};

export default observer(VesselSoundingDraft);
