import axios from './axios';
import queryString from 'query-string'

export const getSoundingMeasures = async (idTerminal, vslVoyId, vslLocCd, cmdtCd) => {
  const queStr = queryString.stringify({ vslLocCd, cmdtCd });
  const result = await axios.get(
    `vessel-sounding/measures/${idTerminal}/${vslVoyId}?${queStr}`,
  );
  return result.data;
}

export const getVesselCompartments = async (idTerminal, vslVoyId) => {
  const result = await axios.get(
    `vessel-sounding/bulk-vessel/hatch-manager/${idTerminal}/${vslVoyId}/compartments`,
  );
  return result.data;
}

export const getSoundingDetails = async (idTerminal, vslVoyId, chkSeq) => {
  const result = await axios.get(
    `vessel-sounding/vessel-sounding-details/${idTerminal}/${vslVoyId}/${chkSeq}`,
  );
  return result.data;
}

export const getSoundingValues = async (idTerminal, cmdtCd) => {
  const result = await axios.get(
    `vessel-sounding/commodity/${idTerminal}/${cmdtCd}/sounding-parameters`,
  );
  return result.data;
}

export const updateSoundingDetails = async (params) => {
  const { idTerminal, soundingDetails } = params;
  const result = await axios.put(
    `vessel-sounding/${idTerminal}/vessel-sounding-details`,
    soundingDetails
  );
  return result.data;
}

export const deleteSoundingDetails = async (params) => {
  const { idTerminal, soundingDetails } = params;
  const queStr = queryString.stringify(soundingDetails.vesselSounding);
  const result = await axios.delete(
    `vessel-sounding/${idTerminal}/vessel-sounding-details?${queStr}`,
  );
  return result.data;
}

export const getVesselDraft = async (params) => {
  const { idTerminal, vslVoyId } = params;
  const result = await axios.get(
    `vessel-sounding/${idTerminal}/${vslVoyId}/vessel-voyage-draft`
  );
  return result.data;
}

export const updateVesselDraft = async (terminal, params) => {
  const { vslVoyId } = params;
  const result = await axios.put(
    `vessel-sounding/${terminal}/${vslVoyId}/vessel-voyage-draft`,
    params
  );
  return result.data;
}
