import { MENU_LIST } from '../const';
import * as menuTerminalRoleService from '../api/menu-terminal-role';
export const getMenuByRole = async role => {
  if (!role) {
    return [];
  }

  return MENU_LIST.filter(item => {
    if (role.includes('admin') && item.roles.indexOf('admin') >= 0) {
      return item;
    } else if (role.includes('external')) {
      if (item.roles.indexOf('external') >= 0) {
        return item;
      }
    } else if (
      ((role.includes('driver') || role.includes('truckcompany')) &&
        item.name === 'Delivery Cargo') ||
      ((role.includes('shippingline') || role.includes('terminal')) &&
        item.name === 'Vessel Voyage') ||
      role.includes('customer') ||
      (role.includes('shippingline') && item.name === 'Container Details')
    ) {
      return item;
    }
  });
};

export const getMenuByTerminalRole = async (idTerminal) => {
  const menus = await menuTerminalRoleService.getUserMenuTerminalRole(idTerminal);
  return menus.map(item => {
    const { menuId, path, icon, name } = item.menu;
    return { menuId, path, icon, name };
  });
};
