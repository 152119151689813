import {
  Block,
  Button,
  Col,
  Link,
  Navbar,
  NavLeft,
  NavRight,
  NavTitle,
  Page,
  Row,
} from 'framework7-react';
import React, { useContext } from 'react';
import { StoreContext } from '../../store';

const WelcomePage = props => {
  const { profileStore } = useContext(StoreContext);

  const redirectLogin = () => {
    props.$f7router.navigate('/', { clearPreviousHistory: true });
  };

  return (
    <Page>
      <Navbar>
        <NavLeft />
        <NavTitle>New member</NavTitle>
        <NavRight>
          <Link icon="icon-bars"></Link>
        </NavRight>
      </Navbar>

      <Block>
        <img
          className="logo-system"
          src="../static/img/logo-opus.svg"
          style={{ width: '260px' }}
          alt="logo-opus"
        ></img>
      </Block>

      <Block>
        <Row>
          <Col>
            <strong>Hello {profileStore.name}!</strong>
            <p>Please check your e-mail and confirm the link.</p>
          </Col>
        </Row>
      </Block>

      <Block
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Button fill round style={{ width: '200px' }} onClick={redirectLogin}>
          Login
        </Button>
      </Block>
    </Page>
  );
};

export default WelcomePage;
