import { store } from '../store/index';

export const setRoleInfo = async roleInfo => {
  if (!roleInfo) {
    return;
  }

  store.profileStore.setRole(roleInfo.role);
  store.profileStore.setTerminal(roleInfo.tmlCd);
  store.profileStore.setCompany(roleInfo.idCompany);
};

export const setRole = role => {
  store.profileStore.setRole(role);
  localStorage.setItem('role', role);
};

export const setTerminal = tmlCd => {
  store.profileStore.setTerminal(tmlCd);
  // localStorage.setItem('terminal', tmlCd);
};

export const setIdTerminal = (idTerminal) => {
  if(!idTerminal) {
    return;
  }
  idTerminal = parseInt(idTerminal);
  store.profileStore.setIdTerminal(idTerminal);
  localStorage.setItem('idTerminal', idTerminal);
};


export const clearStore = () => {
  store.profileStore.clearStore();
};
