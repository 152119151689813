import * as userTerminalVesselApi from '../api/user-terminal-vessel.api';

export const saveUserTerminalVessel = async params => {
  try {
    await userTerminalVesselApi.saveUserTerminalVessel(params);
    return true;
  } catch (ex) {
    console.error(ex.response);
    return false;
  }
};
export const getAllTerminalAccessVessels = async (idTerminal) => {
  try {
    const { data } = await userTerminalVesselApi.getAllTerminalAccessVessels(
      idTerminal,
    );
    return data;
  } catch (ex) {
    console.error(ex.response);
    return [];
  }
};

export const deleteTerminalVesselMenu = async params => {
  try {
    await userTerminalVesselApi.deleteTerminalVesselMenu(params);
    return true;
  } catch (ex) {
    console.error(ex.response);
    return false;
  }
};
