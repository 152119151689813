import axios from './axios';

export const getCargoList = async (params) => {
  const result = await axios.get(
    `cargos/${params.idTerminal}?q=${params.utRepNo}&oprCd=${params.oprCd}&cneeCd=${params.cneeCd}`,
  );
  return result.data;
};

export const getCargoDetail = async (params) => {
  const result = await axios.get(
    `cargos/${params.idTerminal}/${params.tmlUtId}`,
  );
  return result.data;
};

export const getCustomerByIdCompany = async (idCompany) => {
  const result = await axios.get(`companies/${idCompany}/customers`);
  return result.data;
};

export const getShippingLineByIdCompany = async idCompany => {
  const result = await axios.get(`companies/${idCompany}/shippinglines`);
  return result.data;
};