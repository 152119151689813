import React, {
  Fragment,
  useEffect,
  useState,
  useContext,
  useMemo,
} from 'react';
import {
  Link,
  Navbar,
  NavLeft,
  NavTitle,
  Page,
  List,
  ListInput,
  BlockTitle,
  Icon,
  ListItem,
  Card,
  Block,
  Row,
  Col,
  Button,
  NavRight,
} from 'framework7-react';
import { StoreContext } from '../../store';
import * as terminalService from '../../services/terminal-service';
import * as roleService from '../../services/role.service';
import * as roleTerminalService from '../../services/role-terminal.service';

const RoleTerminalSetting = props => {
  const { commonStore, profileStore } = useContext(StoreContext);
  const [terminalList, setTerminalList] = useState([]);
  const [role, setRole] = useState('');
  const [roleList, setRoleList] = useState([]);
  const [roleTerminals, setRoleTerminals] = useState([]);

  useEffect(async () => {
    initialize();
  }, []);

  const initialize = async () => {
    //Get terminal list
    let terminalList = commonStore.getTerminalList;
    if (!terminalList || !terminalList.length) {
      terminalList = await terminalService.getTerminals();
      commonStore.setTerminalList(terminalList);
    }
    setTerminalList(terminalList || []);

    //Get role list
    let roles = commonStore.getRoleList;
    if (!roles || !roles.length) {
      roles = await roleService.getRoleList();
      commonStore.setRoleList(roles);
    }
    setRoleList(roles || []);

    //Set defautl terminal and role
    setRole(roles ? roles[0]?.roleId : '');
  };

  useMemo(async () => {
    //Get Role terminal List
    if (!role) return;
    const result = await roleTerminalService.getRoleTerminals(role);
    // const tmlCds = result.map(item => item.tmlCd);
    const idTerminals = result.map(item => item.idTerminal);
    setRoleTerminals(idTerminals);
  }, [role]);

  const onChangeRole = async e => {
    const value = e.target.value;
    setRole(value);
  };

  const onSaveClick = () => {
    props.$f7router.app.dialog.confirm('Do you want to save', 'Confirm', () => {
      onSaveProcess();
    });
  };

  const onSaveProcess = async () => {
    const params = { idTerminals: roleTerminals, roleId: role };
    const result = await roleTerminalService.saveRoleTerminals(params);
    if (result) {
      showAlert('Info', 'Save successfully!');
    } else {
      showAlert('Warning', 'Failed! Please try again.');
    }
  };

  const onBtnSettingClick = () => {
    props.f7router.navigate('role-setting');
  };
  const isCheckedTerminal = idTerminal => {
    return (
      roleTerminals.findIndex(
        element => parseInt(element) === parseInt(idTerminal),
      ) >= 0
    );
  };

  const onSelectTerminal = e => {
    const value = e.target.value;
    let currentData = roleTerminals;
    if (e.target.checked) {
      currentData.push(value);
    } else {
      currentData = currentData.filter(item => item != value);
    }
    setRoleTerminals([...currentData]);
  };

  const showAlert = (title, content) => {
    props.$f7router.app.dialog.alert(content, title, () => {});
  };

  return (
    <Page>
      <Navbar>
        <NavLeft>
          <Link icon="icon-back" back />
        </NavLeft>
        <NavTitle>Role - Terminal setting</NavTitle>
        <NavRight>
          <Link
            color="black"
            iconSize={25}
            iconMaterial="home"
            onClick={onBtnSettingClick}
          ></Link>
        </NavRight>
      </Navbar>
      <Card style={{ margin: 0, minHeight: '100%' }}>
        <Block style={{ paddingTop: 10, paddingBottom: 10 }}>
          <List>
            <BlockTitle className="strong">Role</BlockTitle>
            <ListInput
              type="select"
              validate
              value={role}
              onChange={e => {
                onChangeRole(e);
              }}
            >
              {roleList &&
                roleList.map((item, index) => (
                  <option key={index} value={item.roleId}>
                    {item.name}
                  </option>
                ))}
            </ListInput>
          </List>

          <Fragment>
            <Row>
              <List>
                <BlockTitle className="strong">Terminal</BlockTitle>
                {(terminalList || []).map((item, index) => (
                  <ListItem
                    key={index}
                    checkbox
                    value={item.idTerminal}
                    title={item.name}
                    checked={isCheckedTerminal(item.idTerminal)}
                    onChange={e => {
                      onSelectTerminal(e);
                    }}
                  >
                    <Icon slot="media" material={item.icon}></Icon>
                  </ListItem>
                ))}
              </List>
            </Row>
            <Row>
              <Col>
                <Button
                  id="saveBtn"
                  className="button button-fill button-round"
                  style={{
                    width: '160px',
                    margin: 'auto',
                  }}
                  onClick={onSaveClick}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Fragment>
        </Block>
      </Card>
    </Page>
  );
};

export default RoleTerminalSetting;
{
  /* <ListItem key={index} title={item.name} link={item.path}>
                <Icon slot="media" material={item.icon}></Icon>
              </ListItem> */
}
