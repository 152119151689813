import axios from './axios';

export const getRoleTerminals = async roleId => {
  const { data } = await axios.get(`role-terminal/${roleId}`);
  return data;
};

export const saveRoleTerminals = async params => {
  await axios.post(`role-terminal`, params);
};
