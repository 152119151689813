import {
  BlockTitle,
  Link,
  List,
  ListItem,
  Navbar,
  NavLeft,
  NavRight,
  NavTitle,
  Page,
  Searchbar,
} from 'framework7-react';
import React, { useContext, useEffect, useState } from 'react';
import * as cargoService from '../../services/cargo-detail.service';
import { StoreContext } from '../../store';

const CargoList = props => {
  const { profileStore } = useContext(StoreContext);
  const [cargoList, setCargoList] = useState([]);
  const [formCargo, setFormCargo] = useState({
    utRepNo: '',
    oprCd: '',
    cneeCd: '',
  });

  useEffect(async () => {
    let role = profileStore.role || '';
    let idCompany = profileStore.company || '';
    if (role === 'shippingline') {
      await getOperation(idCompany);
    } else if (role === 'customer') {
      await getConsignee(idCompany);
    }
  }, []);

  const getOperation = async idCompany => {
    const shippingLine = await cargoService.getShippingLineByIdCompany(
      idCompany,
    );

    if (!shippingLine) {
      return;
    }

    formCargo.oprCd = shippingLine.otmShippingLineCode;
    setFormCargo(formCargo);
  };

  const getConsignee = async idCompany => {
    const customer = await cargoService.getCustomerByIdCompany(idCompany);

    if (!customer) {
      return;
    }

    formCargo.cneeCd = customer.otmCustomerCode;
    setFormCargo(formCargo);
  };

  const cargoOnSubmit = async event => {
    if (!formCargo.utRepNo) {
      return;
    }

    const lstCargo = await getCargoList(formCargo);
    setCargoList(lstCargo);
  };

  const onSearchbarSearch = async (searchbar, query) => {
    const utRepNo = query;
    formCargo.utRepNo = utRepNo;
    setFormCargo(formCargo);
  };

  const getCargoList = async formCargo => {
    const params = {
      utRepNo: formCargo.utRepNo,
      idTerminal: profileStore.idTerminal,
      oprCd: formCargo.oprCd,
      cneeCd: formCargo.cneeCd,
    };

    props.$f7router.app.preloader.show();
    const lstCargo = await cargoService.getCargoList(params);
    props.$f7router.app.preloader.hide();
    return lstCargo;
  };

  return (
    <Page>
      <Navbar>
        <NavLeft backLink />
        <NavTitle style={{ overflow: 'unset' }}>Cargo Search</NavTitle>
        {/* <NavRight>
          <Link color="black" iconMaterial="apps"></Link>
        </NavRight> */}
      </Navbar>
      <Searchbar
        placeholder="Cargo No"
        customSearch={true}
        onSubmit={cargoOnSubmit}
        onSearchbarSearch={onSearchbarSearch}
      ></Searchbar>
      <BlockTitle style={{ marginTop: 'unset', marginBottom: 'unset' }}>
        <p>Cargo List</p>
      </BlockTitle>
      {CargoListComp(cargoList)}
    </Page>
  );
};

const CargoListComp = props => {
  return (
    <List mediaList>
      {props &&
        props.map((item, index) => {
          return (
            <ListItem
              key={index}
              title={item.utRepNo}
              subtitle={item.oprCd}
              link={`/cargo/details/${item.tmlCd}/${item.tmlUtId}`}
            />
          );
        })}
    </List>
  );
};

export default CargoList;
