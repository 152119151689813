import * as terminalApi from '../api/terminal.api';
export const getTerminals = async () => {
  try {
    const lstTerminals = await terminalApi.getTerminal();
    return lstTerminals;
  } catch (error) {
    console.error(error.response);
    return [];
  }
};

export const getTerminalById = async (idTerminal) => {
  const terminals = await getTerminals();
  return terminals.find((tml) => tml.idTerminal = idTerminal);
}

export const getTerminalsRoles = async () => {
  try {
    return await terminalApi.getTerminalsRoles();
  } catch (error) {
    console.error(error.response);
    return [];
  }
};
