import axios from './axios';

export const register = async (userInfo) => {
   await axios.post(`accounts/register`, userInfo);
};

export const getCountryCode = async () => {
  const lstCountryCode = await axios.get('countries/');
  return lstCountryCode.data;
};

export const getUserByUsername = async (username) => {
  const response = await axios.get(`accounts/username-exists?username=${username}`);
  return response.data;
};

export const getPersonByEmail = async (email) => {
  const response = await axios.get(`accounts/email-exists?email=${email}`);
  return response.data;
}