import {
  Link,
  Navbar,
  NavLeft,
  NavTitle,
  Page,
  Card,
  CardContent,
  Searchbar,
  AccordionContent,
  ListItem,
  List,
  Block,
  BlockHeader,
  BlockFooter,
  ListInput,
  Button,
  Col,
  Row,
  Icon,
  BlockTitle,
  Checkbox,
} from 'framework7-react';
import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import MultiSelect from '../../components/multiselect/MultiSelect';
import { StoreContext } from '../../store';
import * as userService from '../../services/user.service';
import * as roleService from '../../services/role.service';
import * as terminalService from '../../services/terminal-service';
import * as menuService from '../../services/menu.service';
import * as vesselCommonService from '../../services/vessel-common.service';
import * as userTerminalVesselService from '../../services/user-terminal-vessel.service';
import { f7 } from 'framework7-react';

const VesselAccessForm = props => {
  const {
    userStore,
    profileStore,
    commonStore,
    vesselAccessManagementStore,
  } = useContext(StoreContext);
  const [menuResourceList, setMenuResourceList] = useState([]);
  const [filterCondition, setFilterCondition] = useState({
    idTerminal: '',
    roleId: '',
  });
  const [sysAdm, setSysAdm] = useState('Y');
  const [terminalList, setTerrminalList] = useState([]);
  const [roleList, setRoleList] = useState([{ name: 'ALL', value: '' }]);
  const [userList, setUserList] = useState([]);
  const [vesselList, setVesselList] = useState([]);
  const [userSelectedList, setUserSelectedList] = useState([]);
  const [vesselSelectedLst, setVesselSelectedLst] = useState([]);
  const [menuSelectedLst, setMenuSelectedLst] = useState([]);
  const [isUpdateData, setIsUpdateData] = useState(false);
  useEffect(async () => {
    await initialize();
  }, []);

  const initialize = async () => {
    showLoading();
    initDataVesselAccess();
    await initDataCommon();
    hideLoading();
  };

  const initDataCommon = async () => {
    const {
      userIdLst,
      vvdList,
      menuIdList,
      idTerminal,
    } = vesselAccessManagementStore.getFormValues;
    const { sysAdm } = profileStore;

    const idTerminalFilter = idTerminal || profileStore.idTerminal;
    if (idTerminal && userIdLst && userIdLst.length) {
      setIsUpdateData(true);
    }

    setSysAdm(sysAdm || 'N');
    setFilterCondition({ ...filterCondition, idTerminal: idTerminalFilter });
    await loadUserList(idTerminalFilter);
    await loadTerrminal();
    await initRoleList();
    await loadVesselList(idTerminalFilter);
    setUserSelectedList(userIdLst);
    setVesselSelectedLst(vvdList);
    setMenuSelectedLst(menuIdList);
  };

  const initDataVesselAccess = () => {};

  const loadUserList = async idTerminal => {
    await userService.fetchAllUserProfile(idTerminal);
    setUserList(userStore.getUserList);
  };

  const initRoleList = async () => {
    //Get role list
    let roles = commonStore.getRoleList;
    if (!roles || !roles.length) {
      roles = await roleService.getRoleList();
      commonStore.setRoleList(roles);
    }
    setRoleList([{ roleId: '', name: 'ALL' }].concat(roles || []));
  };

  const loadMenuResourceData = async () => {
    const { roleId, idTerminal } = filterCondition;
    let menuLst = [];
    if (!idTerminal) {
      setMenuResourceList(menuLst);
    }
    if (roleId) {
      const result = await menuService.getMenuTerminalRole(idTerminal, roleId);
      menuLst = result.map(item => {
        return { name: item.menu?.name, menuId: item.menuId };
      });
    } else {
      const result = await menuService.getMenuResource();
      menuLst = result.map(item => {
        return { name: item.name, menuId: item.menuId };
      });
    }

    setMenuResourceList(menuLst);
  };

  const loadTerrminal = async () => {
    let terminals = commonStore.getTerminalList;
    if (!terminals || !terminals.length) {
      terminals = await terminalService.getTerminals();
      commonStore.setTerminalList(terminals);
    }

    const tmlLst = [].concat(
      terminals.map(item => {
        return { name: `${item.tmlCd} - ${item.name}`, value: item.idTerminal };
      }),
    );
    setTerrminalList(tmlLst);
  };

  const loadVesselList = async idTerminal => {
    let vslList = [];
    if (idTerminal) {
      vslList = await vesselCommonService.getVvdPlannedList(idTerminal);
    }
    vslList = vslList.map(item => {
      const { vslNm, vslVoyId } = item;
      return { vslNm: `${vslVoyId} ${vslNm}`, vslVoyId };
    });
    vslList.sort((a, b) => {
      return a.vslVoyId.localeCompare(b.vslVoyId);
    });
    setVesselList(vslList);
  };

  useMemo(async () => {
    setUserSelectedList([]);
    setVesselSelectedLst([]);
    await loadMenuResourceData();
    await filterUserList();
  }, [filterCondition]);

  const filterUserList = async () => {
    const { idTerminal, roleId } = filterCondition;
    const results = (userStore.getUserList || []).filter(item =>
      matchFilterOptions(item, idTerminal, roleId),
    );
    setUserList(results);
  };

  const matchFilterOptions = (item, idTerminal, roleId) => {
    const isMatchFilterOptions = (item.userTerminalRoles || []).find(
      tmlRole => {
        return (
          idTerminal &&
          tmlRole.idTerminal == idTerminal &&
          checkRole(tmlRole, roleId)
        );
      },
    );
    return isMatchFilterOptions;
  };

  const checkRole = (tmlRole, roleId) => {
    return !roleId || (tmlRole.roles || []).split(', ').includes(roleId);
  };

  const showLoading = () => {
    f7.dialog.progress('Loading...');
  };

  const hideLoading = () => {
    f7.dialog.close();
  };

  const showAlert = (title, content) => {
    f7.dialog.alert(content, title, () => {});
  };

  const onChangeTerminalFilter = async e => {
    showLoading();
    const val = e.target.value;
    setFilterCondition({ ...filterCondition, idTerminal: val });
    await loadVesselList(val);
    hideLoading();
  };

  const onChangeRoleFilter = e => {
    const val = e.target.value;
    setFilterCondition({ ...filterCondition, roleId: val });
  };

  const isSelected = idUser => {
    return (userSelectedList || []).includes(idUser);
  };

  const onUserCheckboxChange = idUser => {
    setUserSelectedList(prevState => {
      return prevState.includes(idUser)
        ? prevState.filter(userId => userId !== idUser)
        : [...prevState, idUser];
    });
  };

  const onSaveClick = async () => {
    if (!onValidate()) {
      return;
    }
    props.$f7router.app.dialog.confirm(
      'Do you want to save ?',
      'Confirm',
      () => {
        onSaveProcess();
        vesselAccessManagementStore.setFetchData();
      },
    );
  };

  const onSaveProcess = async () => {
    showLoading();
    const params = {
      userList: userSelectedList,
      vesselList: vesselSelectedLst,
      menuList: menuSelectedLst,
      idTerminal: filterCondition.idTerminal,
    };

    if (isUpdateData) {
      const {
        userIdLst,
        menuIdList,
        idTerminal,
      } = vesselAccessManagementStore.getFormValues;
      params.currentData = {
        idTerminal,
        userList: userIdLst,
        menuList: menuIdList,
      };
    }

    const result = await userTerminalVesselService.saveUserTerminalVessel(
      params,
    );
    hideLoading();
    if (result) {
      showAlert('Info', 'Save successfully!');
    } else {
      showAlert('Warning', 'Failed! Please try again.');
    }
  };

  const onValidate = () => {
    if (!menuSelectedLst || !menuSelectedLst.length) {
      showAlert('Warning', 'Please select the menu(s)');
      return false;
    }

    if (!userSelectedList || !userSelectedList.length) {
      showAlert('Warning', 'Please select the user(s)');
      return false;
    }
    return true;
  };

  const renderUserList = () => {
    return userList.map((item, index) => {
      const { name, email } = item.person;
      return (
        <Fragment key={index}>
          <CardContent key={index} style={{ position: 'relative' }}>
            <div>
              <Row>
                <Col width={50}>
                  <Block>{name}</Block>
                </Col>
              </Row>
              <Row>
                <Col width={100}>
                  <Block>{email}</Block>
                </Col>
              </Row>
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  right: '15px',
                  transform: 'translate(0px, -50%)',
                  zIndex: '100',
                }}
              >
                {/* <Icon size="22" color="blue" material="chevron_right"></Icon> */}
                <Checkbox
                  checked={isSelected(item.idUser)}
                  onChange={() => onUserCheckboxChange(item.idUser)}
                ></Checkbox>
              </div>
            </div>
          </CardContent>
          <div style={{ height: '1px', background: '#cccccc' }}></div>
        </Fragment>
      );
    });
  };

  const styleCSS = `
    .advance-filter-area {
      margin: 0px 0px 10px 0px !important
    }
    .required {
      color:red;
    }
  `;
  return (
    <Page>
      <style>{styleCSS}</style>
      <Navbar>
        <NavLeft>
          <Link icon="icon-back" back />
        </NavLeft>
        <NavTitle>{isUpdateData ? 'Update' : 'New'} Vessel Access</NavTitle>
      </Navbar>
      {/* <Searchbar
          placeholder={placeholderSearch}
          customSearch={true}
          onSubmit={() => {}}
          onSearchbarSearch={(searchbar, query) => {
            setFilterCondition({ ...filterCondition, keyword: query });
          }}
        /> */}
      <List className="advance-filter-area">
        <ListItem
          accordionItem
          title="Data Assign"
          className="accordion-item-opened"
        >
          <AccordionContent>
            <Block>
              {sysAdm && sysAdm === 'Y' && (
                <List>
                  <ListInput
                    label={
                      <div>
                        Terminal <span className="required">*</span>
                      </div>
                    }
                    type="select"
                    validate
                    value={filterCondition.idTerminal}
                    onChange={e => {
                      onChangeTerminalFilter(e);
                    }}
                  >
                    {terminalList.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </ListInput>
                </List>
              )}
              <ListInput
                label={<div>Role</div>}
                type="select"
                validate
                value={filterCondition.roleId}
                onChange={e => {
                  onChangeRoleFilter(e);
                }}
              >
                {roleList.map((item, index) => (
                  <option key={index} value={item.roleId}>
                    {item.name}
                  </option>
                ))}
              </ListInput>

              <Block>
                <div>
                  Menu <span className="required">*</span>
                </div>
                <MultiSelect
                  collection={menuResourceList}
                  onChange={vals => {
                    setMenuSelectedLst(vals);
                  }}
                  defaultDisabledOption="Select menu"
                  values={menuSelectedLst}
                  mappingPropsValue="name"
                  mappingPropsKey="menuId"
                ></MultiSelect>
              </Block>
              <Block>
                <div>Vessel Code</div>
                <MultiSelect
                  collection={vesselList}
                  onChange={vals => {
                    setVesselSelectedLst(vals);
                  }}
                  values={vesselSelectedLst}
                  defaultDisabledOption="Select vessel voyage"
                  mappingPropsValue="vslNm"
                  mappingPropsKey="vslVoyId"
                ></MultiSelect>
              </Block>

              <Row style={{ paddingBottom: '10px' }}>
                <Col>
                  <Button
                    id="saveBtn"
                    className="button button-fill button-round"
                    style={{
                      width: '160px',
                      margin: 'auto',
                    }}
                    onClick={onSaveClick}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Block>
          </AccordionContent>
        </ListItem>
      </List>
      <Card style={{ margin: 0 }}>
        {userList && userList.length ? (
          renderUserList()
        ) : (
          <p style={{ padding: '10px', textAlign: 'center' }}>
            No matching users
          </p>
        )}
      </Card>
    </Page>
  );
};
export default VesselAccessForm;
