import * as cargoApi from '../api/cargo-detail.api';

export const getCargoList = async params => {
  try {
    const data = await cargoApi.getCargoList(params);
    return data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getCargoDetail = async params => {
  try {
    const data = await cargoApi.getCargoDetail(params);

    if(!data) {
      return {};
    }

    data.lstDamage.forEach(el => {
      el.sumaryDmgTitle = `${el.dmgStsNm ? el.dmgStsNm : ''}${
        el.dmgAreaGrpNm ? ' - ' + el.dmgAreaGrpNm : ''
      }${el.dmgTpNm ? ' - ' + el.dmgTpNm : ''}`;

      const sumaryStrLst = [];

      if(el.dmgAreaNm) {
        sumaryStrLst.push(el.dmgAreaNm);
      }

      if(el.dmgGrdLocNm) {
        sumaryStrLst.push(el.dmgGrdLocNm);
      }

      if(el.dmgSvrtNm) {
        sumaryStrLst.push(el.dmgSvrtNm);
      }
      el.sumaryDmg = sumaryStrLst.join(' - ');
    });

    return data;
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const getCustomerByIdCompany = async idCompany => {
  try {
    const data = await cargoApi.getCustomerByIdCompany(idCompany);
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getShippingLineByIdCompany = async idCompany => {
  try {
    const data = await cargoApi.getShippingLineByIdCompany(idCompany);
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
