import {
  AccordionContent,
  Badge,
  Block,
  Button,
  Icon,
  Link,
  List,
  ListItem,
  ListItemCell,
  ListItemRow,
  Navbar,
  NavLeft,
  NavRight,
  NavTitle,
  Page,
  PhotoBrowser,
} from 'framework7-react';
import React, { useContext, useEffect, useState } from 'react';
import * as cargoService from '../../services/cargo-detail.service';
import { getTerminalHost } from '../../api/common.api';
import { StoreContext } from '../../store';

const CargoDetail = props => {
  const { profileStore } = useContext(StoreContext);
  const { tmlUtId, tmlCd } = props;
  const [details, setDetailsLst] = useState({});
  const [urlHostFile, setUrlHostFile] = useState(0);

  useEffect(async () => {
    props.$f7router.app.preloader.show();
    const params = { idTerminal: profileStore.idTerminal, tmlUtId };
    const cargoDetail = await cargoService.getCargoDetail(params);
    setDetailsLst(cargoDetail);
    const terminalHost = await getTerminalHost(profileStore.idTerminal);
    setUrlHostFile(terminalHost + 'files/download?action=preview&source=');
    props.$f7router.app.preloader.hide();
  }, []);

  return (
    <Page>
      <Navbar>
        <NavLeft backLink />
        <NavTitle style={{ overflow: 'unset' }}>Cargo Detail</NavTitle>
        <NavRight>
          <Link color="black" iconMaterial="apps"></Link>
        </NavRight>
      </Navbar>

      <Button fill large>
        {details.utRepNo || ''}
      </Button>

      <List mediaList style={{ marginTop: 0 }}>
        <ListItem title="Operator" after={`${details.oprCd || ''} `} />
        <ListItemRow>
          <ListItemCell
            style={{
              flex: 5,
              borderRight: '1px solid var(--f7-list-item-border-color)',
            }}
          >
            <ListItem title="Class" after={`${details.clssNm || ''} `} />
          </ListItemCell>
          <ListItemCell style={{ flex: 5, marginLeft: 'unset' }}>
            <ListItem title="Commodity" after={`${details.cmdtNm || ''} `} />
          </ListItemCell>
        </ListItemRow>
        <ListItemRow>
          <ListItemCell
            style={{
              flex: 5,
              borderRight: '1px solid var(--f7-list-item-border-color)',
            }}
          >
            <ListItem
              title="Cargo Status"
              after={`${details.fullMtyDivNm || ''} `}
            />
          </ListItemCell>
          <ListItemCell style={{ flex: 5, marginLeft: 'unset' }}>
            <ListItem title="ISO" after={`${details.isoCntrTpSzCd || ''} `} />
          </ListItemCell>
        </ListItemRow>
        <ListItem title="Gross Weight" after={`${details.grsWgt || ''} kg`} />
        <ListItem title="Tare Weight" after={`${details.tareWgt || ''} kg`} />
        <ListItem
          accordionItem
          title={`Terminal In ${
            details.inTrspTpNm ? '(' + details.inTrspTpNm + ')' : ''
          }`}
        >
          <AccordionContent>
            <Block>
              <ListItem title="Truck" after={`${details.inTrspId || ''} `} />
              <ListItem
                title="Trucking Co"
                after={`${details.truckingCoIn || ''} `}
              />
              <ListItem
                title="Gate-In Date"
                after={`${details.tmlInDt || ''} `}
              />
            </Block>
          </AccordionContent>
        </ListItem>
        <ListItem
          accordionItem
          title={`Terminal Out ${
            details.outTrspTpNm ? '(' + details.outTrspTpNm + ')' : ''
          }`}
        >
          <AccordionContent>
            <Block>
              <ListItem title="Vessel" after={`${details.outTrspId || ''} `} />
              <ListItem
                title="Terminal-Out Date"
                after={`${details.tmlOutDt || ''} `}
              />
            </Block>
          </AccordionContent>
        </ListItem>
        <ListItem title="Seal">
          {details.lstSeal &&
            details.lstSeal.map((item, index) => (
              <Badge
                slot="after"
                color="green"
                style={{ paddingLeft: '15px', paddingRight: '15px' }}
              >
                {item.comCd} / {item.sealNo}
              </Badge>
            ))}
        </ListItem>
        <ListItem accordionItem title="Damages">
          <AccordionContent>
            <Block>
              {details.lstDamage &&
                details.lstDamage.map((item, index) => {
                  const photos = item.dmgPictList.map(el => urlHostFile + el.dmgPictUrl);
                  const cntImg = item.dmgPictList.length;
                  let photoBrowerRef = null;
                  return (
                    <ListItem
                      title={item.sumaryDmgTitle || ''}
                      text={item.sumaryDmg || ''}
                    >
                      <Link
                        slot="after"
                        noFastClick
                        onClick={() => {
                          if (cntImg) {
                            photoBrowerRef.open();
                          }
                        }}
                      >
                        <Icon color="blue" material="photo_library">
                          <Badge color="red">{cntImg || '0'}</Badge>
                        </Icon>
                      </Link>

                      <PhotoBrowser
                        type="popup"
                        photos={photos}
                        ref={element => {
                          photoBrowerRef = element;
                        }}
                      />
                    </ListItem>
                  );
                })}
            </Block>
          </AccordionContent>
        </ListItem>
      </List>
    </Page>
  );
};

export default CargoDetail;
