import axios from './axios';

export const getVslVoySkdStsCdLst = async (idTerminal) => {
  const result = await axios.get(`vessel-common/vessel-voyage-status/${idTerminal}`);
  return result.data;
};

export const getComCodes = async (idTerminal, groupNm) => {
  const result = await axios.get(`common/com-codes/${idTerminal}/${groupNm}`);
  return result.data;
};

export const getVslVoyLst = async (
  idTerminal,
  { selectedBerthingPurposeCodes, selectedVslVoyCds, menuId },
) => {
  const queryString = `?brthPurCds=${selectedBerthingPurposeCodes.join(
    ',',
  )}&vslVoyPrntCds=${selectedVslVoyCds.join(',')}&menuId=${menuId}`;
  const result = await axios.get(
    `vessel-common/vessel-voyage-list/${idTerminal}${queryString}`,
  );
  return result.data;
};

export const getCommodityCodes = async (idTerminal, opTpCd, vslVoyId) => {
  const result = await axios.get(
    `common/commodity/${idTerminal}/${opTpCd}/${vslVoyId}`,
  );
  return result.data;
};

export const getImage = async (tmlCd, path) => {
  const result = await axios.get(
    `common/image/download?tmlCd=${tmlCd}&path=${path}`,
  );
  return result.data;
};

export const getVesselListByVslCdKey = async (tmlCd, vslCdKey) => {
  const result = await axios.get(`vessel-common/${tmlCd}/${vslCdKey}/vessels`);
  return result.data;
};

export const getVessselPlannedList = async tmlCd => {
  const result = await axios.get(`vessel-common/${tmlCd}/vessels-planned`);
  return result.data;
};

export const getVvdPlannedList = async tmlCd => {
  const result = await axios.get(`vessel-common/${tmlCd}/vvds-planned`);
  return result.data;
};
