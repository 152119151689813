import React, {
  Fragment,
  useEffect,
  useState,
  useContext,
  useMemo,
  useRef,
} from 'react';
import {
  Link,
  Navbar,
  NavLeft,
  NavTitle,
  Page,
  List,
  ListInput,
  BlockTitle,
  Icon,
  ListItem,
  Card,
  Block,
  Row,
  Col,
  Button,
  NavRight,
  AccordionContent,
  Badge,
  CardContent,
  ListItemContent,
  ListItemAfter,
  ListGroup,
} from 'framework7-react';
import { StoreContext } from '../../store';
import * as userTerminalVesselService from '../../services/user-terminal-vessel.service';
import { f7 } from 'framework7-react';

const VesselAccessManagement = props => {
  const { commonStore, profileStore, vesselAccessManagementStore } = useContext(
    StoreContext,
  );
  const [vesselAccessList, setVesselAccessList] = useState([]);
  const myPageRef = useRef(null);

  useEffect(async () => {
    initialize();
  }, [vesselAccessManagementStore.fetchData]);

  const initialize = async () => {
    showLoading();
    await loadDataList();
    hideLoading();
  };

  const loadDataList = async () => {
    //Get vessel access list
    const result = await userTerminalVesselService.getAllTerminalAccessVessels(
      profileStore.idTerminal,
    );
    const data = Object.values(groupData(result));
    setVesselAccessList(data);
  };

  const groupData = data => {
    return data.reduce((result, item) => {
      const key = `${item.tmlCd}-${item.menuId}-${item.vslCds}`;
      if (!result[key]) {
        result[key] = {
          idTerminal: item.idTerminal,
          tmlCd: item.tmlCd,
          tmlName: item.terminal.name,
          menuId: item.menuId,
          menuName: item.menu.name,
          vslCds: item.vslCds,
          userList: [],
        };
      }
      result[key].userList.push({
        name: item.user.person.name,
        email: item.user.person.email,
        idUser: item.idUser,
      });
      return result;
    }, {});
  };

  const showAlert = (title, content) => {
    props.$f7router.app.dialog.alert(content, title, () => {});
  };

  const onBtnAddClick = () => {
    vesselAccessManagementStore.clear();
    props.f7router.navigate('vessel-access-form');
  };

  const onEdit = async data => {
    const { menuId, tmlCd, vslCds, userList, idTerminal } = data;
    // props.f7router.navigate('vessel-access-form');
    const userIdLst = userList.map(item => item.idUser);
    const vvdList = vslCds.split(', ');
    const menuIdList = [`${menuId}`];

    vesselAccessManagementStore.setFormValue({
      userIdLst,
      vvdList,
      menuIdList,
      tmlCd,
      idTerminal,
    });
    await props.f7router.navigate('vessel-access-form', {
      // reloadCurrent: true,
    });
  };

  const onDelete = async data => {
    props.$f7router.app.dialog.confirm(
      'Do you want to delete ?',
      'Confirm',
      () => {
        onDeleteProcess(data);
      },
    );
  };

  const onDeleteProcess = async data => {
    const { menuId, tmlCd, userList, idTerminal } = data;
    const menuList = [`${menuId}`];
    const result = await userTerminalVesselService.deleteTerminalVesselMenu({
      idTerminal,
      tmlCd,
      userList: userList.map(item => item.idUser),
      menuList,
    });

    if (result) {
      showAlert('Info', 'Delete successfully!');
      await loadDataList();
    } else {
      showAlert('Warning', 'Failed! Please try again.');
    }
  };

  const showLoading = () => {
    f7.dialog.progress('Loading...');
  };

  const hideLoading = () => {
    f7.dialog.close();
  };

  const renderUserList = userList => {
    return userList.map((item, index) => {
      const { name, email } = item;
      return (
        <div className="item-content" key={index}>
          <div className="item-inner">
            <div className="item-title">
              <div>{name}</div>
              <div>{email}</div>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <Page ref={myPageRef}>
      <Navbar>
        <NavLeft>
          <Link icon="icon-back" back />
        </NavLeft>
        <NavTitle>Vessel Access Management</NavTitle>
        <NavRight>
          <Link
            color="black"
            iconSize={30}
            iconMaterial="add"
            onClick={onBtnAddClick}
          ></Link>
        </NavRight>
      </Navbar>
      <Card style={{ margin: 0, minHeight: '100%' }}>
        {(!vesselAccessList || !vesselAccessList.length) && (
          <p style={{ padding: '10px', textAlign: 'center' }}>No data</p>
        )}
        <div className="list accordion-list">
          <ul>
            {vesselAccessList.map((item, index) => (
              <li className="accordion-item" key={index}>
                <a className="item-content item-link" href="#">
                  <div className="item-inner">
                    <div className="item-title">
                      <div>
                        <div>
                          <b>{item.tmlName}</b>
                        </div>
                        <div>{item.menuName}</div>
                        <div style={{ whiteSpace: 'break-spaces' }}>
                          {item.vslCds}
                        </div>
                      </div>
                    </div>
                    <div slot="after">
                      <div style={{ display: 'flex', gap: '5px' }}>
                        <span>({item.userList.length})</span>
                        <span
                          onClick={event => {
                            event.preventDefault();
                            event.stopPropagation();
                            onEdit(item);
                          }}
                        >
                          <Icon size="20" material="edit" />
                        </span>
                        <span
                          onClick={() => {
                            onDelete(item);
                          }}
                        >
                          <Icon size="20" material="delete" />
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
                <div className="accordion-item-content">
                  <div className="block">
                    <List>
                      <ListGroup>{renderUserList(item.userList)}</ListGroup>
                    </List>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Card>
    </Page>
  );
};

export default VesselAccessManagement;
