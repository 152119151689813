import axios from './axios';

export const saveUserTerminalVessel = async params => {
  await axios.post('user-terminal-vessel/', params);
};

export const getAllTerminalAccessVessels = async (idTerminal) => {
  return await axios.get(`user-terminal-vessel/${idTerminal}`);
};

export const deleteTerminalVesselMenu = async params => {
  await axios.delete(`user-terminal-vessel/`, { data: params });
};
