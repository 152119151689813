import {
  Block,
  Col,
  Link,
  List,
  ListInput,
  ListItemCell,
  ListItemRow,
  Navbar,
  NavLeft,
  NavRight,
  Page,
  Row,
} from 'framework7-react';
import React, { useContext, useEffect, useState } from 'react';
import * as contants from '../../const';
import * as registerService from '../../services/register.service';
import { StoreContext } from '../../store';

const SignUp = props => {
  const store = useContext(StoreContext);

  const [dataConfig, setDataConfig] = useState({
    countryList: [],
    basedCountryList: [],
    genderList: [],
  });

  const [frmRegister, setFrmRegister] = useState({
    username: null,
    password: null,
    passwordRepeat: null,
    email: null,
    idCountry: null,
    mobilePhone: null,
    name: null,
    birthday: null,
    gender: 'M',
    mobileCountryCode: null,
  });

  useEffect(async () => {
    const lstCountry = await registerService.getCountryCode();

    setDataConfig({
      basedCountryList: lstCountry,
      countryList: lstCountry,
      genderList: contants.GENDER,
    });
  }, []);

  const showAlert = (title, content) => {
    props.$f7router.app.dialog.alert(content, title, () => {});
  };

  const submitSignUpForm = async () => {
    if (!(await validateFormBeforeSubmit(frmRegister))) {
      return;
    }

    const success = await registerService.register(frmRegister);

    if (success) {
      store.profileStore.setName(frmRegister.name);
      props.$f7router.navigate('/welcome');
      return;
    }

    props.$f7router.app.dialog.alert(
      'Sign up failed, Please try again!',
      'Error',
    );
  };

  const validateFormBeforeSubmit = async frmRegister => {
    let isValid = true;
    if (frmRegister.password !== frmRegister.passwordRepeat) {
      showAlert('Warning', 'Password not match, Please check again!');
      isValid = false;
      return isValid;
    }

    if (await checkEmailExists(frmRegister.email)) {
      showAlert(
        'Warning',
        'Email is already registered, Please input another email!',
      );
      isValid = false;
      return isValid;
    }

    if (await checkUserExists(frmRegister.username)) {
      showAlert(
        'Warning',
        'Username is already registered, Please input another username!',
      );
      isValid = false;
      return isValid;
    }

    return isValid;
  };

  const checkEmailExists = async email => {
    let isExist = await registerService.checkEmailExits(email);
    return isExist;
  };

  const checkUserExists = async username => {
    let isExist = await registerService.checkUserExits(username);
    return isExist;
  };

  const onFormChange = event => {
    const input = event.target;
    const value = input.value;
    frmRegister[input.name] = value || null;
    setFrmRegister(frmRegister);
  };

  async function redirectLogin() {
    props.$f7router.back('/', { force: true });
  }

  return (
    <Page>
      <Navbar>
        <NavLeft>
          <Link icon="icon-back" href="/" noFastClick />
        </NavLeft>
        <NavRight>
          <img
            className="logo-system"
            src="../static/img/logo-opus.svg"
            style={{ maxHeight: '30px' }}
            alt="logo-opus"
          />
        </NavRight>
      </Navbar>

      <Block style={{ height: '5%' }}>
        <Row>
          <Col>
            <strong>Hi!</strong>
            <p>Please inform your personal data.</p>
          </Col>
        </Row>
      </Block>

      <List noHairlines id="frmRegister">
        <ListInput
          label={<label htmlFor="name">Full Name</label>}
          inputId="name"
          type="text"
          placeholder="Your name"
          name="name"
          onInput={onFormChange}
          clearButton
          required
          validate
        />
        <ListInput
          label={<label htmlFor="email">E-mail</label>}
          inputId="email"
          type="email"
          placeholder="Your e-mail"
          name="email"
          onInput={onFormChange}
          required
          validate
          clearButton
        />

        <ListInput
          type="select"
          label={<label htmlFor="mobileCountryCode">Mobile Country</label>}
          inputId="mobileCountryCode"
          readonly
          name="mobileCountryCode"
          onChange={onFormChange}
        >
          <option value=""></option>
          {dataConfig.countryList.map((item, index) => (
            <option key={item.idCountry} value={item.phoneCode}>
              {item.phoneCountry}
            </option>
          ))}
        </ListInput>

        <ListInput
          label={<label htmlFor="mobilePhone">Mobile Phone</label>}
          inputId="mobilePhone"
          type="tel"
          placeholder="Your mobile phone"
          name="mobilePhone"
          onInput={onFormChange}
          clearButton
        />

        <ListItemRow>
          <ListItemCell style={{ flex: 5 }}>
            <ListInput
              // outline
              type="date"
              label={<label htmlFor="birthday">Birthday</label>}
              inputId="birthday"
              name="birthday"
              required
              onChange={onFormChange}
            ></ListInput>
          </ListItemCell>
          <ListItemCell style={{ flex: 4, marginLeft: 0 }}>
            <ListInput
              type="select"
              label={<label htmlFor="gender">Gender</label>}
              inputId="gender"
              required
              validate
              placeholder="Select Gender"
              name="gender"
              onChange={onFormChange}
            >
              {dataConfig.genderList.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.value}
                </option>
              ))}
            </ListInput>
          </ListItemCell>
        </ListItemRow>
        <ListInput
          type="select"
          label={<label htmlFor="idCountry">Based Country</label>}
          inputId="idCountry"
          name="idCountry"
          onChange={onFormChange}
        >
          <option value=""></option>
          {dataConfig.basedCountryList.map((item, index) => (
            <option key={index} value={item.idCountry}>
              {item.name}
            </option>
          ))}
        </ListInput>
        <ListInput
          label={<label htmlFor="username">Username</label>}
          type="text"
          placeholder="Your username"
          name="username"
          inputId="username"
          onInput={onFormChange}
          required
          validate
          clearButton
        />
        <ListInput
          label={<label htmlFor="password">Password</label>}
          inputId="password"
          type="password"
          placeholder="Your password"
          required
          validate
          name="password"
          onInput={onFormChange}
          clearButton
        />
        <ListInput
          label={<label htmlFor="passwordRepeat">Retype Password</label>}
          type="password"
          placeholder="Your password again"
          required
          validate
          name="passwordRepeat"
          inputId="passwordRepeat"
          onInput={onFormChange}
          clearButton
        />
      </List>

      <Block
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <button
          form="frmRegister"
          type="submit"
          className="button button-fill button-round"
          style={{
            width: '200px',
          }}
          onClick={submitSignUpForm}
        >
          Sign Up
        </button>
      </Block>
    </Page>
  );
};

export default SignUp;
