import * as terminalRoleApi from '../api/user-terminal-role.api';
import * as commonApi from '../api/common.api';

export const getTerminalRoles = async () => {
  try {
    return await terminalRoleApi.getTerminalRoles();
  } catch (error) {
    console.error(error.response);
    return [];
  }
};

export const getComCodesCp = async (tmlCd, grpId) => {
  try {
    return await commonApi.getComCodesCp(tmlCd, grpId);
  } catch (error) {
    console.error(error.response);
    return [];
  }
};
