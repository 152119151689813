import {
  Block,
  List,
  ListItem,
  Page,
  Navbar,
  NavLeft,
  NavRight,
  Link,
  Icon,
  BlockTitle,
  Button,
  Toolbar,
} from 'framework7-react';
import React, { useState, useEffect } from 'react';
import * as roleService from '../../services/role.service';

const ManageRole = props => {
  const [roleList, setRoleList] = useState([]);

  useEffect(async () => {
    await initialize();
  }, []);

  const initialize = async () => {
    const roleLst = await roleService.getRoleList();
    setRoleList(roleLst);
  };

  const onDeleteRole = role => {
    if (roleList.length === 1) {
      props.$f7router.app.dialog.alert('Can not delete role', () => {});
      return;
    }

    props.$f7router.app.dialog.confirm('Do you want to DELETE role ?', () => {
      deleteRole(role);
    });
  };

  const deleteRole = async role => {
    const result = await roleService.deleteRole(role);
    if (result) {
      props.$f7router.app.dialog.alert('Delete role successfully', () => {});
      await initialize();
    } else {
      props.$f7router.app.dialog.alert('Delete role failed', () => {});
    }
  };

  const navigateSetRole = () => {
    props.$f7router.navigate('/setting-role', { props: { setRoleList } });
  };

  async function onBackClick() {
    const roles = await roleService.getRoleList();
    props.setRoles(roles);
    props.$f7router.back();
  }

  return (
    <Page>
      <Navbar>
        <NavLeft>
          <Link icon="icon-back" onClick={onBackClick} />
        </NavLeft>
        <NavRight>
          <Link>
            <img className="logo-system" style={{ maxHeight: '30px' }} />
          </Link>
        </NavRight>
      </Navbar>
      <Block>
        <strong>User Profile</strong>
      </Block>
      <BlockTitle>User access profile</BlockTitle>
      <List mediaList>
        {roleList.map((r, index) => {
          return (
            <ListItem
              key={index}
              header={r.tmlNm || ''}
              title={r.roleNm}
              text={r.companyNm || ''}
            >
              <Button
                small
                fill
                slot="after"
                color="red"
                onClick={() => onDeleteRole(r)}
              >
                <Icon material="delete" style={{ display: 'block' }} />
              </Button>
            </ListItem>
          );
        })}
      </List>
      <Toolbar bottom noHairline noShadow noBorder inner={false}>
        <Button
          className="button button-fill"
          style={{
            width: '100%',
            position: 'absolute',
            bottom: 0,
          }}
          onClick={navigateSetRole}
        >
          New Access Profile
        </Button>
      </Toolbar>
    </Page>
  );
};

export default ManageRole;
