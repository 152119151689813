import * as resetPasswordApi from '../api/reset-password.api';
import * as emailApi from '../api/email.api'

export const resetPassword = async (token, email, password) => {
  try {
    await resetPasswordApi.resetPassword(token, email, password);
    return true;
  } catch (error) {
    console.error(error.response);
    return false;
  }
};

export const isValidEmail = async (token, email) => {
  try {
    const isValid = await emailApi.isValidEmail(token, email);
    return isValid;
  } catch (error) {
    console.error(error.response);
    return false;
  }
};

export const verify = async (email) => {
  return await resetPasswordApi.verify(email);
};