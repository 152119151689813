import * as roleApi from '../api/role.api';
import { ROLES } from '../const';
import { profile } from '../api/auth.api';

// export const getRoles = () => {
//   return ROLES;
// };

const createParamsForSettingRole = frmData => {
  let params = {
    idCompany: frmData.idCompany,
    user: {
      role: frmData.role,
    },
    companyPersons: [
      {
        tmlCd: frmData.tmlCd,
        idCompany: frmData.idCompany,
      },
    ],
  };
  return params;
};

export const getTerminals = async () => {
  try {
    const lstTerminals = await roleApi.getTerminal();
    return lstTerminals;
  } catch (error) {
    console.error(error.response);
    return [];
  }
};

export const getCompanyByRole = async (role, tmlCd) => {
  try {
    const lstCompanyRole = await roleApi.getCompanyByTmlCd(tmlCd, role);
    return lstCompanyRole;
  } catch (error) {
    console.error(error.response);
    return [];
  }
};

export const settingRole = async formData => {
  try {
    const userInfo = createParamsForSettingRole(formData);
    await roleApi.settingRole(userInfo);
    return true;
  } catch (error) {
    console.log(error.message);
    return false;
  }
};

export const isRoleExisted = async roleCd => {
  try {
    // Driver has multi role
    if (roleCd === 'D') {
      return false;
    }

    const roles = await getRole();
    const { role } = ROLES.find(r => r.value === roleCd);
    return roles.includes(role);
  } catch (error) {
    console.log(error.message);
    return true;
  }
};

const getRoleCode = role => {
  const r = ROLES.find(e => e.role === role);
  return r.value;
};

export const deleteRole = async roleInfo => {
  try {
    const roleCd = getRoleCode(roleInfo.role);
    roleInfo.roleCd = roleCd;
    await roleApi.deleteRole(roleInfo);
    return true;
  } catch (error) {
    console.log(error.message);
    return false;
  }
};

export const getRole = async () => {
  try {
    const userInfo = await profile();

    return userInfo.role.replace('user,', '');
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getRoleList = async crntRole => {
  try {
    return await roleApi.getRoles();
  } catch (error) {
    console.error(error.response);
    return [];
  }
};
