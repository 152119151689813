import {
  Block,
  Button,
  Col,
  Link,
  List,
  ListInput,
  ListItemCell,
  ListItemRow,
  ListItem,
  Navbar,
  NavLeft,
  NavRight,
  NavTitle,
  Page,
  Row,
  BlockTitle,
  Badge,
  AccordionContent,
  Card,
} from 'framework7-react';
import React, { useContext, useEffect, useState, Fragment } from 'react';
import * as profileService from '../services/profile.service';
import { GENDER } from '../const';
import moment from 'moment';
import { StoreContext } from '../store';
import { setRoleInfo } from '../services/store.service';
// import TerminalsRoles from '../components/terminals-roles/index';
import * as terminalService from '../services/terminal-service';

const Profile = props => {
  const { profileStore, commonStore } = useContext(StoreContext);
  const [updProfileForm, setUpdProfileForm] = useState({
    idCountry: null,
    mobilePhoneNumber: null,
    gender: 'M',
    mobileCountryCode: null,
    oldPassword: null,
    password: null,
    retypePassword: null,
  });
  const [openChgPwForm, openChangePasswordForm] = useState(false);
  const [profile, setProfile] = useState({});
  const [userTerminalRoles, setUserTerminalRoles] = useState([]);
  const [dataConfig, setDataConfig] = useState({
    countryList: [],
    basedCountryList: [],
    genderList: [],
  });

  useEffect(async () => {
    const lstCountry = await profileService.getCountryCode();
    setDataConfig({
      basedCountryList: lstCountry,
      countryList: lstCountry,
      genderList: GENDER,
    });

    const userInfo = await profileService.getProfile();
    const roles = await profileService.getRoleList();
    setProfile(userInfo);
    if (!userInfo) return;

    updProfileForm.gender = userInfo.gender;
    updProfileForm.mobilePhoneNumber = userInfo.mobilePhoneNumber;
    updProfileForm.mobileCountryCode = userInfo.mobileCountryCode;
    updProfileForm.idCountry = userInfo.idCountry;
    setUpdProfileForm({ ...updProfileForm });

    let terminals = commonStore.getTerminalList;
    if (!terminals || !terminals.length) {
      terminals = await terminalService.getTerminals();
      commonStore.setTerminalList(terminals);
    }

    const userRoles = userInfo.userTerminalRoles.map(item => {
      const roleObjLst = item.roles.split(', ').map(roleId => {
        return roles.find(x => x.roleId == roleId);
      });
      const tmlObj = terminals.find(tml => tml.tmlCd === item.tmlCd);
      return { ...tmlObj, roles: roleObjLst.filter(item2 => item2) };
    });
    setUserTerminalRoles(userRoles);
  }, []);

  const initTerrminal = async () => {};

  const showAlert = (title, content) => {
    props.$f7router.app.dialog.alert(content, title, () => {});
  };

  const saveProfile = async () => {
    const dataSave = clearEmptyValue(updProfileForm);
    const success = await profileService.saveProfile(dataSave);

    if (success) {
      showAlert('Info', 'Save profile successfully!');
      openChangePasswordForm(false);
      updProfileForm.password = null;
      updProfileForm.oldPassword = null;
      updProfileForm.retypePassword = null;
      setUpdProfileForm(updProfileForm);
    } else {
      showAlert('Warning', 'Save profile failed!');
    }
  };

  const clearEmptyValue = obj => {
    Object.entries(obj).forEach(([key, value]) => {
      if (value === '') {
        obj[key] = null; //Replace Empty value to null
      }
    });
    return obj;
  };

  const validChgPwForm = async () => {
    const form = document.getElementById('changePasswordForm');

    if (!form.checkValidity()) {
      return false;
    }

    if (updProfileForm.password !== updProfileForm.retypePassword) {
      showAlert('Warning', 'Password not match, Please check again!');
      return false;
    }

    if (!(await profileService.checkOldPassword(updProfileForm.oldPassword))) {
      showAlert('Warning', 'Old password is wrong, Please check again!');
      return false;
    }

    return true;
  };

  const onFormChange = e => {
    const input = e.target;
    updProfileForm[input.name] = input.value;
    setUpdProfileForm({ ...updProfileForm });
  };

  const onChgPwClick = () => {
    if (openChgPwForm) {
      return;
    }
    openChangePasswordForm(true);
    const ele = document.getElementById('saveBtn');
    ele.scrollIntoView(false);
  };

  const onSaveClick = async () => {
    if (openChgPwForm) {
      if (!(await validChgPwForm())) {
        return;
      }
    }

    props.$f7router.app.dialog.confirm(
      'Do you want to update profile',
      'Confirm',
      () => {
        saveProfile();
      },
    );
  };

  const navigateToMain = async () => {
    const { role, company } = profileStore;
    const roles = await profileService.getRoleList();
    const isExist = await profileService.isRoleExisted(role, company, roles);

    if (isExist) {
      props.$f7router.back();
      return;
    }

    if (roles.length === 1) {
      await setRoleInfo(roles[0]);
      props.$f7router.back();
    } else {
      props.$f7router.navigate('/select-role');
    }
  };

  const renderRoles = roles => {
    return (
      <Fragment>
        {roles.map((item, index) => (
          <Badge style={{ margin: '3px' }} key={index}>
            {item.name}
          </Badge>
        ))}
      </Fragment>
    );
  };
  const CSS = `
  .terminal-roles-item .item-title{
    font-weight: unset !important;
  }
  `;
  return (
    <Page>
      <style>{CSS}</style>
      <Navbar>
        <NavLeft>
          <Link icon="icon-back" back />
        </NavLeft>
        <NavTitle style={{ overflow: 'unset' }}>Profile</NavTitle>
      </Navbar>
      <Card style={{ margin: 0 }} className="user-info-form">
        <List form accordionList id="formUpdateProfile">
          <ListItem
            accordionItem
            title="User Profile"
            // className="accordion-item-opened"
          >
            <AccordionContent>
              <ListInput
                label="Name"
                type="text"
                value={profile.name || ''}
                disabled
              />
              <ListInput
                label="User name"
                type="text"
                name="username"
                value={profile.username || ''}
                disabled
              />
              <ListInput
                label="E-mail"
                type="email"
                name="email"
                value={profile.email || ''}
                disabled
              />

              <ListItemRow>
                <ListItemCell style={{ flex: 5 }}>
                  <ListInput
                    // outline
                    type="date"
                    label="Birthday"
                    name="birthday"
                    value={
                      profile
                        ? moment(profile.birthDate).format('YYYY-MM-DD')
                        : ''
                    }
                    disabled
                  ></ListInput>
                </ListItemCell>
                <ListItemCell style={{ flex: 4, marginLeft: 0 }}>
                  <ListInput
                    type="select"
                    label="Gender"
                    name="gender"
                    value={updProfileForm.gender || ''}
                    onChange={onFormChange}
                  >
                    {dataConfig.genderList.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.value}
                      </option>
                    ))}
                  </ListInput>
                </ListItemCell>
              </ListItemRow>
              <ListInput
                type="select"
                label="Mobile Country"
                readonly
                name="mobileCountryCode"
                onChange={onFormChange}
                value={updProfileForm.mobileCountryCode || ''}
              >
                <option value=""></option>
                {dataConfig.countryList.map((item, index) => (
                  <option key={item.idCountry} value={item.phoneCode}>
                    {item.phoneCountry}
                  </option>
                ))}
              </ListInput>

              <ListInput
                label="Mobile Phone"
                type="tel"
                placeholder="Your mobile phone"
                name="mobilePhoneNumber"
                onInput={onFormChange}
                value={updProfileForm.mobilePhoneNumber || ''}
                clearButton
              />

              <ListInput
                type="select"
                label="Based Country"
                name="idCountry"
                onChange={onFormChange}
                value={updProfileForm.idCountry || ''}
              >
                <option value=""></option>
                {dataConfig.basedCountryList.map((item, index) => (
                  <option key={index} value={item.idCountry}>
                    {item.name}
                  </option>
                ))}
              </ListInput>
            </AccordionContent>
          </ListItem>
        </List>
        <List accordionList>
          <ListItem accordionItem title="Terminal-Roles">
            <AccordionContent>
              <List mediaList>
                {userTerminalRoles.map((item, index) => (
                  <ListItem
                    key={index}
                    className="terminal-roles-item"
                    title={item.name}
                  >
                    {renderRoles(item.roles)}
                  </ListItem>
                ))}
              </List>
              {(!userTerminalRoles || !userTerminalRoles.length) && (
                <Row>
                  <Col style={{ textAlign: 'center', fontStyle: 'italic' }}>
                    No data
                  </Col>
                </Row>
              )}
            </AccordionContent>
          </ListItem>
        </List>
      </Card>

      {openChgPwForm && (
        <List id="changePasswordForm" form>
          <ListInput
            label="Old Password"
            type="password"
            name="oldPassword"
            placeholder="Your Old password"
            onInput={onFormChange}
            required
            validate
          />
          <ListInput
            label="New Password"
            type="password"
            name="password"
            placeholder="Your password"
            onInput={onFormChange}
            required
            validate
          />
          <ListInput
            label="Retype New Password"
            type="password"
            name="retypePassword"
            placeholder="Your Password again"
            onInput={onFormChange}
            required
            validate
          />
        </List>
      )}

      <Row style={{ padding: '10px' }}>
        {!openChgPwForm && (
          <Col>
            <Button
              className="button button-fill button-round"
              style={{
                width: '160px',
                margin: 'auto',
              }}
              onClick={onChgPwClick}
            >
              Change password
            </Button>
          </Col>
        )}
        <Col>
          <Button
            id="saveBtn"
            className="button button-fill button-round"
            style={{
              width: '160px',
              margin: 'auto',
            }}
            onClick={onSaveClick}
          >
            Save
          </Button>
        </Col>
      </Row>
    </Page>
  );
};

export default Profile;
