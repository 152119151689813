import axios from './axios';

export const getUserMenuTerminalRole = async (idTerminal = '') => {
  const { data } = await axios.get(
    `menu-terminal-role/${idTerminal}/user-menu`,
  );
  return data;
};

export const getMenuTerminalRole = async (idTerminal = '', role = '') => {
  const { data } = await axios.get(`menu-terminal-role/${idTerminal}/${role}/menu`);
  return data;
};

export const getMenuResource = async () => {
  const { data } = await axios.get(`menu-terminal-role/menu-resource`);
  return data;
};

export const saveMenuTerminalRole = async (params = {}) => {
  await axios.put('menu-terminal-role/save', params);
};

export const addRole = async (params = {}) => {
  await axios.post('roles', params);
};

export const updateRole = async (params = {}) => {
  await axios.put('roles', params);
};

export const deleteRole = async roleId => {
  const { data } = await axios.delete(`roles/${roleId}`);
  return data;
};

export const getRoleListByMenuId = async (tmlCd, menuId) => {
  const { data } = await axios.get(
    `menu-terminal-role/${tmlCd}/${menuId}/roles`,
  );
  return data;
};
