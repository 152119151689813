import { makeAutoObservable } from 'mobx';

export class ProfileStore {
  idUser;

  username;

  name;

  role;

  sysAdm;

  terminal;

  idTerminal;

  company;

  constructor() {
    makeAutoObservable(this);
  }

  setValues(user) {
    this.idUser = user.idUser || '';

    this.username = user.username || '';

    this.name = user.person.name || '';

    this.sysAdm = user.sysAdm || 'N';
  }

  setName(name) {
    this.name = name;
  }

  setRole(role) {
    this.role = role;
  }

  setTerminal(terminal) {
    this.terminal = terminal;
  }

  setCompany(company) {
    this.company = company;
  }

  setIdTerminal(idTerminal) {
    this.idTerminal = idTerminal;
  }

  clearStore() {
    this.idUser = '';

    this.username = '';

    this.name = '';

    this.role = '';

    this.terminal = '';

    this.company = '';

    this.sysAdm = 'N';

    this.idTerminal = '';
  }
}
