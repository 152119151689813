import { makeAutoObservable, toJS } from 'mobx';

export class CommonStore {
  // terminalRoleList = [];
  terminalList = [];

  roleList = [];

  menu = [];

  constructor() {
    makeAutoObservable(this);
  }

  setTerminalList(dataList = []) {
    this.terminalList = dataList;
  }

  setRoleList(dataList = []) {
    this.roleList = dataList;
  }

  setMenu(menu = []) {
    this.menu = menu;
  }

  get getTerminalList() {
    return toJS(this.terminalList);
  }

  get getRoleList() {
    return toJS(this.roleList);
  }

  get getMenu() {
    return toJS(this.menu);
  }
  // setTerminalRoleList(dataList = []) {
  //   this.terminalRoleList = dataList;
  // }

  // get getTerminalRoleList() {
  //   return toJS(this.terminalRoleList);
  // }

  clearStore() {
    this.terminalList = [];
    this.roleList = [];
  }
}
