import { makeAutoObservable, toJS } from 'mobx';

export class UserStore {
  userList = [];
  user = {};
  updateDataListRev = 0;
  constructor() {
    makeAutoObservable(this);
  }

  setUserList(dataList = []) {
    this.userList = dataList;
  }

  updateUserInList(newInfo) {
    const index = this.userList.findIndex(usr => usr.idUser === newInfo.idUser);
    this.userList.splice(index, 1, newInfo);
    this.updateDataListRev++;
  }

  setUser(data = {}) {
    this.user = data;
  }

  updateUserTerminalsRoles(idTerminal, roles) {
    if (!this.user) {
      return;
    }
    const currentRoles = (this.user.userTerminalRoles || []).find(
      item => item.idTerminal == idTerminal,
    );
    if (currentRoles) {
      currentRoles.roles = roles;
      return;
    }
    this.user.userTerminalRoles = (this.user.userTerminalRoles || []).concat([
      {
        idTerminal,
        roles,
      },
    ]);
  }

  get getUserList() {
    return toJS(this.userList);
  }

  get getUser() {
    return toJS(this.user);
  }

  clearStore() {
    this.userList = [];
    this.user = {};
  }
}
