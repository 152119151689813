import { BlockTitle, List, ListItem } from 'framework7-react';
import React, { useRef, useEffect } from 'react';
import './style.scss';

const MultiSelect = props => {
  const ref = useRef(null);

  useEffect(() => {
    if (!ref || !ref.current) return;
    ref.current.f7SmartSelect.setValue(props.values);
  }, [JSON.stringify(props.values)]);

  const renderItems = () => {
    if (!props.collection || !props.collection.length) return null;

    let mappingPropsValue = props.mappingPropsValue || 'value';
    let mappingPropsKey = props.mappingPropsKey || 'id';

    return props.collection.map((itemMulti, key) => {
      return (
        <option key={key} value={itemMulti[mappingPropsKey]}>
          {itemMulti[mappingPropsValue]}
        </option>
      );
    });
  };

  const onChangeValues = event => {
    const values = Array.from(
      event.target.selectedOptions,
      option => option.value,
    );

    props.onChange(values);
  };

  return (
    <List className={'list-multiselect'}>
      <BlockTitle
        style={{
          fontWeight: 'normal',
          fontSize: '12px',
          paddingLeft:
            'calc(var(--f7-list-item-padding-horizontal) + var(--f7-safe-area-left))',
        }}
        color="gray"
      >
        {props.title}
      </BlockTitle>
      <ListItem
        ref={ref}
        title={' '}
        smartSelect
        smartSelectParams={{ openIn: 'sheet' }}
      >
        <select
          onChange={onChangeValues}
          multiple
          placeholder={props.defaultDisabledOption}
        >
          {/* {props.defaultDisabledOption && (
            <div value="" disabled>
              {props.defaultDisabledOption}
            </div>
          )} */}
          {renderItems()}
        </select>
      </ListItem>
    </List>
  );
};

export default MultiSelect;
