import { f7 } from 'framework7-react';

export const separateThousandsByComma = value => {
  if (value === '' || typeof value === 'undefined') return value;

  // Remove number '0' of decimal part
  value = value.toString().replace(/(\.[0-9]*[1-9])0+$|\.0*$/, '$1');

  // Separate thousand of integer part
  let num_parts = value.toString().split('.');
  let intPart = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  let decimalPart = num_parts[1] || '';
  let displayValue = intPart + (num_parts.length > 1 ? '.' + decimalPart : '');

  return displayValue;
};

export const showLoading = () => {
  f7.dialog.progress('Loading...');
};

export const hideLoading = () => {
  f7.dialog.close();
};

export const openNotification = (title, message) => {
  const notification = f7.notification.create({
    title: title,
    titleRightText: 'now',
    subtitle: message,
    closeTimeout: 3000,
    closeOnClick: true,
  });
  notification.open();
};

export const openConfirm = async (message) => {
  return new Promise((res) => {
    f7.dialog.confirm(message, '', () => res(true), () => res(false));
  })
};

export const alertErrorMess = message => {
  openNotification(
    'Error',
    message || 'Something went wrong, please try again!',
  );
};

export const buildFormData = (data = { files: [], params: {} }) => {
  const formData = new FormData();
  const { files, params } = data;

  files.forEach((f, idx) => {
    formData.append('files', f.file);
  });

  formData.append('params', JSON.stringify(params));

  return formData;
};

export const generateId = length => {
  var text = '';
  var possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (var i = 0; i < length; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
};

export const isValidFormByFormId = (formId) => {
  let isValid = true;
  const inputs = document.querySelectorAll(`#${formId} input[required][data-validate="true"]`);

  for (const input of inputs) {
    if (!input.checkValidity()) {
      isValid = false;
    }
  }

  return isValid;
}
