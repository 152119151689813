import { App, f7ready, View } from 'framework7-react';
import { Device } from 'framework7/framework7-lite.esm.bundle.js';
import React from 'react';
import { SWRConfig } from 'swr';
import cordovaApp from '../js/cordova-app';
import routes from '../js/routes';
import { store } from '../store/index';
import { observer } from 'mobx-react';
export default observer(
  class extends React.Component {
    constructor() {
      super();
      this.state = {
        // Framework7 Parameters
        f7params: {
          id: 'com.clt.customerportal', // App bundle ID
          name: 'Customer Portal', // App name
          theme: 'auto', // Automatic theme detection
          xhrCache: false,

          // App routes
          routes: routes,

          // Register service worker
          serviceWorker: Device.cordova
            ? {}
            : {
                path: '/service-worker.js',
              },
          // Input settings
          input: {
            scrollIntoViewOnFocus: Device.cordova && !Device.electron,
            scrollIntoViewCentered: Device.cordova && !Device.electron,
          },
          // Cordova Statusbar settings
          statusbar: {
            iosOverlaysWebView: true,
            androidOverlaysWebView: false,
          },
        },
      };
    }

    render() {
      return (
        <SWRConfig
          value={{
            revalidateOnFocus: false,
            shouldRetryOnError: false,
          }}
        >
          <App
            params={this.state.f7params}
            themeDark={store.appStore.darkTheme}
          >
            {/* Your main view, should have "view-main" class */}
            <View
              main
              className="safe-areas"
              url="/"
              pushState={true}
              preloadPreviousPage={false}
            />
          </App>
        </SWRConfig>
      );
    }

    componentDidMount() {
      f7ready(f7 => {
        // Init cordova APIs (see cordova-app.js)
        if (Device.cordova) {
          cordovaApp.init(f7);
        }
        // Call F7 APIs here
      });
    }
  },
);
