import { List, ListInput, BlockTitle } from 'framework7-react';
import React, {
  useState,
  useContext,
  useEffect,
  Fragment,
  useMemo,
} from 'react';
import { StoreContext } from '../../store';
import * as terminalRoleService from '../../services/user-terminal-role.service';
// import { getTerminals } from '../../services/terminal-service.js';
import * as terminalService from '../../services/terminal-service.js';

import * as storeService from '../../services/store.service';
import { observer } from 'mobx-react';
// import { CP_GROUP_CODES } from '../../const';

const SelectTerminal = observer(props => {
  const { profileStore } = useContext(StoreContext);
  const [terminalList, setTerminalList] = useState([]);
  const [userTerminalRoles, setUserTerminalRoles] = useState([]);
  // const [roles, setRoles] = useState([]);
  // const [tmlCd, setTmlCd] = useState('');
  const [idTerminal, setIdTerminal] = useState('');
  // const [role, setRoleVal] = useState('');

  useEffect(async () => {
    await initialize();
  }, []);

  // useMemo(async () => {
  //   if (!tmlCd) {
  //     setRoles([]);
  //     return;
  //   }

  //   const roleCodes = await terminalRoleService.getComCodesCp(
  //     tmlCd,
  //     CP_GROUP_CODES.USER_ROLE,
  //   );
  //   const userTerminalRole = userTerminalRoles.find(
  //     item => item.tmlCd === tmlCd,
  //   );
  //   const roleLst = !userTerminalRole.roles
  //     ? []
  //     : userTerminalRole.roles.split(',').map(item => {
  //         const roleItem = roleCodes.find(cd => cd.comCdId === item.trim());
  //         return { name: roleItem.dpCdNm, value: item.trim() };
  //       });
  //   setRoles([{ name: '', value: '' }].concat(roleLst));
  // }, [tmlCd]);

  const initialize = async () => {
    // const result = await terminalRoleService.getTerminalRoles();
    const result = await terminalService.getTerminals();
    setUserTerminalRoles(result);
    const terminalLst = result.map(item => {
      const { tmlCd, name, idTerminal } = item || {};
      return { tmlCd, name, idTerminal };
    });
    setTerminalList(
      [{ name: '', tmlCd: '', idTerminal: '' }].concat(terminalLst),
    );
    // setTmlCd(profileStore.terminal || localStorage.getItem('terminal'));
    setIdTerminal(
      profileStore.idTerminal || localStorage.getItem('idTerminal'),
    );

    // setRoleVal(profileStore.role || localStorage.getItem('role'));
  };

  const onSelectedTerminal = idTerminal => {
    if (!idTerminal) {
      return;
    }
    // setTmlCd(tmlCd);
    const terminal = userTerminalRoles.find(
      tml => tml.idTerminal == idTerminal,
    );
    storeService.setTerminal(terminal.tmlCd);

    setIdTerminal(idTerminal);
    storeService.setIdTerminal(idTerminal);

    // setRoleVal('');
    if (props.onAfterSelected) {
      props.onAfterSelected();
    }
  };

  const onSelectRole = role => {
    if (!role) return;
    // setRoleVal(role);
    // setRole(role);
    if (props.onAfterSelected) {
      props.onAfterSelected();
    }
  };

  return (
    <Fragment>
      {/* <BlockTitle className="strong">Terminal</BlockTitle> */}
      <List>
        <ListInput
          type="select"
          label={<label htmlFor="terminal">Terminal</label>}
          inputId="terminal"
          validate
          value={idTerminal}
          onChange={e => {
            e.preventDefault();
            onSelectedTerminal(e.target.value);
          }}
        >
          {terminalList &&
            terminalList.map((item, index) => (
              <option key={index} value={item.idTerminal}>
                {item.tmlCd ? item.tmlCd + ' - ' : ''}
                {item.name}
              </option>
            ))}
        </ListInput>
      </List>
      {/* <BlockTitle className="strong">Role</BlockTitle>
      <List>
        <ListInput
          type="select"
          validate
          value={role || ''}
          onChange={e => {
            e.preventDefault();
            onSelectRole(e.target.value);
          }}
        >
          {roles &&
            roles.map((item, index) => (
              <option key={index} value={item.value}>
                {item.name}
              </option>
            ))}
        </ListInput>
      </List> */}
    </Fragment>
  );
});

export default SelectTerminal;
