import React from 'react';
import VesselVoyageItem from './VesselVoyageItem';

class ListVesselVoyage extends React.Component {
  renderedListVesselVoyage() {
    if (!this.props.vslVoyLst || !this.props.vslVoyLst.length) return null;

    return this.props.vslVoyLst.map((voyage, index) => {
      return (
        <VesselVoyageItem
          voyage={voyage}
          key={index}
          onClick={this.props.onClick}
        />
      );
    });
  }

  render() {
    return (
      <div style={{height: 'calc(100% - 310px)', overflow: 'auto'}}>
        {this.renderedListVesselVoyage()}
      </div>
    );
  }
}

export default ListVesselVoyage;
