import {
  Link,
  Navbar,
  NavLeft,
  NavTitle,
  Page,
  Card,
  CardContent,
  Searchbar,
  AccordionContent,
  ListItem,
  List,
  Block,
  ListInput,
} from 'framework7-react';
import LineDivine from '../../components/SolidLine';
import { f7 } from 'framework7-react';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import UserItem from '../../components/user/UserItem';
import { StoreContext } from '../../store';
import * as userService from '../../services/user.service';
import * as roleService from '../../services/role.service';
import * as terminalService from '../../services/terminal-service';
const User = ({ f7router }) => {
  const { userStore, profileStore, commonStore } = useContext(StoreContext);
  const [userList, setUserList] = useState([]);
  const [placeholderSearch, setPlaceholderSearch] = useState('Name/Email');
  const [sysAdm, setSysAdm] = useState('N');
  const [terminalList, setTerrminalList] = useState([]);
  const [roleList, setRoleList] = useState([{ name: 'ALL', value: '' }]);
  const [filterCondition, setFilterCondition] = useState({
    idTerminal: '',
    role: '',
    keyword: '',
  });

  const onSelectedUser = userInfo => {
    userStore.setUser(userInfo);
    f7router.navigate('user-form');
  };

  useEffect(async () => {
    await initTerrminalRole();
    await initialize();
  }, []);

  const initialize = async () => {
    showLoading();
    const { sysAdm, idTerminal } = profileStore;
    await userService.fetchAllUserProfile(idTerminal);
    setUserList(userStore.getUserList);
    setSysAdm(sysAdm || 'N');
    if (!sysAdm || sysAdm !== 'Y') {
      setFilterCondition({ ...filterCondition, idTerminal: idTerminal });
      onBindRolesCombo();
    }

    hideLoading();
  };

  const initTerrminalRole = async () => {
    let terminals = commonStore.getTerminalList;
    if (!terminals || !terminals.length) {
      terminals = await terminalService.getTerminals();
      commonStore.setTerminalList(terminals);
    }

    const tmlLst = [{ name: 'ALL', value: '' }].concat(
      terminals.map(item => {
        return { name: `${item.tmlCd} - ${item.name}`, value: item.idTerminal };
      }),
    );
    setTerrminalList(tmlLst);
  };

  useMemo(() => {
    setUserList(userStore.getUserList);
  }, [userStore.updateDataListRev]);

  const showLoading = () => {
    f7.dialog.progress('Loading...');
  };

  const hideLoading = () => {
    f7.dialog.close();
  };

  const renderUserList = () => {
    return userList.map((item, index) => {
      return (
        <UserItem
          userInfo={item}
          idTerminal={filterCondition.idTerminal}
          key={index}
          onClick={onSelectedUser}
        />
      );
    });
  };

  useMemo(() => {
    const { idTerminal, role, keyword } = filterCondition;
    if (!idTerminal && !role && !keyword) {
      setUserList(userStore.getUserList);
      return;
    }

    const results = (userStore.getUserList || []).filter(item => {
      const { email, name } = item.person;
      const isMatchKeyword =
        !keyword ||
        email.toLowerCase().indexOf(keyword.toLowerCase()) >= 0 ||
        name.toLowerCase().indexOf(keyword.toLowerCase()) >= 0;

      const isMatchFilterOptions = (item.userTerminalRoles || []).find(
        tmlRole => {
          return (
            (!idTerminal || tmlRole.idTerminal == idTerminal) &&
            (!role || tmlRole.roles.indexOf(role) >= 0)
          );
        },
      );
      return isMatchKeyword && isMatchFilterOptions;
    });
    setUserList(results);
  }, [filterCondition]);

  const onChangeTerminalFilter = e => {
    const val = e.target.value;
    setFilterCondition({ ...filterCondition, idTerminal: val });
    onBindRolesCombo();
  };

  const onBindRolesCombo = async () => {
    let roles = commonStore.getRoleList;
    if (!roles || !roles.length) {
      roles = await roleService.getRoleList();
      commonStore.setRoleList(roles);
    }

    setRoleList(
      [{ name: 'ALL', value: '' }].concat(
        roles.map(item => {
          return { name: item.name, value: item.roleId };
        }),
      ),
    );
  };
  const onChangeRoleFilter = e => {
    const val = e.target.value;
    setFilterCondition({ ...filterCondition, role: val });
  };
  const styleCSS = `
    .advance-filter-area {
      margin: 0px 0px 10px 0px !important
    }
  `;
  return (
    <Page>
      <style>{styleCSS}</style>
      <Navbar>
        <NavLeft>
          <Link icon="icon-back" back />
        </NavLeft>
        <NavTitle>User List</NavTitle>
      </Navbar>
      <Searchbar
        placeholder={placeholderSearch}
        customSearch={true}
        onSubmit={() => {}}
        onSearchbarSearch={(searchbar, query) => {
          setFilterCondition({ ...filterCondition, keyword: query });
        }}
      />
      <List className="advance-filter-area">
        <ListItem accordionItem title="Filter options">
          <AccordionContent>
            {sysAdm && sysAdm === 'Y' && (
              <List>
                <ListInput
                  label="Terminal"
                  type="select"
                  validate
                  value={filterCondition.idTerminal}
                  onChange={e => {
                    onChangeTerminalFilter(e);
                  }}
                >
                  {terminalList.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.name}
                    </option>
                  ))}
                </ListInput>
              </List>
            )}
            <List>
              <ListInput
                label="Role"
                type="select"
                validate
                value={filterCondition.role}
                onChange={e => {
                  onChangeRoleFilter(e);
                }}
              >
                {roleList.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.name}
                  </option>
                ))}
              </ListInput>
            </List>
          </AccordionContent>
        </ListItem>
      </List>
      <Card style={{ margin: 0 }}>{renderUserList()}</Card>
    </Page>
  );
};

export default User;
