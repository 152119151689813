import * as vesselSoundingApi from '../api/vessel-sounding.api';


export const getSoundingMeasures = async (idTerminal, vslVoyId, vslLocCd, cmdtCd) => {
  try {
    const data = await vesselSoundingApi.getSoundingMeasures(idTerminal, vslVoyId, vslLocCd, cmdtCd);
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const getVesselCompartments = async (idTerminal, vslVoyId) => {
  try {
    const data = await vesselSoundingApi.getVesselCompartments(idTerminal, vslVoyId);
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}


export const getSoundingDetails = async ({ idTerminal, vslVoyId, chkSeq }) => {
  try {
    const data = await vesselSoundingApi.getSoundingDetails(idTerminal, vslVoyId, chkSeq);
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const getSoundingValues = async ({ idTerminal, cmdtCd }) => {
  try {
    const data = await vesselSoundingApi.getSoundingValues(idTerminal, cmdtCd);
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const updateSoundingDetails = async (params) => {
  try {
    const data = await vesselSoundingApi.updateSoundingDetails(params);
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const deleteSoundingDetails = async (params) => {
  try {
    const data = await vesselSoundingApi.deleteSoundingDetails(params);
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const getVesselDraft = async (params) => {
  try {

    let vesselDraft = await vesselSoundingApi.getVesselDraft(params);
    if (!vesselDraft) {
      return vesselDraft;
    }

    const { fwrdDepDrftHgt, aftDepDrftHgt, fwrdArrDrftHgt, aftArrDrftHgt } = vesselDraft;
    vesselDraft.depTrim = '';
    vesselDraft.arrTrim = '';
    if (fwrdArrDrftHgt && aftArrDrftHgt) {
      vesselDraft.arrTrim = Number(Number(fwrdArrDrftHgt - aftArrDrftHgt).toFixed(3));
    }

    if (fwrdDepDrftHgt && aftDepDrftHgt) {
      vesselDraft.depTrim = Number(Number(fwrdDepDrftHgt - aftDepDrftHgt).toFixed(3));
    }
    return vesselDraft;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const updateVesselDraft = async (terminal, vesselDraft) => {
  try {
    let { fwrdDepDrftHgt, aftDepDrftHgt, fwrdArrDrftHgt, aftArrDrftHgt } = vesselDraft;
    const dataUpdate = {
      vslVoyId: vesselDraft.vslVoyId,
      fwrdDepDrftHgt: fwrdDepDrftHgt ? Number(fwrdDepDrftHgt).toFixed(3) : null,
      aftDepDrftHgt: aftDepDrftHgt ? Number(aftDepDrftHgt).toFixed(3) : null,
      fwrdArrDrftHgt: fwrdArrDrftHgt ? Number(fwrdArrDrftHgt).toFixed(3) : null,
      aftArrDrftHgt: aftArrDrftHgt ? Number(aftArrDrftHgt).toFixed(3) : null,
    }
    const data = await vesselSoundingApi.updateVesselDraft(terminal, dataUpdate);
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}


