import React from 'react';
import {
  BlockTitle,
  Block,
  Button,
  Page,
  Toolbar,
  Navbar,
  NavTitle,
} from 'framework7-react';

const Confirm = props => {
  const redirectLogin = () => {
    props.$f7router.navigate('/', { clearPreviousHistory: true });
  };

  return (
    <Page>
      <Navbar>
        <NavTitle>Reset Password</NavTitle>
      </Navbar>

      <Block>
        <img
          className="logo-system"
          src="../static/img/logo-opus.svg"
          style={{ width: '260px' }}
          alt="logo-opus"
        ></img>
      </Block>

      <BlockTitle>Hello,</BlockTitle>
      <Block>Please check your e-mail and access the reset password URL</Block>

      <Toolbar
        bottom
        noHairline
        noShadow
        noBorder
        bgColor="unset"
        style={{
          border: 'unset',
        }}
      >
        <Button
          fill
          style={{
            width: '100%',
            marginTop: '16px',
          }}
          onClick={redirectLogin}
        >
          Sign In
        </Button>
      </Toolbar>
    </Page>
  );
};

export default Confirm;
