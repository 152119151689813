import {
  BlockTitle,
  Button,
  Card,
  CardContent,
  Col,
  Row,
} from 'framework7-react';
import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';
import * as VesselCommonService from '../../services/vessel-common.service';
import { StoreContext } from '../../store';
import { hideLoading, showLoading } from '../../utils/common';
import MultiSelect from '../multiselect/MultiSelect';
import LineDivine from '../SolidLine';
import ListVesselVoyage from './vessel-voyage/ListVesselVoyage';

const VesselMainPage = observer(({ onVoyageItemClick, menuId }) => {
  const { vesselMainpageStore, profileStore } = useContext(StoreContext);

  useEffect(async () => {
    await loadData();
  }, []);

  const loadData = async () => {
    showLoading();
    vesselMainpageStore.setVslVoyageLst([]);
    const vslVoySkdStsCdLst = await VesselCommonService.getVslVoySkdStsCdLst(
      profileStore.idTerminal,
    );
    const berthingPurposeCodes = await VesselCommonService.getComCodes(
      profileStore.idTerminal,
      'BERTHING_PURPOSE',
    );

    vesselMainpageStore.setInputFields({
      selectedVslVoyCds: [vslVoySkdStsCdLst[0]?.id || ''],
      vslVoySkdStsCdLst: vslVoySkdStsCdLst,
      berthingPurposeCodes: berthingPurposeCodes,
    });

    hideLoading();
  };

  const onChangeVslVoySkdStsCd = values => {
    vesselMainpageStore.setInputFields({
      selectedVslVoyCds: [...values],
    });
  };

  const onChangeBerthingPurposeCode = values => {
    vesselMainpageStore.setInputFields({
      selectedBerthingPurposeCodes: [...values],
    });
  };

  const searchVslVoyLst = async () => {
    const {
      selectedBerthingPurposeCodes,
      selectedVslVoyCds,
    } = vesselMainpageStore.getInputFields;

    if (selectedVslVoyCds && selectedVslVoyCds.length === 0) {
      console.log('There is no selected {?Vessel Voyage Status}');
      // this.openNotification(
      //   locLbl('LBL0003126'), // Warning,
      //   ComGetMsg('MSG0000952', locLbl('LBL0004028')) //There is no selected {?Vessel Voyage Status}.
      // );
      return;
    }

    try {
      showLoading();
      const vslVoyLst = await VesselCommonService.getVslVoyLst(
        profileStore.idTerminal,
        {
          selectedVslVoyCds,
          selectedBerthingPurposeCodes,
          menuId
        },
      );

      vesselMainpageStore.setVslVoyageLst(vslVoyLst);
    } catch (error) {
      console.log(error);
    } finally {
      hideLoading();
    }
  };

  const onClickVslVoyageItem = voyage => {
    vesselMainpageStore.setSelectedVoyage(voyage);
    onVoyageItemClick();
  };

  const VslVoySkdStsCdLst = observer(() => {
    const vslVoySkdStsCdLst =
      vesselMainpageStore.getInputFields.vslVoySkdStsCdLst;
    const selectedVslVoyCds =
      vesselMainpageStore.getInputFields.selectedVslVoyCds;

    if (vslVoySkdStsCdLst && vslVoySkdStsCdLst.length === 0) {
      return <></>;
    }

    return (
      <MultiSelect
        collection={vslVoySkdStsCdLst}
        onChange={onChangeVslVoySkdStsCd}
        values={selectedVslVoyCds}
        title="Vessel Voyage Status"
      ></MultiSelect>
    );
  });

  const BerthingPurposeCombo = observer(() => {
    const berthingPurposeCodes =
      vesselMainpageStore.getInputFields.berthingPurposeCodes;
    const selectedBerthingPurposeCodes =
      vesselMainpageStore.getInputFields.selectedBerthingPurposeCodes;

    if (berthingPurposeCodes && berthingPurposeCodes.length === 0) {
      return <></>;
    }

    return (
      <MultiSelect
        collection={berthingPurposeCodes}
        onChange={onChangeBerthingPurposeCode}
        values={selectedBerthingPurposeCodes}
        title="Berthing Purpose"
      ></MultiSelect>
    );
  });

  return (
    <>
      <Card style={{ margin: 0, height: '100%' }}>
        <div style={{ height: '20px' }}></div>
        <CardContent>
          <VslVoySkdStsCdLst />
        </CardContent>
        <CardContent>
          <BerthingPurposeCombo />
        </CardContent>
        <CardContent>
          <Row>
            <Col width={70}></Col>
            <Col width={30}>
              <Button onClick={searchVslVoyLst} fill round smallMd>
                Search
              </Button>
            </Col>
          </Row>
        </CardContent>
        <BlockTitle>Vessel Voyage List</BlockTitle>
        <LineDivine />
        <ListVesselVoyage
          onClick={onClickVslVoyageItem}
          vslVoyLst={vesselMainpageStore.getVslVoyageLst}
        ></ListVesselVoyage>
      </Card>
    </>
  );
});

export default VesselMainPage;
