import {
  Block,
  Button,
  Col,
  Link,
  List,
  ListInput,
  ListItemCell,
  ListItemRow,
  ListItem,
  Navbar,
  NavLeft,
  NavRight,
  NavTitle,
  Page,
  Row,
  BlockTitle,
  Badge,
  Card,
  Toggle,
  AccordionContent,
} from 'framework7-react';
import React, { useContext, useEffect, useState } from 'react';
import * as profileService from '../../services/profile.service';
import { GENDER } from '../../const';
import moment from 'moment';
import { StoreContext } from '../../store';
import { setRoleInfo } from '../../services/store.service';
import TerminalsRoles from '../../components/terminals-roles/index';
import TerminalVessel from '../../components/terminal-vessel/index';
import * as userService from '../../services/user.service';

const UserForm = props => {
  const { profileStore, userStore } = useContext(StoreContext);
  const [profile, setProfile] = useState({
    idCountry: null,
    mobilePhoneNumber: null,
    gender: 'M',
    mobileCountryCode: null,
    name: '',
    username: '',
    birthDate: '',
    email: '',
    emailValid: '',
  });
  const [dataConfig, setDataConfig] = useState({
    countryList: [],
    basedCountryList: [],
    genderList: [],
  });

  useEffect(async () => {
    await initialize();
  }, []);

  const initialize = async () => {
    const lstCountry = await profileService.getCountryCode();
    setDataConfig({
      basedCountryList: lstCountry,
      countryList: lstCountry,
      genderList: GENDER,
    });

    const userInfo = userStore.getUser;
    if (userInfo) {
      profile.gender = userInfo.person.gender;
      profile.mobilePhoneNumber = userInfo.person.mobilePhoneNumber;
      profile.mobileCountryCode = userInfo.person.mobileCountryCode;
      profile.name = userInfo.person.name;
      profile.email = userInfo.person.email;
      profile.enabled = userInfo.person.enabled;
      profile.birthDate = userInfo.birthDate;
      profile.username = userInfo.username;
      profile.emailValid = userInfo.emailValid;
      setProfile({ ...profile });
    }
  };

  const showAlert = (title, content) => {
    props.$f7router.app.dialog.alert(content, title, () => {});
  };

  const saveProfile = async () => {
    const data = getDataForSave();
    const result = await userService.updateUserProfile(data);
    if (result) {
      showAlert('Info', 'Save successfully!');
      userStore.updateUserInList(result);
    } else {
      showAlert('Warning', 'Failed! Please try again.');
    }
  };

  const getDataForSave = () => {
    const { userTerminalRoles, idUser } = userStore.user;
    const roles = userTerminalRoles.map(item => {
      const { idTerminal, roles } = item;
      return { idTerminal, roles };
    });
    return { ...profile, idUser, roles };
  };

  const onSaveClick = async () => {
    props.$f7router.app.dialog.confirm(
      'Do you want to update profile',
      'Confirm',
      () => {
        saveProfile();
      },
    );
  };

  const navigateRoleManager = async () => {
    props.$f7router.navigate('/manage-role', { props: { setRoles } });
  };

  const navigateToMain = async () => {
    const { role, company } = profileStore;
    const roles = await profileService.getRoleList();
    const isExist = await profileService.isRoleExisted(role, company, roles);

    if (isExist) {
      props.$f7router.back();
      return;
    }

    if (roles.length === 1) {
      await setRoleInfo(roles[0]);
      props.$f7router.back();
    } else {
      props.$f7router.navigate('/select-role');
    }
  };

  const style = `
    .user-info-form input, select{
            padding:5px !important;
    }
    .user-info-form .custom-list-item{
      justify-content: space-between;
      display: flex;
      padding: 5px 20px 5px 20px;
    }
  `;
  return (
    <Page>
      <style>{style}</style>
      <Navbar>
        <NavLeft>
          <Link icon="icon-back" back />
        </NavLeft>
        <NavTitle style={{ overflow: 'unset' }}>Profile</NavTitle>
        {/* <NavRight>
          <Link color="black" iconMaterial="person"></Link>
        </NavRight> */}
      </Navbar>
      <Card style={{ margin: 0 }} className="user-info-form">
        <List form accordionList id="formUpdateProfile">
          <ListItem
            accordionItem
            title="User Profile"
            // className="accordion-item-opened"
          >
            <AccordionContent>
              <ListInput
                label="Name"
                type="text"
                value={profile.name || ''}
                disabled
              />
              <ListInput
                label="User name"
                type="text"
                name="username"
                value={profile.username || ''}
                disabled
              />
              <ListInput
                label="E-mail"
                type="email"
                name="email"
                value={profile.email || ''}
                disabled
              />
              <ListItemRow>
                <ListItemCell style={{ flex: 5 }}>
                  <ListInput
                    // outline
                    type="date"
                    label="Birthday"
                    name="birthday"
                    value={
                      profile
                        ? moment(profile.birthDate).format('YYYY-MM-DD')
                        : ''
                    }
                    disabled
                  ></ListInput>
                </ListItemCell>
                <ListItemCell style={{ flex: 4, marginLeft: 0 }}>
                  <ListInput
                    type="select"
                    label="Gender"
                    name="gender"
                    value={profile.gender || ''}
                    disabled
                  >
                    {dataConfig.genderList.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.value}
                      </option>
                    ))}
                  </ListInput>
                </ListItemCell>
              </ListItemRow>
              <ListInput
                type="select"
                label="Mobile Country"
                readonly
                name="mobileCountryCode"
                disabled
                value={profile.mobileCountryCode || ''}
              >
                <option value=""></option>
                {dataConfig.countryList.map((item, index) => (
                  <option key={item.idCountry} value={item.phoneCode}>
                    {item.phoneCountry}
                  </option>
                ))}
              </ListInput>
              <ListInput
                label="Mobile Phone"
                type="tel"
                placeholder="Your mobile phone"
                name="mobilePhoneNumber"
                disabled
                value={profile.mobilePhoneNumber || ''}
              />
              <ListInput
                type="select"
                label="Based Country"
                name="idCountry"
                disabled
                value={profile.idCountry || ''}
              >
                <option value=""></option>
                {dataConfig.basedCountryList.map((item, index) => (
                  <option key={index} value={item.idCountry}>
                    {item.name}
                  </option>
                ))}
              </ListInput>
              <List noHairlinesMd>
                <div className="custom-list-item">
                  <span>Verified email</span>
                  <Toggle
                    checked={profile.emailValid === 'Y'}
                    onChange={() => {
                      setProfile({
                        ...profile,
                        emailValid: profile.emailValid === 'Y' ? 'N' : 'Y',
                      });
                    }}
                  ></Toggle>
                </div>
                <div className="custom-list-item">
                  <span>Approved account</span>
                  <Toggle
                    checked={profile.enabled === 'Y'}
                    onChange={e => {
                      setProfile({
                        ...profile,
                        enabled: profile.enabled === 'Y' ? 'N' : 'Y',
                      });
                    }}
                  ></Toggle>
                </div>
              </List>
            </AccordionContent>
          </ListItem>
        </List>

        <TerminalVessel />

        <TerminalsRoles />

        <Row style={{ paddingBottom: '10px' }}>
          <Col>
            <Button
              id="saveBtn"
              className="button button-fill button-round"
              style={{
                width: '160px',
                margin: 'auto',
              }}
              onClick={onSaveClick}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Card>
    </Page>
  );
};

export default UserForm;
