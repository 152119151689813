import { useContext, useEffect, useMemo, useState } from 'react';

import React from 'react';
import {
  Icon,
  Block,
  Fab,
  Button,
  Card,
  CardContent,
  Col,
  Link,
  List,
  ListInput,
  ListItem,
  Navbar,
  NavLeft,
  NavRight,
  NavTitle,
  Page,
  Popover,
  Row,
  Badge,
} from 'framework7-react';
import { StoreContext } from '../../store';
import * as roleService from '../../services/role.service';
const UserItem = props => {
  const { commonStore } = useContext(StoreContext);
  const [userRoles, setUserRoles] = useState([]);

  useMemo(async () => {
    //Get Roles
    let roleList = commonStore.getRoleList;
    if (!roleList || !roleList.length) {
      roleList = await roleService.getRoleList();
      commonStore.setRoleList(roleList);
    }

    const { userTerminalRoles } = props.userInfo;
    const idTerminal = props.idTerminal;
    let roles = (userTerminalRoles || [])
      .filter(item => {
        return !idTerminal || idTerminal == item.idTerminal;
      })
      .reduce((pre, next) => {
        return pre.concat(next.roles.split(', '));
      }, []);
    roles = roles.filter((item, index) => roles.indexOf(item) === index);
    const userRoleList = roleList.filter(item =>
      roles.includes(`${item.roleId}`),
    );
    setUserRoles(userRoleList);
  }, [props.idTerminal, props.userInfo]);

  const { emailValid, person } = props.userInfo;
  const { email, name, enabled } = person;
  return (
    <React.Fragment>
      <CardContent style={{ position: 'relative' }}>
        <div
          onClick={() => {
            props.onClick(props.userInfo);
          }}
        >
          <Row>
            <Col width={50}>
              <Block>{name}</Block>
            </Col>
            <Col width={50}>
              <Block
                style={{
                  textAlign: 'right',
                  color: enabled === 'Y' ? '#' : 'red',
                }}
              >
                {enabled === 'Y' ? '' : 'Not Approved yet'}
              </Block>
            </Col>
          </Row>
          <Row>
            <Col width={100}>
              <Block>
                {email}

                {emailValid === 'Y' ? (
                  <i
                    className="icon material-icons"
                    style={{ color: '#7ad53b', fontSize: '20px' }}
                  >
                    verified_user
                  </i>
                ) : (
                  <i
                    className="icon material-icons"
                    style={{ color: 'red', fontSize: '20px' }}
                  >
                    report
                  </i>
                )}
              </Block>
            </Col>
          </Row>
          <Row>
            <Col>
              {userRoles && userRoles.length ? (
                <Block>
                  {userRoles.map(item => (
                    <Badge
                      key={item.roleId}
                      style={{
                        color: 'blue',
                        backgroundColor: '#c7db78',
                        padding: '5px',
                        margin: '3px',
                      }}
                    >
                      {item.name}
                    </Badge>
                  ))}
                </Block>
              ) : (
                <Block
                  style={{
                    color: 'red',
                  }}
                >
                  No any role
                </Block>
              )}
            </Col>
          </Row>
          <div
            style={{
              position: 'absolute',
              top: '50%',
              right: '5px',
              transform: 'translate(0px, -50%)',
              zIndex: '100',
            }}
          >
            <Icon size="22" color="blue" material="chevron_right"></Icon>
          </div>
        </div>
      </CardContent>
      <div style={{ height: '1px', background: '#cccccc' }}></div>
    </React.Fragment>
  );
};

export default UserItem;
