import {
  BlockTitle, Button, Fab,
  Icon,
  Link,
  List,
  ListInput,
  ListItem,
  Navbar,
  NavLeft, NavTitle,
  Page, PageContent, Sheet, Toolbar
} from 'framework7-react';
import React, { useContext, useEffect, useState } from 'react';
import * as menuService from '../../services/menu.service';
import * as roleService from '../../services/role.service';
import { StoreContext } from '../../store';
import {
  alertErrorMess,
  hideLoading,
  openConfirm,
  openNotification,
  showLoading
} from '../../utils/common';
import './style.scss';

const RESPONSE_CODE = {
  SUCCESS: 1,
  ROLE_IN_USE: 2,
};

const RoleSetting = () => {
  const { profileStore, commonStore } = useContext(StoreContext);
  const [roleList, setRoleList] = useState([]);

  // const [roleCodeInput, setRoleCodeInput] = useState('');
  const [roleNameInput, setRoleNameInput] = useState('');
  const [isAddNew, setIsAddNew] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [isOpenSheet, setIsOpenSheet] = useState(false);

  useEffect(async () => {
    initialize();
  }, []);

  const initialize = async () => {
    await loadRoleList();
  };

  const onEditRole = role => {
    setSelectedRole(role);
    // setRoleCodeInput(role.comCd);
    setRoleNameInput(role.name);
    setIsAddNew(false);
    setIsOpenSheet(true);
  };

  const onRemoveRole = async role => {
    try {
      const isOk = await openConfirm('Do you want to delete role?');
      if (!isOk) {
        return;
      }

      showLoading();
      const result = await menuService.deleteRole(role.roleId);
      if (result === RESPONSE_CODE.ROLE_IN_USE) {
        openNotification(
          'Menu Setting',
          'Can not delete this role because it is using',
        );
        return;
      } else if (result === RESPONSE_CODE.SUCCESS) {
        openNotification('Menu Setting', 'Delete role successfully');
        await loadRoleList(true);
      } else {
        alertErrorMess();
      }
    } catch (ex) {
      console.log(ex);
      alertErrorMess();
    } finally {
      hideLoading();
    }
  };

  const loadRoleList = async isForce => {
    //Get terminal role list
    let roles = commonStore.getRoleList;
    if (isForce || !roles || !roles.length) {
      roles = await roleService.getRoleList();
      commonStore.setRoleList(roles);
    }
    setRoleList(roles || []);
  };

  const onAddNewRole = () => {
    setIsAddNew(true);
    setIsOpenSheet(true);
  };

  const onSaveRole = async () => {
    showLoading();

    let isSaveSuccess = false;
    if (isAddNew) {
      isSaveSuccess = await createRole();
    } else {
      isSaveSuccess = await updateRole();
    }

    if (isSaveSuccess) {
      openNotification('Menu Setting', 'Save role successfully');
      await loadRoleList(true);
    }

    clearForm();
    hideLoading();
    setIsOpenSheet(false);
  };

  const createRole = async () => {
    try {
      const existedRole = roleList.find(role => role.name === roleNameInput);
      if (existedRole) {
        openNotification('Menu Setting', 'Role already existed.');
        return false;
      }

      const param = {
        name: roleNameInput,
      };

      await menuService.addRole(param);
      return true;
    } catch (ex) {
      alertErrorMess();
      console.log(ex);
    }

    return false;
  };

  const updateRole = async () => {
    try {
      const param = {
        roleId: selectedRole.roleId,
        name: roleNameInput,
      };
      await menuService.updateRole(param);
      return true;
    } catch (ex) {
      alertErrorMess();
      console.log(ex);
    }

    return false;
  };

  const clearForm = () => {
    // setRoleCodeInput('');
    setRoleNameInput('');
  };

  return (
    <Page>
      <Navbar>
        <NavLeft>
          <Link icon="icon-back" back />
        </NavLeft>
        <NavTitle>Role setting</NavTitle>
      </Navbar>

      <BlockTitle>Roles</BlockTitle>
      <List>
        {roleList.map(role => (
          <ListItem key={role.roleId} title={role.name}>
            <div slot="after" onClick={() => onRemoveRole(role)}>
              <Icon size="25" material="delete" className="mr-10" />
            </div>
            <div slot="after" onClick={() => onEditRole(role)}>
              <Icon slot="after" size="25" material="edit" />
            </div>
          </ListItem>
        ))}
      </List>

      <Fab
        position="center-bottom"
        slot="fixed"
        text="Add role"
        color="blue"
        onClick={onAddNewRole}
      ></Fab>

      <Sheet
        className="demo-sheet"
        opened={isOpenSheet}
        style={{ height: '60%', '--f7-sheet-bg-color': '#fff' }}
        onSheetClosed={() => {
          setIsOpenSheet(false);
          // setRoleCodeInput('');
          setRoleNameInput('');
        }}
      >
        <Toolbar>
          <div className="left" style={{ marginLeft: '10px' }}>
            <b>{isAddNew ? 'Add role' : 'Update role'}</b>
          </div>
          <div className="right">
            <Link sheetClose>Close</Link>
          </div>
        </Toolbar>
        <PageContent>
          <List>
            {/* <ListInput
              label="Role code"
              type="text"
              placeholder="Input role code"
              value={roleCodeInput}
              onChange={e => setRoleCodeInput(e.target.value)}
              disabled={!isAddNew}
            /> */}
            <ListInput
              label="Role name"
              type="text"
              placeholder="Input role name"
              value={roleNameInput}
              onChange={e => setRoleNameInput(e.target.value)}
            />
          </List>
          <Button
            style={{
              width: '80px',
              margin: 'auto',
            }}
            raised
            fill
            disabled={!roleNameInput}
            onClick={onSaveRole}
          >
            Save
          </Button>
        </PageContent>
      </Sheet>
    </Page>
  );
};

export default RoleSetting;
