import { makeAutoObservable, toJS } from 'mobx';

export class VesselSoundingStore {
  constructor() {
    makeAutoObservable(this);
  }

  vslLocCds = [];

  selectedVslLocCd = '';

  cmdtCds = [];

  selectedCmdtCd = '';

  soundingMeasures = [];

  selectedSoundingMeasure = {};

  vesselCompartments = [];

  soundingDetails = [];

  vesselDraft = {
    fwrdDepDrftHgt: '',
    aftDepDrftHgt: '',
    fwrdArrDrftHgt: '',
    aftArrDrftHgt: '',
    depTrim: '',
    arrTrim: '',
  };

  get getVslLocCds() {
    return toJS(this.vslLocCds);
  }

  setVslLocCds(vslLocCds) {
    this.vslLocCds = vslLocCds;
  }

  get getSelectedVslLocCd() {
    return toJS(this.selectedVslLocCd);
  }

  setSelectedVslLocCd(selectedVslLocCd) {
    this.selectedVslLocCd = selectedVslLocCd;
  }

  get getCmdtCds() {
    return toJS(this.cmdtCds);
  }

  setCmdtCds(cmdtCds) {
    this.cmdtCds = cmdtCds;
  }

  get getSelectedCmdtCd() {
    return toJS(this.selectedCmdtCd);
  }

  setSelectedCmdtCd(selectedCmdtCd) {
    this.selectedCmdtCd = selectedCmdtCd;
  }

  get getSoundingMeasures() {
    return toJS(this.soundingMeasures);
  }

  setSoundingMeasures(soundingMeasures) {
    this.soundingMeasures = soundingMeasures;
  }

  get getSelectedSoundingMeasure() {
    return toJS(this.selectedSoundingMeasure);
  }

  setSelectedSoundingMeasure(selectedSoundingMeasure) {
    this.selectedSoundingMeasure = {
      ...this.selectedSoundingMeasure,
      ...selectedSoundingMeasure,
    };
  }

  get getVesselCompartments() {
    return toJS(this.vesselCompartments);
  }

  setVesselCompartments(vesselCompartments) {
    this.vesselCompartments = vesselCompartments;
  }

  get getSoundingDetails() {
    return toJS(this.soundingDetails);
  }

  setSoundingDetails(soundingDetails) {
    this.soundingDetails = soundingDetails;
  }

  get getVesselDraft() {
    return toJS(this.vesselDraft);
  }

  setVesselDraft(vesselDraft) {
    this.vesselDraft = vesselDraft;
  }

  setVesselDraftField(data) {
    Object.entries(data).forEach(([key, value]) => {
      if (this.vesselDraft[key] !== undefined) {
        this.vesselDraft[key] = value;
      }
    });
  }
}
