import * as vesselCommonApi from '../api/vessel-common.api';

export const getVslVoySkdStsCdLst = async (idTerminal) => {
  try {
    const data = await vesselCommonApi.getVslVoySkdStsCdLst(idTerminal);
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getComCodes = async (idTerminal, groupNm) => {
  try {
    const data = await vesselCommonApi.getComCodes(idTerminal, groupNm);
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getVslVoyLst = async (idTerminal, param) => {
  try {
    const data = await vesselCommonApi.getVslVoyLst(idTerminal, param);
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getCommodityCodes = async (idTerminal, opTpCd, vslVoyId) => {
  try {
    const data = await vesselCommonApi.getCommodityCodes(
      idTerminal,
      opTpCd,
      vslVoyId,
    );
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getImage = async (tmlCd, path) => {
  try {
    const data = await vesselCommonApi.getImage(tmlCd, path);
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getVesselListByVslCdKey = async (tmlCd, vslCdKey) => {
  const data = await vesselCommonApi.getVesselListByVslCdKey(tmlCd, vslCdKey);
  return data;
};

export const getVessselPlannedList = async tmlCd => {
  const data = await vesselCommonApi.getVessselPlannedList(tmlCd);
  return data;
};

export const getVvdPlannedList = async tmlCd => {
  const data = await vesselCommonApi.getVvdPlannedList(tmlCd);
  return data;
};
