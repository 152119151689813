import { List, ListItem, Navbar, Page, Toggle } from 'framework7-react';
import React, { useEffect, useState } from 'react';
import { QR_SCAN_LIB, QR_SCAN_LIB_DEFAULT } from '../../const';
import CommonQrCode from './common-qr-code';

function ItemTest({ title, checked, onClick, onSelected }) {
  return (
    <ListItem style={{ listStyle: 'none' }}>
      <div slot='inner' className='w-full' onClick={onClick}>
        {title}
      </div>
      <Toggle
        slot='content'
        checked={checked}
        onChange={(e) => {
          if (e.target.checked) {
            onSelected();
          }
        }}
      />
    </ListItem>
  );
}

export const TestQR = () => {
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [selectedQrLib, setSelectedQrLib] = useState(QR_SCAN_LIB_DEFAULT);
  const [selectedItemId, setsSelectedItemId] = useState(''); // use for switch

  function storeSelectedQrLib(libId) {
    localStorage.setItem('qrLibId', libId);
    setsSelectedItemId(libId);
  }

  useEffect(() => {
    const libId = localStorage.getItem('qrLibId');
    setsSelectedItemId(libId || QR_SCAN_LIB_DEFAULT);
  }, []);

  return (
    <Page>
      <Navbar title={'QR Scanning Testing'} backLink="Back" />
      <List inset>
        {
          Object.keys(QR_SCAN_LIB).map((lib) => <ItemTest
            key={lib}
            title={QR_SCAN_LIB[lib].name}
            checked={selectedItemId === QR_SCAN_LIB[lib].id}
            onClick={() => {
              setIsOpenPopup(true);
              setSelectedQrLib(QR_SCAN_LIB[lib].id);
            }}
            onSelected={() => storeSelectedQrLib(QR_SCAN_LIB[lib].id)}
          />)
        }
      </List>

      <CommonQrCode
        libId={selectedQrLib}
        isOpen={isOpenPopup}
        onPopupClosed={() => {
          setIsOpenPopup(false);
        }}
      />
    </Page>
  );
}
